import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Box,
  Button,
  Checkbox,
  Grid2,
  Modal,
  TextField,
  Typography,
  InputBase,
  FormControlLabel,
  Dialog,
  DialogTitle,
  Autocomplete,
  DialogContent
} from '@mui/material';
import BasicModal from '~/app/components/BasicModal/BasicModal';
import Snackbar from '@mui/material/Snackbar';
import React, { ReactNode, useEffect, useState, useRef, useContext } from 'react';
import { IProduct, ModalContentType } from '~/interfaces/product';
import { api, currentUser } from '~/api';
import { Map } from '~/app/components/Map/Map';
import { PortfolioCard } from '~/app/components/PortfolioCard/PortfolioCard';
import { appTheme } from '~/utils/theme';
import './ProductList.scss';
import auth from '~/app/components/Auth/AuthApi';
import packageInfo from '../../../../package.json';
import axios from 'axios';
import normalizedString from '~/utils/normalizedString';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import WizzardCreateProduct from '~/app/components/CreateProduct/WizzardCreateProduct';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

interface IAddProduct {
  street: string;
  city: string;
  country: string;
  zip_code: string;
  model_id?: string;
  reference?: string;
  data?: Record<string, never>;
}

const LotCheckBoxKey = 'lotCheckBox';
const WalletCheckBoxKey = 'walletCheckBox';
const EventsToCheckCheckBoxKey = 'eventsToCheckCheckBox';
const isScannedCheckedheckBoxKey = 'isScannedChecked';

export const ProductList = ({ setSpinnerOpen, spinnerOpen }) => {
  const initProductData = {
    street: '',
    city: '',
    country: 'France',
    zip_code: '',
    model_id: '',
    reference: '',
    data: {}
  };
  const [search, setSearch] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState({ isError: false, error: {} });
  const [productsList, setProductsList] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [filteredProductsData, setFilteredProductsData] = useState([]);
  const [addProductMode, setAddProductMode] = useState(false);
  const [newProductData, setNewProductData] = useState<IAddProduct>(initProductData);
  const [submit, setSubmit] = useState<boolean>(false);
  const [selectedIdProduct, setSelectedIdProduct] = useState('');
  const [coordsToMoveTo, setCoordsToMoveTo] = useState([]);
  const [originalBds, setOriginalBds] = useState<any>();
  const [mapObject, setMapObject] = useState<any>();
  const [cardIndexEntered, setCardIndexEntered] = useState<number>(-1);
  const [noRoll, setNoRoll] = useState<boolean>(true);

  const [walletCheckBox, setWalletCheckBox] = useState<boolean>(
    localStorage.getItem(WalletCheckBoxKey) === 'true' ? true : false
  );
  const [eventsToCheckCheckBox, setEventsToCheckCheckBox] = useState<boolean>(
    localStorage.getItem(EventsToCheckCheckBoxKey) === 'true' ? true : false
  );

  const [isScannedChecked, setIsScannedChecked] = useState<boolean>(
    localStorage.getItem(isScannedCheckedheckBoxKey) === 'true' ? true : false
  );

  const [firstConection, setFirstConection] = useState<boolean>(true);
  const [displayMap, setDisplayMap] = useState<boolean>(true);
  const [contentHasBeenScrolled, setContentHasBeenScrolled] = useState<boolean>(false);
  const [uuidHighlited, setUuidHighlited] = useState<string>('');
  const [googleApiLoaded, setGoogleApiLoaded] = useState<boolean>(false);
  const [placeSelected, setPlaceSelected] = useState<any>(null);

  const [uuidProductToAttribute, setUuidProductToAttribute] = useState<string>('');

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalContentType>();

  const [usersInOrganisation, setUsersInOrganisation] = useState([]);

  const [uuidUserToAttribute, setUuidUserToAttribute] = useState<string>('');

  const modalOpenRef = useRef(modalOpen);

  const [cancelCreationConfirmation, setCancelCreationConfirmation] = useState<boolean>(false);

  const [attributionDialogOpen, setAttributionDialogOpen] = React.useState(false);

  const mediaQueries = useContext(MediaQueryContext);

  const [displayMapByUserAction, setDisplayMapByUserAction] = useState<boolean>(true);

  const handleCloseAttributionDialog = () => {
    setUuidUserToAttribute('');
    setAttributionDialogOpen(false);
    setUuidProductToAttribute('');
  };

  useEffect(() => {
    localStorage.removeItem(LotCheckBoxKey);
  }, []);

  useEffect(() => {
    localStorage.setItem(WalletCheckBoxKey, walletCheckBox.toString());
  }, [walletCheckBox]);

  useEffect(() => {
    localStorage.setItem(EventsToCheckCheckBoxKey, eventsToCheckCheckBox.toString());
  }, [eventsToCheckCheckBox]);

  useEffect(() => {
    localStorage.setItem(isScannedCheckedheckBoxKey, isScannedChecked.toString());
  }, [isScannedChecked]);

  const checkVersion = () => {
    if (window.location.pathname === '/' && modalOpenRef.current === false) {
      axios.get(window.location.origin + '/version.txt?' + Math.random()).then((res) => {
        const versionTxt = res.data.replace(/[\r\n]+/gm, '');
        if (versionTxt !== packageInfo.version) {
          window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    modalOpenRef.current = modalOpen;
  }, [modalOpen]);

  const handleScannedChange = (event) => {
    setIsScannedChecked(event.target.checked);
  };

  const refreshProducts = () => {
    const requests = [];
    requests.push(api.product.search());

    Promise.all(requests)
      .then((values) => {
        const products = values[0];
        setProductsData(products);
        setProductsList(products);
        setFilteredProductsData(products);
        setSpinnerOpen(false);
      })
      .catch((error) => {
        setError({ isError: true, error });
        setSpinnerOpen(false);
      })
      .finally(() => {
        setSpinnerOpen(false);
      });
  };

  useEffect(() => {
    refreshProducts();

    api.organizations.get(auth.getOrganizationUUID()).then((res) => {
      const users = [];
      res.users.forEach((user) => {
        users.push({ label: `${user.first_name} ${user.last_name}`, value: user.uuid });
      });
      setUsersInOrganisation(users);
    });

    let intervalId = setInterval(checkVersion, 1000 * 60);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (productsData && productsData.length > 0) {
      const filteredData = [];
      let hasProductsInWallet = false;

      productsData.forEach((product: IProduct) => {
        let isInMyWallet = false;
        let hasEventsToCheck = false;
        let isScanned = false;

        // Vérification si le produit est dans mon portefeuille
        if (walletCheckBox) {
          if (product.users_in_charge && product.users_in_charge.length > 0) {
            product.users_in_charge.forEach((user) => {
              if (user.uuid_user === currentUser.uuid_user) {
                isInMyWallet = true;
              }
            });
          }
        }

        // Vérification si le produit a des événements à qualifier
        if (eventsToCheckCheckBox) {
          if (product.nb_events_to_qualify && product.nb_events_to_qualify > 0) {
            hasEventsToCheck = true;
          }
        }

        // Vérification si le produit est scanné
        if (isScannedChecked) {
          if (product?.matterport_scan?.model_id) {
            // Assure-toi que cette propriété existe dans product
            isScanned = true;
          }
        }

        // Filtrage basé sur les critères activés
        if (
          (isInMyWallet || !walletCheckBox) &&
          (hasEventsToCheck || !eventsToCheckCheckBox) &&
          (isScanned || !isScannedChecked)
        ) {
          filteredData.push(product);
        }

        if (isInMyWallet) {
          hasProductsInWallet = true;
        }
      });

      if (!hasProductsInWallet && firstConection) {
        setWalletCheckBox(false);
      }

      const newFilteredFolderdData = filteredData.filter((product) => {
        let hasToBeInvisible = false;
        return !hasToBeInvisible;
      });

      setFilteredProductsData(newFilteredFolderdData);
    }
  }, [walletCheckBox, eventsToCheckCheckBox, isScannedChecked, productsData]);

  useEffect(() => {
    if (!spinnerOpen && !error.isError && productsData) {
      const filteredList = filteredProductsData.filter((item: IProduct) => {
        const normalizedSearch = normalizedString(search);

        return item.address
          ? normalizedString(item.address.street).indexOf(normalizedSearch) >= 0 ||
              normalizedString(item.address.city).indexOf(normalizedSearch) >= 0 ||
              normalizedString(item.address.zip_code).indexOf(normalizedSearch) >= 0 ||
              (item.tenant && normalizedString(item.tenant.name).indexOf(normalizedSearch) >= 0) ||
              (item.tenant && normalizedString(item.tenant.company_name).indexOf(normalizedSearch) >= 0) ||
              (item.owner && normalizedString(item.owner.name).indexOf(normalizedSearch) >= 0) ||
              (item.owner && normalizedString(item.owner.company_name).indexOf(normalizedSearch) >= 0) ||
              (item.name && normalizedString(item.name).indexOf(normalizedSearch) >= 0) ||
              (item.identification?.id_internal &&
                normalizedString(item.identification?.id_internal).indexOf(normalizedSearch) >= 0)
          : item;
      });
      setProductsList(filteredList);
    }
  }, [search, spinnerOpen, productsData, filteredProductsData]);

  useEffect(() => {
    if (placeSelected !== null) {
      const id = String(placeSelected.place_id);
      new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(
        { placeId: id, fields: ['address_components'] },
        (details) => {
          let postcode = null;
          let country = null;
          let locality = null;
          let street_number = null;
          let route = null;
          details?.address_components?.forEach((entry) => {
            if (entry.types?.[0] === 'postal_code') {
              postcode = entry.long_name;
            }
            if (entry.types?.[0] === 'country') {
              country = entry.long_name;
            }
            if (entry.types?.[0] === 'locality') {
              locality = entry.long_name;
            }
            if (entry.types?.[0] === 'street_number') {
              street_number = entry.long_name;
            }
            if (entry.types?.[0] === 'route') {
              route = entry.long_name;
            }
          });
          const street = street_number ? street_number + ' ' + route : route;
          const address = {
            street: street,
            city: locality,
            country: country,
            zip_code: postcode
          };
          setNewProductData(address);
        }
      );
    }
  }, [placeSelected]);

  const handleClose = () => {
    setOpenSnackBar(false);
    setOpen(false);
  };

  const setInitialCoords = (bds, map, center) => {
    setOriginalBds(bds);
    setMapObject(map);
  };

  const handleCreateProduct = () => {
    setModalType('CreateProduct');
    setModalOpen(true);
  };

  const handleCancel = () => {
    setAddProductMode(false);
    setOpen(false);
  };

  const handleSubmitNewProduct = () => {
    setSubmit(true);
    const newProduct = {
      id_internal: newProductData.reference,
      address: {
        street: newProductData.street,
        city: newProductData.city,
        zip_code: newProductData.zip_code,
        country: newProductData.country
      },
      matterport_scan: {
        model_id: newProductData.model_id,
        activated: true
      },
      data: {}
    };
  };

  const handleMouseEnter = (e) => {
    setCardIndexEntered(Number(e.currentTarget.dataset.index));
    if (e.currentTarget.dataset.lat) setCoordsToMoveTo([e.currentTarget.dataset.lat, e.currentTarget.dataset.lgt]);
    setUuidHighlited(e.currentTarget.dataset.linkid);
    setNoRoll(false);
  };

  const handleMouseLeave = (e) => {
    if (originalBds) setCoordsToMoveTo([originalBds.getCenter().lat(), originalBds.getCenter().lng()]);
    if (mapObject !== undefined && mapObject !== null) mapObject.fitBounds(originalBds);
    setCardIndexEntered(-1);
    setUuidHighlited('');
    setNoRoll(true);
  };

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '1390px',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3
  };

  const handleSuppressProduct = (idProduct) => {
    setSelectedIdProduct(idProduct);
    setOpen(true);
  };

  const handleValidate = () => {
    api.product.delete(selectedIdProduct).then((resp) => {
      const newData = [];
      productsData.forEach((product) => {
        if (product.uuid !== selectedIdProduct) newData.push(product);
      });
      setProductsData(newData);
      setSelectedIdProduct('');
      setProductsList(newData);
      setFilteredProductsData(newData);
      setOpen(false);
    });
    // }
  };

  const modalClosed = (str: string) => {
    if (productsData === null) return;
  };

  const handleChangeWalletCheckBox = (e) => {
    setWalletCheckBox(e.target.checked);
    setFirstConection(false);
  };
  const handleChangeEventsToCheckCheckBox = (e) => {
    setEventsToCheckCheckBox(e.target.checked);
    setFirstConection(false);
  };

  const handleSearch = (e) => {
    setSearch(e.currentTarget.value);
  };

  const mapToggler = () => {
    setDisplayMapByUserAction(!displayMapByUserAction);
    setDisplayMap(!displayMap);
  };

  useEffect(() => {
    if (!mediaQueries.matchesMd) {
      setDisplayMap(false);
    } else {
      if (displayMapByUserAction) setDisplayMap(true);
    }
  }, [mediaQueries.matchesMd]);

  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = React.useState('');

  const handleContentModal = (type: ModalContentType): ReactNode => {
    switch (type) {
      case 'CreateProduct':
        return (
          <div style={{ padding: '25px', paddingBottom: '0px' }}>
            <WizzardCreateProduct
              setModalOpen={setModalOpen}
              closeModal={() => setModalOpen(false)}
              cancelCreationConfirmation={cancelCreationConfirmation}
              setCancelCreationConfirmation={setCancelCreationConfirmation}
            ></WizzardCreateProduct>
          </div>
        );
    }
  };

  const handleConfirmClose = () => {
    setCancelCreationConfirmation(true);
  };

  const inCharge = (item) => {
    let inCharge = false;
    if (item.users_in_charge) {
      item.users_in_charge.forEach((user: any) => {
        if (user?.uuid_user === currentUser?.uuid_user) {
          inCharge = true;
        }
      });
    }
    return inCharge;
  };

  return (
    <Box
      className="ProductList"
      sx={{
        width: 1
      }}
    >
      <div>
        <BasicModal
          modalOpen={modalOpen}
          onModalOpen={(open: boolean) => setModalOpen(open)}
          onModalClose={modalClosed}
          confirmClose={true}
          handleConfirm={handleConfirmClose}
        >
          {handleContentModal(modalType)}
        </BasicModal>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{
          backdropFilter: 'blur(5px)'
        }}
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="parent-modal-title">{`Suppression de produit`}</h2>
          <p id="parent-modal-description">{`Êtes vous sur de vouloir supprimer ce produit`}</p>
          <div className="BasicModal__close" onClick={handleClose}>
            <CloseIcon />
          </div>
          <Grid2>
            <Button color={'primary'} variant="outlined" onClick={handleCancel}>
              Annuler
            </Button>
            &nbsp;
            <Button variant="contained" color={'primary'} onClick={handleValidate}>
              Valider
            </Button>
          </Grid2>
        </Box>
      </Modal>
      <div
        style={{
          display: 'flex',
          maxHeight: mediaQueries.matchesSm ? 'calc(100% - 65px)' : 'calc(100% - 50px)',
          height: mediaQueries.matchesSm ? 'calc(100% - 65px)' : 'calc(100% - 50px)',
          minHeight: mediaQueries.matchesSm ? 'calc(100% - 65px)' : 'calc(100% - 50px)',
          width: mediaQueries.matchesSm ? 'calc(100% - 48px)' : 'calc(100% - 35px)',
          maxWidth: '1488px',
          position: 'absolute',
          overflow: 'hidden'
        }}
      >
        <div
          style={{
            display: 'flex',
            width: displayMap ? '58.5%' : '100%',
            minWidth: displayMap ? '58.5%' : '100%',
            flexDirection: 'column'
          }}
        >
          <div>
            <Box
              sx={{
                width: displayMap ? null : 1,
                display: 'flex',
                alignItems: 'center'
              }}
              style={{ paddingRight: '17px' }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant={
                    mediaQueries.matchesXl ? 'h1' : mediaQueries.matchesLg ? 'h2' : mediaQueries.matchesMd ? 'h3' : 'h4'
                  }
                  sx={{ color: 'primary.main', paddingTop: !mediaQueries.matchesMd ? '5px' : '15px' }}
                >
                  Portefeuille
                </Typography>
              </Box>
              <div style={{ paddingTop: !mediaQueries.matchesMd ? '10px' : '20px' }}>
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  sx={
                    !mediaQueries.matchesSm
                      ? {
                          color: 'white',
                          borderRadius: '20px',
                          borderColor: 'secondary.main',
                          minWidth: 0,
                          padding: 0,
                          position: 'relative',
                          top: '-4px'
                        }
                      : {
                          color: 'white',
                          borderRadius: '20px',
                          borderColor: 'secondary.main',
                          position: 'relative',
                          top: '-4px'
                        }
                  }
                  onClick={handleCreateProduct}
                >
                  <AddOutlinedIcon fontSize="small" />
                  {!mediaQueries.matchesSm ? 'Biens' : ' Ajouter un bien'}
                </Button>
              </div>
            </Box>

            <div style={{ paddingRight: displayMap ? '17px' : '0px' }}>
              <Box
                sx={{
                  my: 1
                }}
              >
                <Grid2 container>
                  <Grid2 size={mediaQueries.matchesMd ? 12 : 'grow'} style={{ textAlign: 'right' }}>
                    <div
                      style={{
                        alignItems: 'center',
                        backgroundColor: appTheme.palette.primary[50],
                        border: `1px solid`,
                        borderColor: appTheme.palette.primary[100],
                        borderRadius: '20px',
                        display: 'flex',
                        height: !mediaQueries.matchesMd ? '24px' : '36px',
                        fontSize: '16px'
                      }}
                    >
                      <InputBase
                        autoComplete="off"
                        placeholder="Propriétaire, locataire, référence, adresse…"
                        onChange={handleSearch}
                        value={search}
                        inputProps={{
                          sx: {
                            pl: 1.8,
                            '&::placeholder': {
                              // color: 'primary.main',
                              // opacity: 1
                            }
                          }
                        }}
                        sx={{ flex: 1 }}
                      />
                      <SearchOutlinedIcon
                        sx={{
                          color: 'primary.main',
                          ml: 2,
                          mr: 1
                        }}
                        fontSize="medium"
                      />
                    </div>
                  </Grid2>
                  <Grid2 size={mediaQueries.matchesMd ? 12 : 'auto'} style={{ textAlign: 'right' }}>
                    <Typography
                      sx={{
                        marginBottom: !mediaQueries.matchesMd ? 0 : 2,
                        color: 'primary.main',
                        opacity: 1,
                        textAlign: 'right',
                        pl: 1,
                        pt: '2px'
                      }}
                    >
                      {productsList.length} Résultats
                    </Typography>
                  </Grid2>
                </Grid2>
              </Box>
              <Grid2 container alignItems="flex-end">
                <Grid2
                  size={12}
                  style={
                    contentHasBeenScrolled
                      ? {
                          zIndex: 999,
                          boxShadow: '-6px 14px 18px -18px #000000'
                        }
                      : {}
                  }
                >
                  <Box sx={{ flex: displayMap ? null : 1, mb: !mediaQueries.matchesMd ? 0 : 4 }}>
                    <Grid2 container>
                      <Grid2 size={mediaQueries.matchesMd ? 9 : 12}>
                        <Typography variant="body1" sx={{ color: 'primary.main' }}>
                          <FormControlLabel
                            sx={{ pr: mediaQueries.matchesMd ? '15px' : '5px' }}
                            control={
                              <Checkbox
                                checked={walletCheckBox}
                                onChange={handleChangeWalletCheckBox}
                                id="port"
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                              />
                            }
                            label={mediaQueries.matchesMd ? 'Mon portefeuille' : 'Portefeuille'}
                          />

                          <FormControlLabel
                            sx={{ pr: mediaQueries.matchesMd ? '15px' : '5px' }}
                            control={
                              <Checkbox
                                checked={eventsToCheckCheckBox}
                                onChange={handleChangeEventsToCheckCheckBox}
                                id="eve"
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                              />
                            }
                            label={mediaQueries.matchesMd ? 'Évènements à traiter' : 'A traiter'}
                          />

                          <FormControlLabel
                            sx={{ pr: mediaQueries.matchesMd ? '15px' : '0px' }}
                            control={
                              <Checkbox
                                checked={isScannedChecked}
                                onChange={handleScannedChange}
                                id="port"
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                              />
                            }
                            label={mediaQueries.matchesMd ? 'Lot(s) scanné(s)' : 'Scannés'}
                          />
                        </Typography>
                      </Grid2>
                      {mediaQueries.matchesMd && (
                        <Grid2 size={3} style={{ textAlign: 'right' }}>
                          <Button
                            color="primary"
                            size="small"
                            endIcon={<MapOutlinedIcon />}
                            variant="contained"
                            onClick={mapToggler}
                          >
                            {!displayMap ? 'Afficher la carte' : 'Masquer la carte'}
                          </Button>
                        </Grid2>
                      )}
                    </Grid2>
                  </Box>
                </Grid2>
              </Grid2>
            </div>
          </div>
          <Grid2
            onScroll={(e: any) => {
              if (e.target.scrollTop > 0) {
                setContentHasBeenScrolled(true);
              } else {
                setContentHasBeenScrolled(false);
              }
            }}
            sx={{
              overflow: 'scroll',
              msOverflowStyle: 'none' /* Pour IE et Edge */,
              scrollbarWidth: 'none' /* Pour Firefox */,
              '&::-webkit-scrollbar': {
                display: 'none' /* Pour WebKit (Chrome, Safari) */
              }
            }}
          >
            <Grid2 container justifyContent="space-around">
              {productsList.map((item: IProduct, index: number) => {
                return (
                  <Grid2
                    key={index}
                    style={{
                      padding: '10px',
                      paddingLeft: '1px',
                      paddingRight: '0px',
                      transition: 'all 1s'
                    }}
                  >
                    <PortfolioCard
                      key={`cartes-${index}`}
                      data={{
                        title: item.address && item.address.building_name ? item.address.building_name : '',
                        subtitle: item.address && item.address.street ? item.address.street : '',
                        description:
                          item.address && item.address.city && item.address.zip_code
                            ? item.address.zip_code + ' ' + item.address.city
                            : '',
                        index: index,
                        links: item.links,
                        image: item.image_url,
                        linkId: item.uuid,
                        obj: item,
                        nb_events_to_qualify: item.nb_events_to_qualify,
                        isSuperAdmin: auth.isSuperAdmin(),
                        handleSuppressProduct: handleSuppressProduct,
                        onMouseEnter: handleMouseEnter,
                        onMouseLeave: handleMouseLeave,
                        geocoords: item.address ? [item.address.lat, item.address.long] : [],
                        reference: item.identification?.id_internal,
                        openSnackBar: setOpenSnackBar,
                        openSnackBarMessage: setOpenSnackBarMessage,
                        setSpinnerOpen: setSpinnerOpen,
                        usersInCharge: item.users_in_charge,
                        isInMyWallet: inCharge(item),
                        name: item.name,
                        setAttributionDialogOpen: setAttributionDialogOpen,
                        setUuidProductToAttribute: setUuidProductToAttribute,
                        kind: item.kind
                      }}
                    />
                  </Grid2>
                );
              })}
            </Grid2>
          </Grid2>
        </div>

        {displayMap && !error.isError && (
          <Map
            data={productsList}
            coordsToMoveTo={coordsToMoveTo}
            noRoll={noRoll}
            uuidHighlited={uuidHighlited}
            googleApiLoaded={setGoogleApiLoaded}
          />
        )}
      </div>

      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={handleClose}
        message={openSnackBarMessage}
        ContentProps={{
          sx: {
            background: appTheme.palette.primary.main
          }
        }}
      />

      {error.isError && <div className="ProductList__error">{`Une erreur s'est produite`}</div>}

      <Dialog
        onClose={handleCloseAttributionDialog}
        open={attributionDialogOpen}
        PaperProps={{ sx: { width: '500px' } }}
      >
        <DialogTitle>
          <Typography variant="h4">
            <PermIdentityIcon style={{ fontSize: '30px', position: 'relative', top: '5px' }} />
            Attribuer à un utilisateur
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid2 container spacing={1}>
            <Grid2></Grid2>
            <Grid2 size={12} sx={{ pt: '20px' }}>
              <Autocomplete
                onChange={(event, newValue) => {
                  if (newValue) {
                    setUuidUserToAttribute(newValue.value);
                  }
                }}
                options={usersInOrganisation}
                renderInput={(params) => <TextField {...params} label="Rechercher un utilisateur" fullWidth />}
              ></Autocomplete>
            </Grid2>
            <Grid2></Grid2>
            <Grid2></Grid2>
            <Grid2></Grid2>
            <Grid2 size={12} sx={{ pt: '20px' }}>
              <Grid2 container spacing={1} justifyContent={'center'}>
                <Grid2 sx={{ pt: '10px' }}>
                  <Button
                    onClick={() => {
                      setAttributionDialogOpen(false);
                      setUuidUserToAttribute('');
                      setUuidProductToAttribute('');
                    }}
                    variant="outlined"
                  >
                    Annuler
                  </Button>
                </Grid2>
                <Grid2 sx={{ pt: '10px' }}>
                  <Button
                    disabled={uuidUserToAttribute === ''}
                    variant="contained"
                    onClick={() => {
                      api.users.product
                        .assign(uuidUserToAttribute, uuidProductToAttribute)
                        .then((res) => {})
                        .catch((error) => {})
                        .finally(() => {
                          setAttributionDialogOpen(false);
                          setUuidUserToAttribute('');
                          setUuidProductToAttribute('');
                          refreshProducts();
                        });
                    }}
                  >
                    valider
                  </Button>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
