import { Box, Chip, Grid2, IconButton, Modal, Rating } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';
import dayjs from 'dayjs';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import { useSwipeable } from 'react-swipeable';
import { EditOutlined } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getIconState, getVariantState } from '../View3d/utilsView3D';

export const StateItem = ({ state, isLast = false, context = null }) => {
  const { referencial } = useContext(AppContext);
  const mediaQueries = useContext(MediaQueryContext);
  const [images, setImages] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  useEffect(() => {
    if (state) {
      const imgs = [];
      if (state.images && state.images.length > 0) {
        state.images.map((image) => {
          imgs.push(image);
        });
      }
      if (state.tags && state.tags.length > 0) {
        state.tags.map((tag) => {
          if (tag.images && tag.images.length > 0) {
            tag.images.map((image) => {
              imgs.push(image);
            });
          }
        });
      }
      setImages(imgs);
    }
  }, [state]);

  const handleOpen = (index) => {
    setSelectedImage(index);
    setSelectedImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const handleNext = useCallback(() => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images]);

  const handlePrev = useCallback(() => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  }, [images]);
  return (
    <div>
      <Grid2
        container
        onClick={() => {
          if (context === 'equipment-details' && !state.uuid_edl && state.uuid_edl !== '') {
            console.log('context', context);
          }
        }}
        sx={{ borderBottom: !isLast ? '1px solid  #e0e0e0' : 'none', mb: '10px' }}
      >
        <Grid2 size={12}>
          <Grid2 container>
            <Grid2 size={'grow'} sx={{ pb: '10px' }}>
              <Rating size={mediaQueries.matchesSm ? 'medium' : 'small'} value={state.rating} readOnly></Rating>
            </Grid2>

            {state.uuid_edl && state.uuid_edl !== '' && (
              <Grid2 size={'auto'}>
                <LockIcon sx={{ fontSize: '18px', color: 'primary.main' }} />
              </Grid2>
            )}

            {(!state.uuid_edl || state.uuid_edl === '') && (
              <Grid2
                size={'auto'}
                onClick={() => {
                  // handleModal('EditProductInfos');
                }}
              >
                <EditOutlined sx={{ fontSize: '18px', cursor: 'pointer' }} />
              </Grid2>
            )}
            {state.state !== 0 && (
              <Grid2 size={'auto'}>
                <Chip
                  size="small"
                  icon={getIconState(state.state)}
                  color={getVariantState(state.state)}
                  sx={{
                    color: getVariantState(state.state) + '.main',
                    backgroundColor: getVariantState(state.state) + '.light'
                  }}
                  label={_.find(referencial['equipment_state.status'], { id: Number(state.state) }).name}
                ></Chip>
              </Grid2>
            )}
          </Grid2>
        </Grid2>
        <Grid2 size={12}>
          <Grid2 container>
            <Grid2 size={'grow'}>
              <Grid2 container></Grid2>
              {state.created_at && state.created_at !== '' && (
                <Grid2 container>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Etat au : </span> <br />
                    <span>
                      {dayjs(state.created_at).format('DD/MM/YYYY') + ' - ' + dayjs(state.created_at).format('HH:mm')}{' '}
                    </span>{' '}
                    <br />
                  </Grid2>
                </Grid2>
              )}

              {state?.data && state?.data?.damages && state?.data?.damages.length > 0 && (
                <Grid2 container>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Dommages : </span> <br />
                    <>
                      {state?.data?.damages.map((damage, index) => {
                        return (
                          <span key={index}>
                            <span>{`${damage.number} ${damage.name}${index < state?.data?.damages.length - 1 ? ', ' : ''}`}</span>
                            {/* <br /> */}
                          </span>
                        );
                      })}
                    </>{' '}
                    <br />
                  </Grid2>
                </Grid2>
              )}

              {state.comment && state.comment !== '' && (
                <Grid2 container>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Commentaire : </span> <br />
                    <span>{state.comment}</span>
                  </Grid2>
                </Grid2>
              )}
              {state?.data?.meterValue &&
                state?.data?.meterUnity &&
                (state?.data?.meterValue2 === null ||
                  state?.data?.meterValue2 === undefined ||
                  state?.data?.meterValue2 === '') && (
                  <Grid2 container>
                    <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                      <span style={{ color: '#929292' }}>Consommation : </span> <br />
                      <span>
                        {state?.data?.meterValue} {state?.data?.meterUnity}
                      </span>
                    </Grid2>
                  </Grid2>
                )}

              {state?.data?.meterValue && state?.data?.meterUnity && state?.data?.meterValue2 && (
                <Grid2 container>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Consommation HP : </span> <br />
                    <span>
                      {state?.data?.meterValue} {state?.data?.meterUnity}
                    </span>
                  </Grid2>
                </Grid2>
              )}

              {state?.data?.meterValue2 && state?.data?.meterUnity && (
                <Grid2 container>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Consommation HC : </span> <br />
                    <span>
                      {state?.data?.meterValue2} {state?.data?.meterUnity}
                    </span>
                  </Grid2>
                </Grid2>
              )}

              {state?.data?.serialNumber && state?.data?.serialNumber && (
                <Grid2 container>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Numéro de série : </span> <br />
                    <span>{state?.data?.serialNumber}</span>
                  </Grid2>
                </Grid2>
              )}

              {state?.data?.inventoryNumber && state?.data?.inventoryNumber && (
                <Grid2 container>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Nombre : </span> <br />
                    <span>{state?.data?.inventoryNumber}</span>
                  </Grid2>
                </Grid2>
              )}

              {state?.data?.keyNumber && state?.data?.keyNumber && (
                <Grid2 container>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Nombre de clés : </span> <br />
                    <span>{state?.data?.keyNumber}</span>
                  </Grid2>
                </Grid2>
              )}
            </Grid2>
            <Grid2 size={'auto'}>
              <Grid2 container>
                {images && (
                  <Grid2 size={12}>
                    <div className="hide_scrollbar" style={{ width: '100%', overflowX: 'scroll' }}>
                      <div style={{ position: 'relative', display: 'inline-flex' }}>
                        {images
                          .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                          .map((image, index) => (
                            <div
                              className="product_sheet_image_event"
                              key={index}
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedImage(index);
                                handleOpen(index);
                              }}
                              style={{
                                backgroundImage: `url(${process.env.REACT_APP_BASEURL_MEDIAS}/${image.uri})`
                              }}
                            ></div>
                          ))}
                      </div>
                    </div>
                  </Grid2>
                )}
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>

      <Modal
        open={open}
        onClose={handleClose}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          style={{ position: 'relative', maxWidth: '90%', maxHeight: '90%', borderRadius: '20px', overflow: 'hidden' }}
          {...useSwipeable({
            onSwipedLeft: handleNext,
            onSwipedRight: handlePrev
          })}
        >
          {selectedImageIndex !== null && (
            <>
              {images.length > 1 && (
                <IconButton
                  onClick={handlePrev}
                  style={{
                    position: 'absolute',
                    left: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'rgba(0,0,0,0.5)'
                  }}
                >
                  <ArrowBackIosIcon style={{ color: 'white' }} />
                </IconButton>
              )}
              <img
                src={`${process.env.REACT_APP_BASEURL_MEDIAS}/${images[selectedImageIndex].uri}`}
                alt="Equipment"
                style={{ width: '100%', height: 'auto', maxHeight: '90vh' }}
              />
              {images.length > 1 && (
                <IconButton
                  onClick={handleNext}
                  style={{
                    position: 'absolute',
                    right: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'rgba(0,0,0,0.5)'
                  }}
                >
                  <ArrowForwardIosIcon style={{ color: 'white' }} />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};
