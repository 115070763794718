import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box, Grid2, MenuItem, Typography, Button } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getEvent, patchEvent, getProduct, api } from '~/api';
import { referentielEventTypes } from '~/utils/references';
import Breadcrumb from '~/app/components/Breadcrumb/Breadcrumb';
import WizzardEquipment from '~/app/components/WizzardEquipment/WizzardEquipment';
import WizzardEvent from '~/app/components/WizzardEvent/WizzardEvent';
import EventDetail from '~/app/components/EventDetail/EventDetail';
import EventsList from '~/app/components/EventsList/EventsList';
import ListFilter from '~/app/components/ListFilter/ListFilter';
import '~/app/pages/Events/Events.scss';
import { ProductTitle } from '~/app/components/ProductTitle/ProductTitle';
import normalizedString from '~/utils/normalizedString';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import { BasicModal } from '~/app/components/BasicModal/BasicModal';

interface IEvents {
  id: number;
  diagnosticType: number;
  status: number;
  date: string;
  desc: string;
  equipment: string;
  urgent: boolean;
}

export const Events = ({ opened, setSpinnerOpen, spinnerOpen }) => {
  const [error, setError] = useState({ isError: false, error: {} });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [eventListFilterd, setEventListFiltered] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [eventSelected, setEventSelected] = useState(null);
  const [dataForm, setDataForm] = useState<IEvents>();
  const [selectedByProducts, setSelectedByProducts] = useState({});
  const [uuidEventToRowId, setUuidEventToRowId] = useState({});

  const [walletChecked, setWalletChecked] = useState<boolean>(false);
  const [closedChecked, setClosedChecked] = useState<boolean>(false);
  const [archivedChecked, setArchivedChecked] = useState<boolean>(false);
  const [selectedProductDatas, setSelectedProductDatas] = useState<string>('');

  const [wizzardEquipmentOpen, setWizzardEquipmentOpen] = useState<any>(false);
  const [wizzardEventOpen, setWizzardEventOpen] = useState<any>(false);

  const [wizzardEquipmentCreationMode, setWizzardEquipmentCreationMode] = useState<boolean>(false);
  const [wizzardEventCreationMode, setWizzardEventCreationMode] = useState<boolean>(false);

  const [selectedUUIDEquipment, setSelectedUUIDEquipment] = useState<string>('');
  const [selectedUUIDEvent, setSelectedUUIDEvent] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [typeFilter, setTypeFilter] = useState<number>(0);

  const [selectedEventIndex, setSelectedEventIndex] = useState<number>(null);
  const [productEventsLayout, setProductEventsLayout] = useState<boolean>(false);
  const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
  const [pathContext, setPathContext] = useState('');

  const [data, setData] = useState<any>(null);

  const { id, idEvent } = useParams();

  const scrollableDetail = useRef(null);
  const navigate = useNavigate();

  const mediaQueries = useContext(MediaQueryContext);

  const [openModal, setOpenModal] = useState(false);

  const handleModifyEvent = (dataForm) => {
    if (dataForm === null) return;
    const newEvent = JSON.parse(JSON.stringify(dataForm));
    if (newEvent.tags !== null) delete newEvent.tags;
    if (newEvent.diagnosticType !== null) delete newEvent.diagnosticType;
    if (newEvent.files !== null) delete newEvent.files;
    if (newEvent.data !== null) delete newEvent.data;

    newEvent.status = dataForm.status;
    newEvent.urgent = dataForm.urgent;
    newEvent.kind = dataForm.kind;
    patchEvent(dataForm.uuid, newEvent)
      .then((res) => {
        let selectedEventIndex = null;
        for (const [id, value] of Object.entries(eventListFilterd)) {
          if (uuidEventToRowId[dataForm.uuid] === value.id) {
            selectedEventIndex = Number(id);
          }
        }
        const newRows = JSON.parse(JSON.stringify(eventListFilterd));
        newRows[selectedEventIndex].status = res.status;
        newRows[selectedEventIndex].urgent = res.urgent;
        newRows[selectedEventIndex].diagnosticType = referentielEventTypes[res.kind];
        newRows[selectedEventIndex].kind = res.kind;

        eventSelected.status = res.status;
        eventSelected.urgent = res.urgent;
        eventSelected.diagnosticType = referentielEventTypes[res.kind];
        eventSelected.kind = res.kind;

        if (res.uuid_owner) {
          const pathContext = 'product';

          api[pathContext]
            .get(res.uuid_owner)
            // getProduct(res.uuid_owner)
            .then((data) => {
              setSelectedProductDatas(data);
              setEventListFiltered(newRows);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openModalHandler = () => {
    setOpenModal(true);
  };

  const closeModalHandler = () => {
    setOpenModal(false);
  };

  const getProductData = () => {
    const rows = [];
    if (id)
      api[pathContext]
        .get(id)
        .then((data) => {
          setData(data);
          const rowsByProduct = {};
          const arrSortedDate = data.events
            ? data.events.sort((a, b) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
              })
            : [];
          arrSortedDate.forEach((event, index: number) => {
            const dateStringFr = event.created_at;
            const row = {
              id: index,
              diagnosticType: referentielEventTypes[event.kind],
              status: event.status,
              date: dateStringFr,
              address: `${data.address.street}, ${data.address.zip_code} ${data.address.city}`,
              situation: event.situation,
              desc: event.desc,
              action: 'action',
              uuid: event.uuid,
              uuid_owner: event.uuid_owner,
              urgent: event.urgent,
              selected: true,
              kind: event.kind
            };
            rows.push(row);
            rowsByProduct[event.uuid] = index;
          });
          setUuidEventToRowId(rowsByProduct);
        })
        .finally(() => {
          setSpinnerOpen(false);
          setIsLoading(false);
          setEventListFiltered(rows.filter((row) => row.uuid === idEvent || (row.status !== 4 && row.status !== 5)));
          setEventList(rows);
        });
  };

  const getAllTickets = () => {
    const rows = [];
    api.pages.ticket
      .get()
      .then((data) => {
        let json = { tickets: [] };
        if (data.tickets) {
          json = data;
        } else {
          json = JSON.parse(data);
        }

        const rowsByProduct = {};
        const arrSortedDate = json.tickets.sort((a, b) => {
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        });
        arrSortedDate.forEach((event, index: number) => {
          const dateStringFr = event.created_at;
          const row = {
            id: index,
            diagnosticType: referentielEventTypes[event.kind],
            status: event.status,
            date: dateStringFr,
            address: `${event.address.street}, ${event.address.zip_code} ${event.address.city}`,
            situation: event.situation,
            desc: event.description,
            action: 'action',
            uuid: event.uuid,
            uuid_owner: event.uuid_owner,
            urgent: event.urgent,
            selected: true,
            kind: event.kind,
            iam_in_charge: event.iam_in_charge,
            is_building: event.is_building
          };
          rows.push(row);
          rowsByProduct[event.uuid] = index;
        });
        setUuidEventToRowId(rowsByProduct);
      })
      .finally(() => {
        setSpinnerOpen(false);
        setIsLoading(false);
        setEventListFiltered(rows.filter((row) => row.uuid === idEvent || (row.status !== 4 && row.status !== 5)));
        setEventList(rows);
      });
  };

  const setSelectedProduct = (row) => {
    if (row.uuid_owner)
      getProduct(row.uuid_owner).then((resp) => {
        setSelectedProductDatas(resp);
        setSelectedProduct(row.uuid_owner);

        setSelectedUUIDEvent(row.uuid);
        setWizzardEventCreationMode(false);
        setWizzardEventOpen(true);

        getEvent(row.uuid).then((respEvent) => {
          setDataForm(respEvent);
          setEventSelected(respEvent);
        });
      });
  };

  const onRowClickHandler = (row) => {
    const tempSelectedByProducts = {};
    for (const [uuid_owner, selected] of Object.entries(selectedByProducts)) {
      tempSelectedByProducts[uuid_owner] = row.uuid_owner !== uuid_owner ? false : true;
    }
    setSelectedByProducts(tempSelectedByProducts);
    setSelectedEventIndex(row.id);
    api.events
      .get(row.uuid)
      .then((resp) => {
        setDataForm(resp);
        setEventSelected(resp);
        openModalHandler();
        api.pages.ticket
          .getDetail(row.uuid)
          .then((resp) => {
            setSelectedProductDatas(resp.property);
          })
          .catch((err) => {
            console.log(err);
          });

        if (productEventsLayout) {
          navigate(`/${pathContext}/${id}/events/${row.uuid}`);
        } else {
          navigate(`/events/${row.uuid}`);
        }

        if (scrollableDetail && scrollableDetail.current) {
          scrollableDetail.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  const refreshEvents = () => {
    // Todo Refresh events
    if (window.location.href.indexOf('product/pro-') === -1) {
      setProductEventsLayout(true);
      getProductData();
    } else {
      getAllTickets();
    }
    api.pages.ticket
      .getDetail(idEvent)
      .then((resp) => {
        setSelectedUUIDEvent(idEvent);
        setDataForm(resp.ticket);
        setEventSelected(resp.ticket);
        setSelectedProductDatas(resp.property);
        let selectedEventIndex = null;
        for (const [id, value] of Object.entries(eventListFilterd)) {
          if (uuidEventToRowId[resp.ticket.uuid] === value.id) {
            selectedEventIndex = value.id;
            setSelectedEventIndex(selectedEventIndex);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (dataForm === undefined || dataForm === null) return;
    if (eventSelected === undefined || eventSelected === null) return;
    const hasChanges = JSON.stringify(eventSelected) !== JSON.stringify(dataForm);
    if (hasChanges) {
      if (dataForm && dataForm !== undefined) {
        handleModifyEvent(dataForm);
      }
    }
  }, [dataForm]);

  const saveChanges = (dataForm) => {
    handleModifyEvent(dataForm);
  };

  useEffect(() => {
    if (id === undefined && idEvent === undefined) {
      setEventSelected(null);
      setProductEventsLayout(false);
      getAllTickets();
    } else {
      if (window.location.href.indexOf('product/pro-') === -1) {
        getAllTickets();
      }
      if (idEvent !== null && idEvent !== undefined) {
        api.pages.ticket
          .getDetail(idEvent)
          .then((resp) => {
            setSelectedUUIDEvent(idEvent);
            setDataForm(resp.ticket);
            setEventSelected(resp.ticket);
            setSelectedProductDatas(resp.property);
            let selectedEventIndex = null;
            for (const [id, value] of Object.entries(eventListFilterd)) {
              if (uuidEventToRowId[resp.ticket.uuid] === value.id) {
                selectedEventIndex = value.id;
                setSelectedEventIndex(selectedEventIndex);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [id, idEvent]);

  useEffect(() => {
    const pathname = window.location.pathname.split('/');
    if (pathname[1] === 'product') {
      setPathContext('product');
    } else if (pathname[1] === 'events') {
      setPathContext('events');
    }
  }, []);

  useEffect(() => {
    if (id && pathContext !== '') {
      setProductEventsLayout(true);
      getProductData();
    }
  }, [pathContext]);

  useEffect(() => {}, [eventSelected]);

  useEffect(() => {
    if (eventList.length > 0) {
      if (idEvent) {
        api.pages.ticket
          .getDetail(idEvent)
          .then((resp) => {
            setDataForm(resp.ticket);
            setEventSelected(resp.ticket);
            setSelectedProductDatas(resp.property);
            let selectedEventIndex = null;
            for (const [id, value] of Object.entries(eventListFilterd)) {
              if (uuidEventToRowId[resp.ticket.uuid] === value.id) {
                selectedEventIndex = value.id;
                setSelectedEventIndex(selectedEventIndex);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [eventList]);

  useEffect(() => {
    if (refreshRequired === true) {
      setRefreshRequired(false);
    }
  }, [refreshRequired]);

  // FILTER EVENTS
  useEffect(() => {
    const filteredList = eventList.filter((item) => {
      const normalizedSearch = normalizedString(search);

      return item.address
        ? normalizedString(item.address).indexOf(normalizedSearch) >= 0 ||
            normalizedString(item.desc).indexOf(normalizedSearch) >= 0
        : item;
    });

    const tmpRows = [];

    filteredList.forEach((row) => {
      if (row.kind === typeFilter || typeFilter === 0) {
        if (closedChecked) {
          if (row.status === 4) {
            tmpRows.push(row);
          }
        }

        if (archivedChecked) {
          if (row.status === 5) {
            tmpRows.push(row);
          }
        }

        if (!closedChecked && !archivedChecked) {
          if ((row.status !== 4 && row.status !== 5) || row.uuid === idEvent) {
            tmpRows.push(row);
          }
        }
      }
    });

    const walletCheckFiltered = tmpRows.filter((item) => {
      if (item.iam_in_charge) {
        return item;
      }
    });
    setEventListFiltered(walletChecked ? walletCheckFiltered : tmpRows);
  }, [search, typeFilter, walletChecked, closedChecked, archivedChecked]);

  // Filter Kind
  const handleChangeTypeFilter = (e) => {
    setTypeFilter(e.target.value);
  };

  // Filter Search
  const handleChangeSearchFilter = (e) => {
    const search = e.target.value;
    setSearch(search);
  };

  // Filter Checkboxes
  const handleChangeWalletChecked = (e) => {
    setWalletChecked(e.target.checked);
  };
  const handleChangeClosedChecked = (e) => {
    setClosedChecked(e.target.checked);
  };
  const handleChangeArchivedChecked = (e) => {
    setArchivedChecked(e.target.checked);
  };

  const buildRefEventTypesValues = () => {
    const arrType = [];
    referentielEventTypes.forEach((item, index) => {
      arrType.push(
        <MenuItem key={index} value={Number(index)}>
          {item}
        </MenuItem>
      );
    });
    return arrType;
  };

  return (
    <>
      <div
        style={{
          position: 'absolute',
          width: !mediaQueries.matchesMd ? 'calc(85%)' : 'calc(100% - 38px)',
          height: 'calc(100% - 100px)',
          maxWidth: '1506px'
        }}
      >
        {!spinnerOpen && !error.isError && (
          <Grid2 container spacing={0} sx={{ height: 'calc(100% - 265px)' }}>
            <Grid2 size={12}>
              <Box
                className="Events"
                sx={{
                  width: 1
                }}
              >
                {error.isError && <div className="ProductList__error">{`Une erreur s'est produite`}</div>}

                <Box
                  sx={{
                    width: 1,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h1" sx={{ color: 'primary.main' }}>
                      {productEventsLayout && data && data.address && <ProductTitle data={data} />}
                      {!productEventsLayout && (
                        <>
                          <Box
                            sx={{
                              mt: 2,
                              width: 1,
                              display: 'flex',
                              alignItems: 'center',
                              height: '68.5px'
                            }}
                          >
                            <Box sx={{ flexGrow: 1, mb: 1 }}>
                              <Typography
                                variant={
                                  mediaQueries.matchesXl
                                    ? 'h1'
                                    : mediaQueries.matchesLg
                                      ? 'h2'
                                      : mediaQueries.matchesMd
                                        ? 'h3'
                                        : 'h4'
                                }
                                sx={{ color: 'primary.main', lineHeight: 1 }}
                              >
                                Liste des tickets
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Typography>
                    {productEventsLayout && (
                      <Breadcrumb
                        data={[
                          { label: 'Accueil', href: '/' },
                          { label: 'Portefeuille', href: '/' },
                          {
                            label: 'Fiche du Bien',
                            href: `/${pathContext}/${id}`
                          }
                        ]}
                        last={`Tickets`}
                        setSpinnerOpen={setSpinnerOpen}
                      />
                    )}

                    {!productEventsLayout && (
                      <Breadcrumb
                        data={[{ label: 'Accueil', href: '/' }]}
                        last={`Liste des Tickets`}
                        setSpinnerOpen={setSpinnerOpen}
                      />
                    )}
                  </Box>

                  {/* On garde ça de coté */}

                  {productEventsLayout && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        color="secondary"
                        variant="contained"
                        sx={{ color: 'white', borderRadius: '20px', paddingTop: '8px' }}
                        onClick={() => {
                          setWizzardEventCreationMode(true);
                          setWizzardEventOpen(true);
                        }}
                      >
                        <AddOutlinedIcon fontSize="small" />
                        {!mediaQueries.matchesMd ? (
                          <span style={{ fontWeight: 'bold', fontSize: '15px' }}>&nbsp;Tickets</span>
                        ) : (
                          <span style={{ fontWeight: 'bold', fontSize: '15px' }}>&nbsp; Ajouter un ticket</span>
                        )}
                      </Button>
                    </div>
                  )}
                </Box>
              </Box>
              <ListFilter
                handleChangeTypeFilter={handleChangeTypeFilter}
                buildRefEventTypesValues={buildRefEventTypesValues}
                handleChangeSearchFilter={handleChangeSearchFilter}
                search={search}
                walletChecked={walletChecked}
                handleChangeWalletChecked={handleChangeWalletChecked}
                closedChecked={closedChecked}
                handleChangeClosedChecked={handleChangeClosedChecked}
                archivedChecked={archivedChecked}
                handleChangeArchivedChecked={handleChangeArchivedChecked}
                productEventsLayout={productEventsLayout}
              ></ListFilter>
            </Grid2>

            <Grid2 size={12} sx={{ height: 'calc(100%)' }}>
              <Grid2
                container
                spacing={0}
                sx={{ height: 'calc(100%)' }}
                display={!mediaQueries.matchesMd ? 'flex' : ''}
              >
                <Grid2 size={12} sx={{ height: 'calc(100%)' }}>
                  <EventsList
                    handleModal={null}
                    loading={isLoading}
                    setOpen={null}
                    rows={eventListFilterd}
                    onData={null}
                    onRowsSelectionHandler={null}
                    //uuid_product={product.uuid}
                    onRowClick={onRowClickHandler}
                    eventIndexInList={selectedEventIndex}
                    unselect={selectedByProducts}
                    setWizzardEventCreationMode={setWizzardEventCreationMode}
                    setWizzardEventOpen={setWizzardEventOpen}
                    setSelectedUUIDEvent={setSelectedUUIDEvent}
                    setSelectedProduct={setSelectedProduct}
                  />
                </Grid2>
                {eventSelected && openModal && !error.isError && (
                  <Grid2
                    ref={scrollableDetail}
                    id={'scroll-detail'}
                    size={6}
                    sx={{
                      height: !mediaQueries.matchesMd ? 'calc(85%)' : 'calc(100%)',
                      overflow: 'hidden',
                      overflowY: 'scroll'
                    }}
                  >
                    <BasicModal
                      modalOpen={openModal}
                      onModalOpen={openModalHandler}
                      onModalClose={closeModalHandler}
                      confirmClose={true}
                      handleConfirm={closeModalHandler}
                    >
                      <EventDetail
                        setDataForm={setDataForm}
                        dataForm={dataForm}
                        eventData={eventSelected}
                        idEvent={eventSelected.uuid}
                        setEventData={setEventSelected}
                        productDatas={selectedProductDatas}
                        wizzardEquipmentCreationMode={wizzardEquipmentCreationMode}
                        setWizzardEquipmentOpen={setWizzardEquipmentOpen}
                        setWizzardEquipmentCreationMode={setWizzardEquipmentCreationMode}
                        setSelectedUUIDEquipment={setSelectedUUIDEquipment}
                        setWizzardEventCreationMode={setWizzardEventCreationMode}
                        setWizzardEventOpen={setWizzardEventOpen}
                        setSelectedUUIDEvent={setSelectedUUIDEvent}
                        refreshRequired={refreshRequired}
                      />
                    </BasicModal>
                  </Grid2>
                )}
              </Grid2>
            </Grid2>
          </Grid2>
        )}

        {wizzardEquipmentOpen && (
          <WizzardEquipment
            productDatas={selectedProductDatas}
            setWizzardEquipmentOpen={setWizzardEquipmentOpen}
            wizzardEquipmentCreationMode={wizzardEquipmentCreationMode}
            selectedUUIDEquipment={selectedUUIDEquipment}
            setRefreshRequired={setRefreshRequired}
          ></WizzardEquipment>
        )}
        {wizzardEventOpen && (
          <WizzardEvent
            saveChanges={saveChanges}
            productDatas={selectedProductDatas}
            setWizzardEventOpen={setWizzardEventOpen}
            refreshEvents={refreshEvents}
            wizzardEventCreationMode={wizzardEventCreationMode}
            selectedUUIDEvent={selectedUUIDEvent}
            wizzardOpen={wizzardEventOpen}
          ></WizzardEvent>
        )}
      </div>
    </>
  );
};
