import { Chip, Grid2, Pagination, Rating, Typography } from '@mui/material';
import { Box } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  useGridApiRef,
  GridRowSelectionModel
} from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import './StatesList.scss';
import { AppContext } from '~/utils/context/AppContext';
import { useWindowSize } from '~/utils/useWindowSize';
import { getIcon, getIconState, getStatusWordingState, getVariant, getVariantState } from '../View3d/utilsView3D';

const StatesList = ({
  handleModal,
  loading,
  onRowsSelectionHandler,
  rows,
  onData,
  stateIndexInList = null,
  setOpen,
  uuid_owner = null,
  onRowClick = null,
  unselect = null,
  setWizzardStateCreationMode = null,
  setWizzardStateOpen = null,
  setSelectedUUIDState = null,
  setSelectedProduct = null,
  isMeter = false,
  equipmentKind = null
}) => {
  const { idState } = useParams();
  const [indexInRows, setIndexInRows] = useState(null);
  const [isLoading, setIsloading] = useState(true);

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

  const apiRef = useGridApiRef();

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    if (pageCount === 1) return <div></div>;
    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };

  const columns: GridColDef[] = !isMeter
    ? [
        {
          field: 'desc',
          headerName: 'Détail',
          width: 340,
          flex: 0.6,
          headerClassName: 'cibel-app-theme--header',
          renderCell: (params) => {
            return (
              <Box sx={{ pl: 2, py: 4 }}>
                <Typography variant="body1">{params.row.desc}</Typography>
              </Box>
            );
          }
        },
        {
          field: 'comment',
          headerName: 'Commentaire',
          width: 140,
          flex: 0.6,
          headerClassName: 'cibel-app-theme--header',
          renderCell: (params) => {
            return (
              <Box sx={{ py: 3 }}>
                <Typography variant="body1">{params.row.comment}</Typography>
              </Box>
            );
          }
        },
        {
          field: 'damages',
          headerName: 'Dégradations',
          width: 140,
          flex: 0.6,
          headerClassName: 'cibel-app-theme--header',
          renderCell: (params) => {
            const damagesText = params.row?.damages?.damages
              .map((damage) => damage.number + ' ' + `${damage.number > 1 ? damage.name + 's' : damage.name}`)
              .join(', ');
            return (
              <Box sx={{ py: 3 }}>
                <Typography variant="body1">{damagesText}</Typography>
              </Box>
            );
          }
        },
        {
          field: 'rating',
          headerName: 'Etat',
          width: 140,
          flex: 1,
          headerClassName: 'cibel-app-theme--header',
          renderCell: (params) => {
            return (
              <Box sx={{ pl: 0, py: 4 }}>
                <Rating
                  size="small"
                  name="simple-controlled"
                  value={params.row.rating}
                  readOnly
                  sx={{ color: 'primary.main' }}
                />
              </Box>
            );
          }
        },
        {
          field: 'state',
          headerName: 'Fonctionnement',
          width: 140,
          flex: 1,
          headerClassName: 'cibel-app-theme--header',
          renderCell: (params) => {
            return (
              <Box sx={{ pr: 2, py: 3 }}>
                {params?.row?.state !== 0 && (
                  <Chip
                    size="small"
                    icon={getIcon(params.row.state)}
                    label={getStatusWordingState(params.row.state)}
                    color={getVariant(params.row.state)}
                    sx={{
                      color: getVariant(params.row.state) + '.main',
                      backgroundColor: getVariant(params.row.state) + '.light'
                    }}
                  />
                )}
              </Box>
            );
          }
        }
      ]
    : equipmentKind === 265
      ? [
          {
            field: 'desc',
            headerName: 'Détail',
            width: 340,
            flex: 0.5,
            headerClassName: 'cibel-app-theme--header',
            renderCell: (params) => {
              return (
                <Box sx={{ pl: 2, py: 4 }}>
                  <Typography variant="body1">{params.row.desc}</Typography>
                </Box>
              );
            }
          },
          {
            field: 'comment',
            headerName: 'Commentaire',
            width: 140,
            flex: 0.5,
            headerClassName: 'cibel-app-theme--header',
            renderCell: (params) => {
              return (
                <Box sx={{ py: 3 }}>
                  <Typography variant="body1">{params.row.comment}</Typography>
                </Box>
              );
            }
          },
          {
            field: 'consommation HC',
            headerName: 'Consommation HC',
            width: 340,
            flex: 0.5,
            headerClassName: 'cibel-app-theme--header',
            renderCell: (params) => {
              return (
                <Box sx={{ pl: 0, py: 4 }}>
                  <Typography variant="body1">
                    {params?.row?.data?.meterValue
                      ? params.row.data.meterValue + ' ' + params.row.data.meterUnity
                      : null}
                  </Typography>
                </Box>
              );
            }
          },
          {
            field: 'consommation HP',
            headerName: 'Consommation HP',
            width: 340,
            flex: 0.5,
            headerClassName: 'cibel-app-theme--header',
            renderCell: (params) => {
              return (
                <Box sx={{ pl: 0, py: 4 }}>
                  <Typography variant="body1">
                    {params?.row?.data?.meterValue2
                      ? params.row.data.meterValue2 + ' ' + params.row.data.meterUnity
                      : null}
                  </Typography>
                </Box>
              );
            }
          },
          {
            field: 'serial',
            headerName: 'N° de série',
            width: 340,
            flex: 0.5,
            headerClassName: 'cibel-app-theme--header',
            renderCell: (params) => {
              return (
                <Box sx={{ pl: 0, py: 4 }}>
                  <Typography variant="body1">
                    {params?.row?.data?.serialNumber ? params.row.data.serialNumber : null}
                  </Typography>
                </Box>
              );
            }
          },
          {
            field: 'state',
            headerName: 'Fonctionnement',
            width: 140,
            headerClassName: 'cibel-app-theme--header',
            renderCell: (params) => {
              return (
                <Box sx={{ pr: 2, py: 3 }}>
                  {params?.row?.state !== 0 && (
                    <Chip
                      size="small"
                      icon={getIconState(params.row.state)}
                      label={getStatusWordingState(params.row.state)}
                      color={getVariantState(params.row.state)}
                      sx={{
                        color: getVariantState(params.row.state) + '.main',
                        backgroundColor: getVariantState(params.row.state) + '.light'
                      }}
                    />
                  )}
                </Box>
              );
            }
          }
        ]
      : [
          {
            field: 'desc',
            headerName: 'Détail',
            width: 340,
            flex: 0.5,
            headerClassName: 'cibel-app-theme--header',
            renderCell: (params) => {
              return (
                <Box sx={{ pl: 2, py: 4 }}>
                  <Typography variant="body1">{params.row.desc}</Typography>
                </Box>
              );
            }
          },
          {
            field: 'comment',
            headerName: 'Commentaire',
            width: 140,
            flex: 0.5,
            headerClassName: 'cibel-app-theme--header',
            renderCell: (params) => {
              return (
                <Box sx={{ py: 3 }}>
                  <Typography variant="body1">{params.row.comment}</Typography>
                </Box>
              );
            }
          },
          {
            field: 'consommation',
            headerName: 'Consommation',
            width: 340,
            flex: 0.5,
            headerClassName: 'cibel-app-theme--header',
            renderCell: (params) => {
              return (
                <Box sx={{ pl: 0, py: 4 }}>
                  <Typography variant="body1">
                    {params?.row?.data?.meterValue
                      ? params.row.data.meterValue + ' ' + params.row.data.meterUnity
                      : null}
                  </Typography>
                </Box>
              );
            }
          },
          {
            field: 'serial',
            headerName: 'N° de série',
            width: 340,
            flex: 0.5,
            headerClassName: 'cibel-app-theme--header',
            renderCell: (params) => {
              return (
                <Box sx={{ pl: 0, py: 4 }}>
                  <Typography variant="body1">
                    {params?.row?.data?.serialNumber ? params.row.data.serialNumber : null}
                  </Typography>
                </Box>
              );
            }
          },
          {
            field: 'state',
            headerName: 'Fonctionnement',
            width: 140,
            headerClassName: 'cibel-app-theme--header',
            renderCell: (params) => {
              return (
                <Box sx={{ pr: 2, py: 3 }}>
                  {params?.row?.state !== 0 && (
                    <Chip
                      size="small"
                      icon={getIconState(params.row.state)}
                      label={getStatusWordingState(params.row.state)}
                      color={getVariantState(params.row.state)}
                      sx={{
                        color: getVariantState(params.row.state) + '.main',
                        backgroundColor: getVariantState(params.row.state) + '.light'
                      }}
                    />
                  )}
                </Box>
              );
            }
          }
        ];

  useEffect(() => {
    if (idState === undefined) {
      setIsloading(false);
    }
    if (stateIndexInList) {
      setSelectionModel([stateIndexInList]);
    }
  }, []);

  useEffect(() => {
    if (stateIndexInList !== null && stateIndexInList !== undefined) {
      setSelectionModel([stateIndexInList]);
      const indexInRows = rows.findIndex((row) => row.id === stateIndexInList);
      setIndexInRows(indexInRows);
    }
  }, [stateIndexInList]);

  useEffect(() => {
    if (apiRef && indexInRows !== null && indexInRows !== undefined && apiRef.current) {
      apiRef.current.setPaginationModel({ page: Math.floor(indexInRows / 5), pageSize: 5 });
      apiRef.current.scrollToIndexes({ rowIndex: indexInRows, colIndex: 0 });
      setIsloading(false);
    }
  }, [indexInRows, apiRef]);

  useEffect(() => {
    if (stateIndexInList === 0) {
      setIsloading(false);
    }
    if (apiRef && stateIndexInList) {
      const indexInRows = rows.findIndex((row) => row.id === stateIndexInList);
      setIndexInRows(indexInRows);
    }
  }, [apiRef, stateIndexInList]);

  useEffect(() => {
    if (unselect && unselect[uuid_owner] === false) {
      setSelectionModel([]);
    }
  }, [unselect]);

  const testIfFooter = rows.length < 6 ? true : false;

  return (
    <Grid2 container sx={{ height: 'calc(100%)' }}>
      <Grid2 size={12} className="hidescrollbar">
        {rows.length > 0 ? (
          <DataGrid
            apiRef={apiRef}
            className="StatesList"
            sx={{ maxHeight: `${window.innerHeight - 380}px` }}
            loading={isLoading}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            hideFooterSelectedRowCount
            hideFooter={testIfFooter}
            getRowHeight={() => 'auto'}
            rows={rows}
            columns={columns}
            pageSizeOptions={[5]}
            columnVisibilityModel={{
              uuid: false,
              uuid_owner: false
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'date', sort: 'desc' }]
              },
              pagination: { paginationModel: { page: 0, pageSize: 5 } }
            }}
            sortingOrder={['desc', 'asc']}
            checkboxSelection={false}
            disableColumnMenu={true}
            onRowSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            rowSelectionModel={selectionModel}
            slots={{
              pagination: CustomPagination
            }}
          />
        ) : null}
      </Grid2>
    </Grid2>
  );
};

export default StatesList;
