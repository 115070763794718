import {
  FormControl,
  Grid2,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { EventItem } from './EventItem';
import { EventDetails } from './EventDetails';
import { useContext, useEffect, useState } from 'react';
import { iconFilters } from '../View3d/utilsView3D';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import normalizedString from '~/utils/normalizedString';
import { AppContext } from '~/utils/context/AppContext';

import _ from 'lodash';
import { CreateEventForm } from './CreateEventForm';

export const EventsListView3d = ({
  events,
  eventSelected,
  setEventSelected,
  setUuidTagSelected,
  uuidTagSelected,
  navigateToTag,
  setEquipmentSelected,
  productDatas = null,
  showTags,
  refreshData,
  createEntityMode,
  setCreateEntityMode,
  hasNoMatterportScan,
  setModalOpen,
  setActiveMainToolId,
  setLinkedEntityUuid,
  linkedEntityUuid,
  setWaitingForApiResponse,
  setNewEntityCreated,
  isLandscape
}) => {
  const { referencial } = useContext(AppContext);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [sortedEvents, setSortedEvents] = useState([]);
  const [hoverFilterButton, setHoverFilterButton] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedEventStatus, setSelectedEventStatus] = useState(null);
  const [eventStatus, setEventStatus] = useState([]);

  useEffect(() => {
    if (events) {
      // Récupérer les type d'événements

      const eventTypesTmp = _.uniq(events.map((event) => event.kind));
      setEventTypes(eventTypesTmp);

      const eventStatusTmp = _.uniq(events.map((event) => event.status));
      setEventStatus(eventStatusTmp);

      setFilteredEvents([...events]);
    }
  }, [events]);

  useEffect(() => {
    const tagsToShow = [];
    setSortedEvents(
      filteredEvents.sort((a, b) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      })
    );
    filteredEvents.forEach((event) => {
      if (event.tags) {
        event.tags.forEach((tag) => {
          tagsToShow.push(tag.uuid);
        });
      }
    });
    showTags(tagsToShow);
  }, [filteredEvents]);

  useEffect(() => {
    if (showFilters === false) {
      setFilteredEvents(events);
    }
  }, [showFilters]);

  useEffect(() => {
    if (selectedType) {
      const filteredEquipmentsTmp = events.filter((event) => event.kind === selectedType);
      setFilteredEvents(filteredEquipmentsTmp);
    } else {
      setFilteredEvents(events);
    }
  }, [selectedType]);

  useEffect(() => {
    if (selectedEventStatus) {
      const filteredEquipmentsTmp = events.filter((event) => event.status === selectedEventStatus);
      setFilteredEvents(filteredEquipmentsTmp);
    } else {
      setFilteredEvents(events);
    }
  }, [selectedEventStatus]);

  useEffect(() => {
    const filteredEquipmentsTmp = events.filter(
      (event) =>
        normalizedString(event.desc).includes(normalizedString(searchInputValue)) ||
        normalizedString(
          _.find(referencial['event.kind'], { id: Number(event.kind) })
            ? _.find(referencial['event.kind'], { id: Number(event.kind) })?.name
            : ''
        ).includes(normalizedString(searchInputValue))
    );

    setSelectedType(null);
    setSelectedEventStatus(null);
    setFilteredEvents(filteredEquipmentsTmp);
  }, [searchInputValue]);

  if (createEntityMode) {
    return (
      <CreateEventForm
        refreshData={refreshData}
        productDatas={productDatas}
        setSelectedEvent={setEventSelected}
        hasNoMatterportScan={hasNoMatterportScan}
        setModalOpen={setModalOpen}
        setCreateEntityMode={setCreateEntityMode}
        setActiveMainToolId={setActiveMainToolId}
        setLinkedEntityUuid={setLinkedEntityUuid}
        setWaitingForApiResponse={setWaitingForApiResponse}
        linkedEntityUuid={linkedEntityUuid}
        setNewEntityCreated={setNewEntityCreated}
      ></CreateEventForm>
    );
  }

  if (eventSelected) {
    return (
      <EventDetails
        setEquipmentSelected={setEquipmentSelected}
        event={eventSelected}
        productDatas={productDatas}
        setUuidTagSelected={setUuidTagSelected}
        refreshData={refreshData}
        setCreateEntityMode={setCreateEntityMode}
        setActiveMainToolId={setActiveMainToolId}
        setLinkedEntityUuid={setLinkedEntityUuid}
        isLandscape={isLandscape}
      ></EventDetails>
    );
  }
  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Grid2
          container
          spacing={0}
          alignItems={'flex-start'}
          sx={{ borderBottom: '0px solid #e0e0e0', padding: '10px 0' }}
        >
          <Grid2 size={'grow'}>
            {showFilters && events.length > 1 && (
              <Grid2 container spacing={1}>
                <Grid2 size={{ xs: 6 }}>
                  <TextField
                    size="small"
                    fullWidth
                    autoComplete="off"
                    label="Recherche"
                    onChange={(e) => {
                      setSearchInputValue(e.target.value);
                      setSelectedType(null);
                    }}
                    value={searchInputValue}
                    InputProps={
                      searchInputValue !== ''
                        ? {
                            endAdornment: (
                              <InputAdornment position="start">
                                <HighlightOffIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setSearchInputValue('');
                                  }}
                                ></HighlightOffIcon>
                              </InputAdornment>
                            ),
                            style: {
                              paddingRight: 0
                            }
                          }
                        : {}
                    }
                  ></TextField>
                </Grid2>

                <Grid2 size={{ xs: 6 }}>
                  <FormControl fullWidth>
                    <InputLabel size="small" id="demo-simple-select-label">
                      Par type
                    </InputLabel>
                    <Select
                      defaultValue=""
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedType ? selectedType : ''}
                      label="Par type"
                      onChange={(e) => {
                        setSearchInputValue('');
                        setSelectedEventStatus(null);
                        setSelectedType(Number(e.target.value));
                      }}
                      size="small"
                    >
                      {eventTypes.map((type, index) => {
                        if (_.find(referencial['event.kind'], { id: Number(type) })?.name) {
                          return (
                            <MenuItem key={index} value={type}>
                              {_.find(referencial['event.kind'], { id: Number(type) })?.name}
                            </MenuItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                      {selectedType && (
                        <MenuItem value={null}>
                          <ListItemIcon>
                            <HighlightOffIcon></HighlightOffIcon>
                          </ListItemIcon>
                          <ListItemText primary="Réinitialiser" />
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid2>

                {eventStatus.length > 1 && (
                  <Grid2 size={{ xs: 6 }}>
                    <FormControl fullWidth>
                      <InputLabel size="small" id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        defaultValue=""
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedEventStatus ? selectedEventStatus : ''}
                        label="Status"
                        onChange={(e) => {
                          setSearchInputValue('');
                          setSelectedType(null);
                          setSelectedEventStatus(Number(e.target.value));
                        }}
                        size="small"
                      >
                        {eventStatus.map((category, index) => {
                          return (
                            <MenuItem key={index} value={category}>
                              {_.find(referencial['event.status'], { id: Number(category) }).name}
                            </MenuItem>
                          );
                        })}
                        {selectedEventStatus && (
                          <MenuItem value={null}>
                            <ListItemIcon>
                              <HighlightOffIcon></HighlightOffIcon>
                            </ListItemIcon>
                            <ListItemText primary="Réinitialiser" />
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid2>
                )}
              </Grid2>
            )}
          </Grid2>
          {events && events.length > 1 && (
            <Grid2
              size={'auto'}
              sx={{ width: '32px', cursor: 'pointer', pl: '10px' }}
              onMouseEnter={() => setHoverFilterButton(true)}
              onMouseLeave={() => setHoverFilterButton(false)}
              onClick={() => setShowFilters(!showFilters)}
            >
              {iconFilters(hoverFilterButton || showFilters ? 'rgb(241, 118, 51)' : '#000')}
            </Grid2>
          )}
        </Grid2>
      </Grid2>

      {(!sortedEvents || sortedEvents.length === 0) && (
        <Grid2 size={12}>
          <div style={{ fontSize: '12px', color: '#9e9e9e' }}>Aucun ticket</div>
        </Grid2>
      )}
      {sortedEvents.map((event, index) => (
        <Grid2 size={12} key={`${event.uuid}-${index}`}>
          <EventItem
            event={event}
            eventSelected={eventSelected}
            setEventSelected={setEventSelected}
            setUuidTagSelected={setUuidTagSelected}
            productDatas={productDatas}
          />
        </Grid2>
      ))}
    </Grid2>
  );
};

export default EventsListView3d;
