import { Chip, Grid2 } from '@mui/material';
import dayjs from 'dayjs';
import { useContext, useEffect } from 'react';
import { AppContext } from '~/utils/context/AppContext';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import { getOdmChipIcon, getOdmStatusWording, getOdmTypeLabel, getOdmVariant } from '~/utils/references';

export const MissionOrderItem = ({
  missionOrder,
  missionOrderSelected = null,
  setMissionOrderSelected = null,
  isLast = null,
  openSummary = null
}) => {
  const { referencial } = useContext(AppContext);
  const mediaQueries = useContext(MediaQueryContext);

  return (
    <>
      {missionOrder && (
        <div>
          <Grid2
            size={12}
            id={missionOrder.uuid}
            sx={{
              cursor: 'pointer',
              backgroundColor: missionOrderSelected?.uuid === missionOrder.uuid ? '#EEEEEE' : '#0000',
              p: '10px'
            }}
            onClick={() => {
              setMissionOrderSelected(missionOrder);
              openSummary();
            }}
          >
            <Grid2 container>
              <Grid2 size={8} sx={{ pb: mediaQueries.matchesMd ? '0px' : '10px' }}>
                <span style={{ color: '#929292' }}>Ref Dossier </span> {!mediaQueries.matchesMd && <br />}
                <span>{missionOrder?.ref_internal}</span>
              </Grid2>
              <Grid2 size={4}>
                <Chip
                  size="small"
                  icon={getOdmChipIcon(missionOrder)}
                  label={getOdmStatusWording(missionOrder, referencial)}
                  sx={{
                    color: getOdmVariant(missionOrder) + '.main',
                    backgroundColor: getOdmVariant(missionOrder) + '.light'
                  }}
                />
              </Grid2>
              <Grid2 size={8} sx={{ pb: mediaQueries.matchesMd ? '0px' : '10px' }}>
                <span style={{ color: '#929292' }}>Adresse </span> {!mediaQueries.matchesMd && <br />}
                <span>
                  {missionOrder?.data?.address?.street}
                  {missionOrder?.data?.address_complement} {missionOrder?.data?.address?.zip_code}{' '}
                  {missionOrder?.data?.address?.city}
                </span>
              </Grid2>
              <Grid2 size={4}>
                <span style={{ color: '#929292' }}>Type </span> {!mediaQueries.matchesMd && <br />}
                <span>{getOdmTypeLabel(missionOrder?.kind, missionOrder?.edl?.kind)}</span>
              </Grid2>
              <Grid2 size={mediaQueries.matchesMd ? 8 : 6} sx={{ pb: mediaQueries.matchesMd ? '0px' : '10px' }}>
                {missionOrder?.appointment_date ? (
                  <>
                    <span style={{ color: '#929292' }}>Date de rendez-vous </span> {!mediaQueries.matchesMd && <br />}
                    <span>
                      {dayjs(missionOrder?.appointment_date).format('DD/MM/YYYY')} -{' '}
                      {dayjs(missionOrder?.appointment_date).format('HH:mm')}
                    </span>
                  </>
                ) : (
                  <>
                    <span style={{ color: '#929292' }}>Date de création </span> {!mediaQueries.matchesMd && <br />}
                    <span>{dayjs(missionOrder.created_at).format('DD/MM/YYYY')}</span>
                  </>
                )}
              </Grid2>
              <Grid2 size={mediaQueries.matchesMd ? 4 : 6}>
                <span style={{ color: '#929292' }}>Technicien Assigné </span> {!mediaQueries.matchesMd && <br />}
                <span>
                  {missionOrder?.user_in_charge?.first_name} {missionOrder?.user_in_charge?.last_name}
                </span>
              </Grid2>
            </Grid2>
          </Grid2>
          {!isLast && <div style={{ borderTop: '1px solid #EEEEEE', paddingBottom: '10px' }} />}
        </div>
      )}
    </>
  );
};
