import { axiosGet } from '.';

export const referencial = () => {
  return axiosGet(`/referencial`);
};

export const equipmentreferencial = () => {
  return axiosGet(`/referencial/v2/parsed`);
};

export const edlReferencial = () => {
  return axiosGet(`/referencial/v2`);
};
