import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { getIconState, getVariantState } from '../View3d/utilsView3D';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '~/utils/context/AppContext';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import _ from 'lodash';
import { DegradationKind } from './DegradationKind';
import { api } from '~/api';

// Define the EditStateFormProps type
interface EditStateFormProps {
  equipment: any; // Replace 'any' with the actual type of 'equipment'
  onClose: () => void;
  state?: any; // Replace 'any' with the actual type of 'stateData'
  generateSubtitleEquipment: (equipment: any) => string;
  isLandscape?: boolean;
}

export const EditStateForm = ({
  equipment,
  onClose,
  state = null,
  generateSubtitleEquipment = null,
  isLandscape = null
}: EditStateFormProps) => {
  const { referencial, edlReferencial } = useContext(AppContext);
  const mediaQueries = useContext(MediaQueryContext);
  const [tmpStateData, setTmpStateData] = useState({ ...state });
  const [elligibleDamages, setElligibleDamages] = useState([]);

  const [equipmentIsMeter, setEquipmentIsMeter] = useState(false);

  useEffect(() => {
    console.log('state', state);
    if (state) {
      setTmpStateData({ ...state });
    }
  }, [state]);

  useEffect(() => {
    console.log('tmpStateData', tmpStateData);
  }, [tmpStateData]);

  useEffect(() => {
    console.log(edlReferencial);
    console.log(_.find(edlReferencial['equipmentsKind'], { id: equipment.kind }));
    const equipmentFromEdlReferencial = _.find(edlReferencial['equipmentsKind'], { id: equipment.kind });
    const natureFromEdlReferencial = _.find(equipmentFromEdlReferencial['nature'], { id: equipment.nature });
    console.log(natureFromEdlReferencial);
    const damagesFromEdlReferencial = natureFromEdlReferencial
      ? edlReferencial['damages'].filter((damage) => natureFromEdlReferencial['damage'].includes(damage.id))
      : edlReferencial['damages'];
    console.log(damagesFromEdlReferencial);

    setElligibleDamages(damagesFromEdlReferencial);

    // Condition hyper cheulou pour déterminer le type d'équipement
    const kind =
      state && state.kind
        ? state.kind
        : equipment?.degradationEquipment?.deleted === true || equipment?.equipment?.deleted === true
          ? 3
          : equipment.tab === 'degradations'
            ? 2
            : 1;

    setTmpStateData({ ...tmpStateData, kind: kind });

    console.log('equipment', equipment);
    console.log(_.find(edlReferencial['equipmentsKind'], { id: equipment.kind }).equipmentCategoryKinds[0]);

    setEquipmentIsMeter(
      equipment.category_kind === 4 ||
        _.find(edlReferencial['equipmentsKind'], { id: equipment.kind }).equipmentCategoryKinds[0] === 4
    );
  }, [edlReferencial, equipment]);

  const stateStateList = Object.entries(referencial['equipment_state.status']).map(
    ([key, value]: [string, { id: number; name: string }]) => {
      if (value.id === 0) return null;
      return <MenuItem value={value.id}>{value.name}</MenuItem>;
    }
  );

  useEffect(() => {
    console.log('equipmentIsMeter', equipmentIsMeter);
  }, [equipmentIsMeter]);

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {'Etat : ' + generateSubtitleEquipment(equipment)}
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Grid2 container>
          <Grid2 size={12} sx={{ pb: '10px' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Etat
            </Typography>
            <Rating
              size={mediaQueries.matchesSm ? 'medium' : 'small'}
              value={tmpStateData?.rating ?? null}
              onChange={(e) => {
                const target = e.target as HTMLInputElement;
                setTmpStateData({ ...tmpStateData, rating: Number(target.value) });
              }}
            ></Rating>
          </Grid2>
          {tmpStateData?.state !== 0 && (
            <Grid2 size={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="energy">
                  Fonctionnement
                </InputLabel>
                <Select
                  size="small"
                  labelId="state_state"
                  label="Fonctionnement"
                  value={tmpStateData.state ? tmpStateData.state : ''}
                  onChange={(e) => {
                    setTmpStateData({ ...tmpStateData, state: Number(e.target.value) });
                  }}
                >
                  {stateStateList}
                </Select>
              </FormControl>
            </Grid2>
          )}
          {!equipmentIsMeter && (
            <Grid2 size={12}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Dégradations
              </Typography>
              <Grid2 container spacing={2}>
                {elligibleDamages.length > 0 &&
                  elligibleDamages.map((degredation) => (
                    <DegradationKind
                      key={degredation.id}
                      data={tmpStateData?.data?.damages}
                      degredation={degredation}
                      onChange={(damageValue) => {
                        setTmpStateData((prevState) => {
                          const updatedDamages = prevState.data?.damages ? [...prevState.data.damages] : [];
                          const damageIndex = updatedDamages.findIndex((damage) => damage.id === damageValue.id);

                          if (damageValue.number === 0) {
                            if (damageIndex !== -1) {
                              updatedDamages.splice(damageIndex, 1); // Remove the damage if number is 0
                            }
                          } else {
                            if (damageIndex !== -1) {
                              updatedDamages[damageIndex] = damageValue;
                            } else {
                              updatedDamages.push(damageValue);
                            }
                          }

                          return {
                            ...prevState,
                            data: {
                              ...prevState.data,
                              damages: updatedDamages
                            }
                          };
                        });
                      }}
                      isLandscape={isLandscape}
                    />
                  ))}
              </Grid2>
            </Grid2>
          )}

          <Grid2 size={12}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Commentaire
            </Typography>
            <TextField
              size="small"
              fullWidth
              value={tmpStateData.comment ?? ''}
              onChange={(e) => {
                setTmpStateData({ ...tmpStateData, comment: e.target.value });
              }}
            ></TextField>
          </Grid2>

          {!equipmentIsMeter && (
            <Grid2 size={12}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Nécéssite un nettoyage
              </Typography>
              <Checkbox
                value={tmpStateData.is_dirty}
                onChange={() => {
                  setTmpStateData({ ...tmpStateData, is_dirty: !tmpStateData.is_dirty });
                }}
              ></Checkbox>
            </Grid2>
          )}

          {equipmentIsMeter && (
            <>
              <Grid2 size={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {equipment.kind === 265 ? 'Relevé Consommation heures pleines' : 'Relevé Consommation'}
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  value={tmpStateData?.data?.meterValue ?? ''}
                  onChange={(e) => {
                    setTmpStateData({
                      ...tmpStateData,
                      data: {
                        ...tmpStateData.data,
                        meterValue: e.target.value,
                        meterUnit: equipment.kind === 265 || equipment.kind === 192 ? 'KW' : 'm3'
                      }
                    });
                  }}
                ></TextField>
              </Grid2>

              {equipment.kind === 265 && (
                <Grid2 size={12}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    Relevé Consommation heures creuses
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    value={tmpStateData?.data?.meterValue2 ?? ''}
                    onChange={(e) => {
                      setTmpStateData({
                        ...tmpStateData,
                        data: {
                          ...tmpStateData.data,
                          meterValue2: e.target.value,
                          meterUnit: equipment.kind === 265 || equipment.kind === 192 ? 'KW' : 'm3'
                        }
                      });
                    }}
                  ></TextField>
                </Grid2>
              )}
            </>
          )}
        </Grid2>
      </Grid2>

      <Grid2 size={12} sx={{ mt: '15px', position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
        <Grid2 container spacing={2} justifyContent={'center'} alignItems={'center'}>
          <Grid2>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
              }}
            >
              Annuler
            </Button>
          </Grid2>
          <Grid2>
            <Button
              variant="contained"
              onClick={() => {
                console.log('tmpStateData', tmpStateData);
                if (tmpStateData.uuid) {
                  api.equipments.states.update(equipment.uuid, tmpStateData.uuid, tmpStateData).then((res) => {
                    onClose();
                  });
                } else {
                  api.equipments.states.create(equipment.uuid, tmpStateData).then((res) => {
                    onClose();
                  });
                }
              }}
            >
              Valider
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default EditStateForm;
