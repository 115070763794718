import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  TextField,
  Typography,
  Autocomplete
} from '@mui/material';
import { FC, useEffect, useState, useContext } from 'react';
import { Toaster } from 'react-hot-toast';
import { api } from '~/api';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { WizzardContact } from '../WizzardContact/WizzardContact';
import { auth } from '~/app/components/Auth/AuthApi';
import { referentielEventTypes } from '~/utils/references';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

interface EventShareDialogProps {
  open: boolean;
  onClose: () => void;
  eventData: any;
  isFromProductPage?: boolean;
  dataToShare?: any;
  address?: any;
}

export const EventShareDialog: FC<EventShareDialogProps> = (props) => {
  const { open, onClose, eventData, isFromProductPage, dataToShare, address, ...other } = props;
  const claims = auth.getJWT();
  const [user, setUser] = useState({} as any);
  const [getAllContacts, setGetAllContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [openWizzardContact, setOpenWizzardContact] = useState(false);
  const [contacts, setContacts] = useState([]);
  const mediaQueries = useContext(MediaQueryContext);

  useEffect(() => {
    const fetchContacts = async () => {
      if (dataToShare?.address) {
        try {
          const res = await api.product.contact.get(dataToShare.uuid);
          setContacts(res);
        } catch (error) {
          console.error('Failed to fetch contacts:', error);
        }
      }
    };

    fetchContacts();
  }, [dataToShare]);

  useEffect(() => {
    api.users.get(claims.uuid_user).then((res) => {
      setUser(res);
    });
    if (!isFromProductPage && eventData) defaultInformation();
    if (isFromProductPage && dataToShare) handleDataFromProductPage();
  }, [contacts]);

  const defaultInformation = () => {
    const date =
      new Date(eventData.created_at).toLocaleDateString('fr-FR') +
      ' à ' +
      new Date(eventData.created_at).toLocaleTimeString('fr-FR');

    const addressComplete = `${address.street} ${address.zip_code} ${address.city}`;
    const name = `${eventData?.user_creator?.first_name} ${eventData?.user_creator?.last_name}`;
    const email = eventData?.user_creator?.email;
    const phone = eventData?.user_creator?.phone1;
    const incidentType = referentielEventTypes[eventData.kind];
    const description = eventData.desc;
    const link = `${process.env.REACT_APP_BASEURL_PUBLIC}viewEvent/${eventData.uuid}`;

    const incidentText = eventData.kind !== 0 ? incidentType : 'Non renseigné';

    const defaultMessage = `\nAdresse : ${addressComplete}\nDate du signalement : ${date}\nNom : ${name}\nEmail : ${email}\nTéléphone : ${phone}\nType d'incident : ${incidentText}\nDescription : ${description}\nLien : ${link}`;

    setMessage(defaultMessage);
  };

  const handleDataFromProductPage = () => {
    if (dataToShare.link !== undefined) {
      const link = dataToShare;
      const defaultMessage = `Lien : ${link}`;
      setMessage(defaultMessage);
    } else {
      const { street, zip_code, city } = dataToShare.address;
      const addressComplement = dataToShare.address_complement || '';
      const code = dataToShare?.address?.building_access;

      const addressParts = [street, zip_code, city, addressComplement].filter(Boolean);
      const address = addressParts.join(' ');

      let defaultMessage = code ? `Adresse : ${address}\nCode : ${code}` : `Adresse : ${address}`;

      const tenants = contacts?.filter((contact) => contact.kind === 2);
      if (tenants && tenants.length > 0) {
        const tenantsInfo = tenants.map((tenant) => `${tenant.name} ${tenant.email} ${tenant.phone1}`).join(', ');
        defaultMessage += `\nLocataire(s) : ${tenantsInfo}`;
      }

      setMessage(defaultMessage);
    }
  };

  const handleOpenWizzardContact = () => {
    setOpenWizzardContact(!openWizzardContact);
  };
  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
  };

  const handleSendMail = () => {
    const userMail = user.email;

    const mailsContacts = [...selectedContacts.map((contact) => contact.email)];
    mailsContacts.unshift(userMail);

    const mailMessage = message;

    const data = {
      to_emails: mailsContacts,
      subject: subject,
      body: mailMessage
    };

    api.mails.send(data).then((res) => {
      onClose();
    });
  };

  useEffect(() => {
    api.contacts.search().then((res) => {
      setGetAllContacts(res);
    });
  }, []);

  return (
    <>
      <Dialog
        sx={{ p: mediaQueries.matchesMd && 6, borderRadius: '20px', width: !mediaQueries.matchesMd ? '100%' : '95%' }}
        onClose={onClose}
        open={open}
        {...other}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="h5" sx={{ color: 'primary.main' }}>
            {isFromProductPage ? 'Partager' : "Partager l'incident"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid2 container spacing={2}>
            <Grid2 size={12} sx={{ width: '45vw', height: 'auto' }}></Grid2>
            <Grid2 size={12}>
              <Autocomplete
                multiple
                id="contacts-autocomplete"
                options={getAllContacts}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  setSelectedContacts(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="Contacts" />}
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) => option.name.toLowerCase().includes(inputValue.toLowerCase()))
                }
                value={selectedContacts}
                noOptionsText="Aucun contact trouvé, veuillez créer un contact."
              />
            </Grid2>
            <Grid2 size={8} sx={{ mb: '1vh' }}>
              <Button
                color="primary"
                variant="outlined"
                sx={{ color: 'primary', borderRadius: '20px', paddingTop: '8px', borderColor: 'primary.main' }}
                onClick={handleOpenWizzardContact}
              >
                <AddOutlinedIcon fontSize="small" style={{ paddingBottom: '2px' }} />
                <span style={{ fontSize: '15px' }}>
                  &nbsp;{mediaQueries.matchesMd ? 'Créer un contact' : 'Contact'}
                </span>
              </Button>
            </Grid2>
            <Grid2 size={12}>
              <TextField fullWidth label="Objet" name="subject" onChange={handleSubjectChange} value={subject} />
            </Grid2>
            <Grid2 size={12}>
              <TextField
                multiline
                rows={9}
                fullWidth
                label="Votre message"
                name="message"
                onChange={handleMessageChange}
                value={message}
              />
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose} variant="text">
            Annuler
          </Button>
          <Button
            color="primary"
            size="medium"
            onClick={handleSendMail}
            disabled={selectedContacts.length === 0 || message === '' || subject === ''}
            endIcon={
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.4504 11.9478L3.45042 2.94776C3.08847 2.76101 2.64942 2.81243 2.34042 3.07776C2.03269 3.34502 1.91796 3.7723 2.05042 4.15776L5.00042 12.8378L2.10042 21.5178C1.99957 21.8152 2.04431 22.1427 2.22124 22.4022C2.39816 22.6617 2.68671 22.823 3.00042 22.8378C3.15696 22.8368 3.3111 22.7991 3.45042 22.7278L21.4504 13.7278C21.7844 13.5567 21.9945 13.213 21.9945 12.8378C21.9945 12.4625 21.7844 12.1189 21.4504 11.9478ZM4.71042 19.8378L6.71042 13.8378H16.7104L4.71042 19.8378ZM4.71042 5.83776L6.71042 11.8378H16.7604L4.71042 5.83776Z"
                  fill="white"
                />
              </svg>
            }
            variant="contained"
          >
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster />
      {openWizzardContact && (
        <WizzardContact
          open={open}
          isProductPage={false}
          onClose={handleOpenWizzardContact}
          isNewContact={true}
          contacts={getAllContacts}
          setContacts={setGetAllContacts}
        />
      )}
    </>
  );
};

// Remove the duplicate declaration
