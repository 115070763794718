import { useEffect, useRef, useState } from 'react';
import { ShowcaseBundleWindow } from '~/../public/matterport/sdk';
import './MatterportViewer.scss';

const { REACT_APP_MATTERPORT_KEY } = process.env;

export declare interface MatterportViewerProps {
  modelId: string;
  mpDh: number;
  mpLang: string;
  mpSearch: number;
  tags?: {
    uuid: any;
    position: {
      steam_z: any;
      steam_y: any;
      steam_x: any;
      x: number;
      y: number;
      z: number;
    };
  }[];
  mpSdk;
  setMpSdk;
  iDownHandler?: any;
  iStopLongClickHandler?: any;
}

export const MatterportViewer2 = ({
  modelId,
  mpDh,
  mpLang,
  mpSearch,
  setMpSdk,
  mpSdk,
  iDownHandler = null,
  iStopLongClickHandler = null
}: MatterportViewerProps) => {
  const matterPortFrame = useRef<HTMLIFrameElement>(null);

  const [mpSdkLoaded, setMpSdkLoaded] = useState(false);

  type MatterportParams = {
    modelId: string; // Identifiant du modèle Matterport
    applicationKey: string; // Clé d'application pour l'authentification
    help?: 0 | 1 | 2; // Affichage de l'aide au lancement
    nt?: 0 | 1; // Comportement d'ouverture sur mobile
    play?: 0 | 1; // Lecture automatique du modèle
    qs?: 0 | 1; // Activation du Quickstart
    brand?: 0 | 1; // Affichage des détails de la marque
    dh?: 0 | 1; // Affichage de la vue Dollhouse
    tour?: 0 | 1 | 2 | 3; // Mode de visite guidée
    gt?: 0 | 1; // Affichage des boutons de visite guidée
    views?: 0 | 1; // Affichage des vues enregistrées
    hr?: 0 | 1; // Affichage du Highlight Reel
    mls?: 0 | 1 | 2; // Mode MLS (cacher les informations de marque)
    mt?: 0 | 1; // Affichage des Mattertags
    tagNav?: 0 | 1; // Navigation avec les tags
    pin?: 0 | 1; // Affichage des pins de vues 360°
    portal?: 0 | 1; // Affichage des portails de connexion 360°
    f?: 0 | 1; // Activation du changement d'étage
    fp?: 0 | 1; // Affichage du plan d'étage
    lang?: string; // Langue de l'interface utilisateur
    nozoom?: 0 | 1; // Désactivation du zoom
    search?: 0 | 1; // Affichage de l'icône de recherche
    wh?: 0 | 1; // Activation de la molette de la souris
    kb?: 0 | 1; // Activation du panoramique en mode visite guidée
    lp?: 0 | 1; // Boucle de la visite guidée
    st?: number; // Temps d'attente à chaque point de visite en ms
    title?: 0 | 1; // Affichage du titre de l'espace
    tourcta?: 0 | 1 | 2; // Affichage de l'appel à l'action en fin de visite
    wts?: number; // Vitesse de transition entre les points de visite
    ts?: number; // Temps avant le démarrage automatique de la visite guidée
    hl?: 0 | 1 | 2; // Mode d'affichage du Highlight Reel au lancement
    vr?: 0 | 1; // Activation du bouton VR
  };

  const generateMatterportUrl = (params: MatterportParams): string => {
    const baseUrl = '/matterport/showcase.html';
    const queryParams = new URLSearchParams({
      m: params.modelId,
      applicationKey: params.applicationKey,
      ...Object.fromEntries(
        Object.entries(params)
          .filter(([key, value]) => value !== undefined && key !== 'modelId' && key !== 'applicationKey')
          .map(([key, value]) => [key, String(value)])
      )
    });
    return `${baseUrl}?${queryParams.toString()}`;
  };

  // const frameUri = `/matterport/showcase.html?m=${modelId}&applicationKey=${REACT_APP_MATTERPORT_KEY}&help=0&play=1&qs=0&gt=0&hr=0&dh=${mpDh}&lang=${mpLang}&search=${mpSearch}&mls=2&f=1&wh=1&tagNav=0&brand=0`;
  const frameUri = generateMatterportUrl({
    modelId: modelId,
    applicationKey: REACT_APP_MATTERPORT_KEY,
    help: 0,
    play: 1,
    qs: 1,
    gt: 0,
    hr: 0,
    dh: mpDh as 0 | 1,
    lang: mpLang,
    search: mpSearch as 0 | 1,
    mls: 2,
    f: 1,
    wh: 1,
    tagNav: 0,
    brand: 0
  });

  useEffect(() => {
    if (matterPortFrame.current && mpSdk) {
      // const frameCtx = matterPortFrame.current.contentWindow as ShowcaseBundleWindow;
      // const sdk = mpSdk;
      const gl = matterPortFrame.current.contentWindow['THREE'];
    }
  }, [mpSdk]);

  useEffect(() => {
    if (!matterPortFrame.current) {
      return;
    }
    matterPortFrame.current.addEventListener('load', async () => {
      try {
        const style = document.createElement('style');
        style.textContent = `
        .tag-billboard-contents {
          padding: 5px !important;
          min-width: 100px !important;
          min-height: 0px !important;
          pointer-events: none !important;
        };
        .tag-billboard-title {
          padding: 5px !important;
          min-width: 100px !important;
          min-height: 10px !important;
          pointer-events: none !important;
        };
        .annotation-preview-contents {
          min-width: 100px !important;
          min-height: 0px !important;
          pointer-events: none !important;
        }
        
        .annotation-preview {
          min-width: 100px !important;
          min-height: 10px !important;
          pointer-events: none !important;
        }
        .annotation-preview .annotation-preview-contents {
          min-width: 100px !important;
          min-height: 10px !important;
          pointer-events: none !important;
        }
        .annotation-preview .annotation-preview-right .tag-preview {
          pointer-events: none !important;
          max-width: none !important;
        }

        .tag-preview {
          pointer-events: none !important;
          max-width: none !important;
        }
        .text-box-text {
          min-width: 100px !important;
          min-height: 0px !important;
          pointer-events: none !important;  
        }
        .annotation-read-more {
          display: none !important;
        }
        .tag-billboard-description {

        }
        .annotation-read-more {
          display: none !important;
        }
        .tag-billboard-contents>div:not(:last-child){
          margin-bottom: 0px !important;
        }

        // Hide the child container
        // .child-container{
        //   display: none !important;
        // }
        // .top-ui {
        //   display: none !important;
        // }
    `;

        const iframeDocument =
          matterPortFrame.current.contentDocument || matterPortFrame.current.contentWindow.document;
        iframeDocument.head.appendChild(style);
        const frameCtx = matterPortFrame.current.contentWindow as ShowcaseBundleWindow;
        const sdk = await frameCtx.MP_SDK.connect(matterPortFrame.current.contentWindow as ShowcaseBundleWindow);

        setMpSdk(sdk);
      } catch (e) {}
    });
    matterPortFrame.current.src = frameUri;
  }, [matterPortFrame, frameUri]);

  useEffect(() => {
    if (mpSdk) {
      const bodyIframe = matterPortFrame.current.contentWindow.window.document.body;

      bodyIframe.style.borderRadius = '20px';

      bodyIframe.removeEventListener('mousedown', iDownHandler);
      bodyIframe.removeEventListener('mousemove', iStopLongClickHandler);
      bodyIframe.removeEventListener('mouseup', iStopLongClickHandler);
      bodyIframe.removeEventListener('touchstart', iDownHandler);
      bodyIframe.removeEventListener('touchmove', iStopLongClickHandler);
      bodyIframe.removeEventListener('touchend', iStopLongClickHandler);

      bodyIframe.addEventListener('mousedown', iDownHandler);
      bodyIframe.addEventListener('mousemove', iStopLongClickHandler);
      bodyIframe.addEventListener('mouseup', iStopLongClickHandler);
      bodyIframe.addEventListener('touchstart', iDownHandler);
      bodyIframe.addEventListener('touchmove', iStopLongClickHandler);
      bodyIframe.addEventListener('touchend', iStopLongClickHandler);

      return () => {
        bodyIframe.removeEventListener('mousedown', iDownHandler);
        bodyIframe.removeEventListener('mousemove', iStopLongClickHandler);
        bodyIframe.removeEventListener('mouseup', iStopLongClickHandler);
        bodyIframe.removeEventListener('touchstart', iDownHandler);
        bodyIframe.removeEventListener('touchmove', iStopLongClickHandler);
        bodyIframe.removeEventListener('touchend', iStopLongClickHandler);
      };
    }
  }, [mpSdk]);

  // Création des tags sur le Matterport

  // RENDER
  if (!REACT_APP_MATTERPORT_KEY) {
    return (
      <>
        <h2>Could not load frame</h2>
      </>
    );
  }

  return (
    <>
      <div className="matterportViewer__container">
        <iframe
          id="matterport-frame"
          allowFullScreen
          allow="xr-spatial-tracking"
          title="matterport-frame"
          ref={matterPortFrame}
          style={{ width: '100%', border: 0 }}
        ></iframe>
      </div>
    </>
  );
};

export default MatterportViewer2;
