import { Grid2, Typography } from '@mui/material';
import { toolLogo } from '~/utils/references';
import { appTheme } from '~/utils/theme';
import { getTagForEquipment, getTagForEvent, getTagPinPoint } from './utilsView3D';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export const View3dContentTitle = ({
  entityHasTag,
  navigateToTag,
  mainToolsNames,
  activeMainToolId,
  setUuidTagSelected,
  equipmentSelected,
  setEquipmentSelected,
  eventSelected,
  setEventSelected,
  cancelTagCreation,
  cancelTagPositionChange,
  createEntityMode,
  setCreateEntityMode,
  hasNoMatterportScan
}) => {
  const [titleIsOnEntityList, setTitleIsOnEntityList] = useState(false);

  useEffect(() => {
    if (
      activeMainToolId &&
      ((!equipmentSelected && activeMainToolId === 3) || (!eventSelected && activeMainToolId === 2))
    ) {
      setTitleIsOnEntityList(true);
    } else {
      setTitleIsOnEntityList(false);
    }
  }, [activeMainToolId, equipmentSelected, eventSelected, entityHasTag]);
  return (
    <Grid2 container spacing={0} sx={{ height: '100%', alignItems: 'baseline', justifyContent: 'center' }}>
      <Grid2 sx={{ minHeight: '24px', minWidth: '24px', textAlign: 'center' }}>
        {((activeMainToolId === 3 && equipmentSelected) ||
          (activeMainToolId === 2 && eventSelected) ||
          createEntityMode) &&
          !hasNoMatterportScan && (
            <FormatListBulletedIcon
              sx={{ cursor: 'pointer', opacity: 1 }}
              onClick={() => {
                if (createEntityMode === false) {
                  cancelTagCreation();
                  cancelTagPositionChange();
                  if (activeMainToolId === 3) {
                    setEquipmentSelected(null);
                    setUuidTagSelected(null);
                  } else if (activeMainToolId === 2) {
                    setEventSelected(null);
                    setUuidTagSelected(null);
                  }
                } else {
                  setCreateEntityMode(false);
                }
              }}
            />
          )}
      </Grid2>
      <Grid2 size={'grow'} sx={{ textAlign: 'center' }}>
        <Typography variant="h4" sx={{ color: appTheme.palette.primary.main }}>
          <span style={{ top: '6px', position: 'relative', right: '4px' }}>
            {toolLogo(activeMainToolId, 30, appTheme.palette.primary.main)}
          </span>
          <span>{mainToolsNames[activeMainToolId]}</span>
        </Typography>
      </Grid2>
      <Grid2 sx={{ minHeight: '24px', minWidth: '24px', textAlign: 'center' }}>
        {(activeMainToolId === 3 || activeMainToolId === 2) && (
          <div
            style={{ cursor: 'pointer', fontSize: '30px' }}
            onClick={() => {
              if (activeMainToolId === 3) {
                navigateToTag(getTagForEquipment(equipmentSelected).uuid);
              } else if (activeMainToolId === 2) {
                navigateToTag(getTagForEvent(eventSelected).uuid);
              }
            }}
          >
            {entityHasTag && getTagPinPoint(equipmentSelected)}
            {entityHasTag && getTagPinPoint(eventSelected)}
          </div>
        )}
        {titleIsOnEntityList && !createEntityMode && (
          <>
            <AddCircleIcon
              onClick={() => {
                setCreateEntityMode(true);
              }}
              sx={{ color: 'rgb(241, 118, 51)', cursor: 'pointer', fontSize: '30px' }}
            ></AddCircleIcon>
          </>
        )}
      </Grid2>
    </Grid2>
  );
};
