import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, Typography, useMediaQuery, useTheme, IconButton, Dialog } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { api } from '~/api';
import 'dayjs/locale/fr';
import normalizedString from '~/utils/normalizedString';
import { WizzardMissionOrderEdl } from '~/app/components/WizzardMissionOrder/WizzardMissionOrderEdl';
import { WizzardMissionOrderScan } from '~/app/components/WizzardMissionOrder/WizzardMissionOrderScan';
import { WizzardMissionOrderPlan } from '~/app/components/WizzardMissionOrder/WizzardMissionOrderPlan';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export const WizzardMissionOrder = ({ activeStep, setActiveStep, product, closeModal, scanOrder }) => {
  const [inputValue, setInputValue] = useState('');
  const [productList, setProductList] = useState(null);
  const [productFilter, setProductFilter] = useState(null);
  const [productSelected, setProductSelected] = useState(product || null);

  const [step, setStep] = useState(0);

  const [isEdl, setIsEdl] = useState(false);
  const [isScan3D, setIsScan3D] = useState(false);
  const [isPlan, setIsPlan] = useState(false);

  const [allContactsOrga, setAllContactsOrga] = useState([]);

  const [isFurnished, setIsFurnished] = useState(null);

  const [buildingCode, setBuildingCode] = useState(null);

  const [disabled, setDisabled] = useState(true);

  const [isBuilding, setIsBuilding] = useState(false);

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  const [openHelperTextScan, setOpenHelperTextScan] = useState(false);
  const [openHelperTextPlan, setOpenHelperTextPlan] = useState(false);

  useEffect(() => {
    fetchProductAndBuilding();
  }, []);

  const fetchProductAndBuilding = async () => {
    const list = [];
    await api.product.search().then((res) => {
      list.push(...res);
    });
    // await api.building.search().then((res) => {
    //   list.push(...res);
    // });
    setProductList(list);
  };

  useEffect(() => {
    if (scanOrder) {
      setIsScan3D(scanOrder);
    }
  }, []);

  const handleOpenHelperTextScan = () => {
    setOpenHelperTextScan(!openHelperTextScan);
  };

  const handleOpenHelperTextPlan = () => {
    setOpenHelperTextPlan(!openHelperTextPlan);
  };

  useEffect(() => {
    if (!productList) return;
    const filteredList = productList.filter((item) => {
      const normalizedSearch = normalizedString(inputValue);

      return (
        item.address &&
        (normalizedString(item.address.street).indexOf(normalizedSearch) >= 0 ||
          normalizedString(item.address.city).indexOf(normalizedSearch) >= 0 ||
          normalizedString(item.address.zip_code).indexOf(normalizedSearch) >= 0 ||
          (item.owner && normalizedString(item.owner.name).indexOf(normalizedSearch) >= 0) ||
          (item.owner && normalizedString(item.owner.company_name).indexOf(normalizedSearch) >= 0) ||
          (item.name && normalizedString(item.name).indexOf(normalizedSearch) >= 0) ||
          (item.id_internal && normalizedString(item.id_internal).indexOf(normalizedSearch) >= 0))
      );
    });
    setProductFilter(filteredList);
  }, [inputValue]);

  useEffect(() => {
    if (!product) return;
    handleNext();
  }, []);

  useEffect(() => {
    handleDisabled();
  }, [isEdl, isScan3D, isPlan]);

  const handleDisabled = () => {
    if (isEdl) {
      setDisabled(false);
    } else if (isScan3D) {
      setDisabled(false);
    } else if (isPlan) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleProductSelected = (product) => {
    setProductSelected(product);
  };

  const handleNext = () => {
    setStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNextActiveStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackActiveStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleService = (e, serviceKind) => {
    if (serviceKind === 'edl') {
      setIsEdl(true);
      setIsScan3D(false);
      setIsPlan(false);
    } else if (serviceKind === 'scan') {
      setIsScan3D(true);
      setIsEdl(false);
      setIsPlan(false);
    } else if (serviceKind === 'plan') {
      setIsPlan(true);
      setIsEdl(false);
      setIsScan3D(false);
    }
  };

  useEffect(() => {
    if (productSelected) {
      api.contacts.search().then((res) => {
        setAllContactsOrga(res);
      });

      if (!isBuilding) {
        api.product.get(productSelected.uuid).then((res) => {
          setIsFurnished(res?.data?.furnished);
          setBuildingCode(res?.building?.data?.access_code);
        });
      } else {
        api.building.get(productSelected.uuid).then((res) => {
          setBuildingCode(res?.data?.access_code);
        });
      }
    }
  }, [productSelected]);

  return (
    <Grid container spacing={2}>
      {activeStep === 0 && (
        <>
          {step === 0 && !productSelected && (
            <>
              <Grid item xs={12} md={8}>
                <TextField
                  autoComplete="off"
                  label={'Recherche du bien concerné'}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={inputValue}
                  onChange={handleInputChange}
                  helperText="Entrer 3 caractères minimum"
                  InputProps={{
                    endAdornment: (
                      <SearchOutlinedIcon
                        sx={{
                          color: 'primary.main',
                          ml: 2,
                          mr: 1
                        }}
                        fontSize="medium"
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {inputValue.length > 2 &&
                  productFilter?.map((item, index) => (
                    <Grid item xs={12} key={index} sx={{ mb: '2vh' }}>
                      <Button
                        key={index}
                        variant="outlined"
                        color="primary"
                        sx={{ borderColor: 'lightgrey', borderRadius: '10px' }}
                        onClick={() => {
                          handleNext();
                          handleProductSelected(item);
                          if (item.uuid.substring(0, 3) === 'bui') setIsBuilding(true);
                        }}
                      >
                        <Typography variant="h5" sx={{ color: 'primary.main' }}>
                          {item.uuid.substring(0, 3) === 'bui' ? 'Bâtiment, ' : ''}
                          {item.address.street}, {item.address.zip_code} {item.address.city} {item.address_complement}
                        </Typography>
                      </Button>
                    </Grid>
                  ))}
              </Grid>
            </>
          )}
          {step === 1 && (
            <>
              <Grid item xs={12} sx={{ position: 'absolute', top: '6vh' }}>
                <Typography variant="h4" sx={{ color: 'primary.main', mb: '1.5vh' }}>
                  {productSelected.address.street}, {productSelected.address.zip_code} {productSelected.address.city}{' '}
                  {productSelected.address_complement}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', mb: '1.5vh' }}>
                  Choix de service :
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', mt: '1.5vh' }}>
                  {!isBuilding && (
                    <Grid item xs={12}>
                      <Button
                        variant={isEdl ? 'contained' : 'outlined'}
                        color={isEdl ? 'secondary' : 'primary'}
                        sx={{ mb: '3vh', borderRadius: '10px', borderColor: 'lightgrey' }}
                        onClick={(e) => handleService(e, 'edl')}
                      >
                        <Typography variant="h6" sx={{ color: isEdl ? 'white' : 'primary.main', fontWeight: 'bold' }}>
                          Etat des lieux
                        </Typography>
                      </Button>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Button
                      variant={isScan3D ? 'contained' : 'outlined'}
                      color={isScan3D ? 'secondary' : 'primary'}
                      sx={{ mb: '3vh', borderRadius: '10px', borderColor: 'lightgrey' }}
                      onClick={(e) => {
                        handleService(e, 'scan');
                      }}
                    >
                      <Typography variant="h6" sx={{ color: isScan3D ? 'white' : 'primary.main', fontWeight: 'bold' }}>
                        Modélisation 3D
                      </Typography>
                    </Button>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => {
                        handleOpenHelperTextScan();
                      }}
                      sx={{ mb: '3vh', ml: '3vw' }}
                    >
                      <HelpOutlineIcon fontSize="large" />
                    </IconButton>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Button
                      variant={isPlan ? 'contained' : 'outlined'}
                      color={isPlan ? 'secondary' : 'primary'}
                      sx={{ mb: '3vh', borderRadius: '10px', borderColor: 'lightgrey' }}
                      onClick={(e) => handleService(e, 'plan')}
                    >
                      <Typography variant="h6" sx={{ color: isPlan ? 'white' : 'primary.main', fontWeight: 'bold' }}>
                        Plan schématique de niveau
                      </Typography>
                    </Button>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={handleOpenHelperTextPlan}
                      sx={{ mb: '3vh', ml: '3vw' }}
                    >
                      <HelpOutlineIcon fontSize="large" />
                    </IconButton>
                  </Grid> */}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ my: 2 }}>
                  <Button
                    onClick={() => {
                      if (product) {
                        setProductSelected(null);
                        closeModal();
                      } else {
                        setProductSelected(null);
                        setStep(0);
                      }
                    }}
                    sx={{ marginRight: 2 }}
                  >
                    Retour
                  </Button>
                  <Button variant="contained" disabled={disabled} onClick={handleNext}>
                    Suivant
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </>
      )}

      {step === 2 && (
        <>
          <Grid item xs={12} sx={{ position: 'absolute', top: '6vh' }}>
            <Typography variant="h4" sx={{ color: 'primary.main', mb: '1.5vh' }}>
              {productSelected.address.street}, {productSelected.address.zip_code} {productSelected.address.city}{' '}
              {productSelected.address_complement}
            </Typography>
          </Grid>
          {isEdl && !isBuilding && (
            <WizzardMissionOrderEdl
              nextActiveStepViewer={handleNextActiveStep}
              previousActiveStepViewer={handleBackActiveStep}
              productSelected={productSelected}
              previousStep={handleBack}
              closeModal={closeModal}
              isFurnished={isFurnished}
              buildingCode={buildingCode}
              allContactsOrga={allContactsOrga}
            />
          )}
          {isScan3D && (
            <WizzardMissionOrderScan
              productSelected={productSelected}
              nextActiveStepViewer={handleNextActiveStep}
              previousActiveStepViewer={handleBackActiveStep}
              previousStep={handleBack}
              isFurnished={isFurnished}
              buildingCode={buildingCode}
              allContactsOrga={allContactsOrga}
              closeModal={closeModal}
              isBuilding={isBuilding}
            />
          )}
          {/* {isPlan && <WizzardMissionOrderPlan />} */}
        </>
      )}
      <Dialog open={openHelperTextScan} onClose={handleOpenHelperTextScan}>
        <Box sx={{ p: 4 }}>
          <Typography variant="h4" textAlign={'center'} sx={{ color: 'primary.main' }}>
            Pour un usage optimal, comme la commercialisation, nous vous recommandons de vous assurer que le bien est
            propre et bien rangé.
          </Typography>
        </Box>
      </Dialog>
      <Dialog open={openHelperTextPlan} onClose={handleOpenHelperTextPlan}>
        <Box sx={{ p: 4 }}>
          <Typography variant="h6" textAlign={'center'} sx={{ color: 'primary.main', mb: '1vh' }}>
            Des plans d'étage prêts à l'emploi pour la commercialisation de vos bien.
          </Typography>
          <Typography variant="h6" textAlign={'center'} sx={{ color: 'primary.main', mb: '2vh' }}>
            Commandez des plans de niveau qui exploitent les données spatiales capturées lors de la réalisation du scan
            3D pour générer une présentation schématique en 2D de votre bien.
          </Typography>
          <Typography variant="h6" textAlign={'center'} sx={{ color: 'primary.main', mb: '1vh' }}>
            Informations :
          </Typography>
          <Typography variant="h6" textAlign={'center'} sx={{ color: 'primary.main', mb: '2vh' }}>
            Les plans schématiques de niveau sont un atout essentiel du marketing immobilier qui s’ajoute à la visite
            virtuelle et aux photos, pour susciter l'intérêt de vos clients. Lorsque vous commandez un plan de niveau,
            nous faisons le gros du travail pour vous envoyer un fichier, prêt à l'emploi qui vous évitera de devoir
            mesurer toute la propriété à la main. Il suffit juste qu’elle soit déjà numérisée.
          </Typography>
          <Typography variant="h6" textAlign={'center'} sx={{ color: 'primary.main', mb: '1vh' }}>
            Les plans d'étage sont prêts à être présentés dès leur livraison et peuvent être personnalisés par
            l'utilisateur. Les fichiers vectoriels (SVG) inclus vous permettent de modifier rapidement les
            caractéristiques du plan d'étage tout en conservant le niveau de détails.
          </Typography>
          <Typography variant="h6" textAlign={'center'} sx={{ color: 'primary.main', mb: '2vh' }}>
            Il ne s’agit en aucun cas d’un plan d’execution pour travaux.
          </Typography>
          <Typography variant="h6" textAlign={'center'} sx={{ color: 'primary.main' }}>
            Tarifs : à partir de 40 €HT par niveau
          </Typography>
        </Box>
      </Dialog>
    </Grid>
  );
};
