import { Grid2 } from '@mui/material';
import dayjs from 'dayjs';

export const DocumentItem = ({ document, setWizzardDocumentOpen, setSelectedDocument, isLast = null }) => {
  const getDocumentName = (document) => {
    const nameItems = [];

    if (document.category && document.category !== '') {
      nameItems.push(document.category);
    }

    if (document.name && document.name !== '') {
      nameItems.push(document.name);
    }

    return nameItems.join(' - ');
  };

  return (
    <div>
      <Grid2
        container
        onClick={() => {
          setSelectedDocument(document);
          setWizzardDocumentOpen(true);
        }}
        style={{ cursor: 'pointer' }}
      >
        {document.created_at && document.created_at !== '' && (
          <Grid2 container>
            <Grid2 size={12} sx={{ pb: '10px' }}>
              <span style={{ color: '#929292' }}>
                {dayjs(document.created_at).format('DD/MM/YYYY') + ' - ' + dayjs(document.created_at).format('HH:mm')}{' '}
              </span>{' '}
              <br />
              <span>{getDocumentName(document)}</span>{' '}
            </Grid2>
          </Grid2>
        )}
      </Grid2>
      {!isLast && <div style={{ borderTop: '1px solid #EEEEEE', paddingBottom: '10px' }} />}
    </div>
  );
};

export default DocumentItem;
