import { Grid2, TextField, Checkbox, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export const DegradationKind = (props: any) => {
  const { degredation, onChange, data } = props;

  const [number, setNumber] = useState(0);

  const [check, setCheck] = useState(false);

  const [inData, setInData] = useState(false);

  const [dataNumber, setDataNumber] = useState(0);

  useEffect(() => {
    if (data) {
      data.map((data: any) => {
        if (data.id === degredation.id) {
          setInData(true);
          setNumber(data.number);
          setDataNumber(data.number);
          setCheck(true);
        }
      });
    }
  }, [check, inData]);
  useEffect(() => {
    setTimeout(() => {
      if (check && number === 0) setNumber(1);
    }, 1);
  }, [check, number]);

  useEffect(() => {
    if (check && !inData) {
      const addDegradation = {
        number: number,
        name: degredation.name,
        id: degredation.id
      };

      onChange(addDegradation);
    }
    if (number !== dataNumber && inData) {
      const addDegradation = {
        number: number,
        name: degredation.name,
        id: degredation.id
      };
      onChange(addDegradation);
    }
    if (!check && inData) {
      const addDegradation = {
        number: 0,
        name: degredation.name,
        id: degredation.id
      };
      onChange(addDegradation);
      setCheck(false);
      setNumber(0);
    }
  }, [number, check]);

  return (
    <Grid2 size={props.isLandscape ? 12 : 6}>
      <Grid2 container spacing={2}>
        <Grid2 size={'auto'}>
          <TextField
            size="small"
            sx={{ width: '50px' }}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              style: { textAlign: 'center' }
            }}
            value={number > 0 ? number : ''}
            type="number"
            onChange={(e) => {
              setNumber(parseInt(e.target.value));
              if (parseInt(e.target.value) > 0) setCheck(true);
              if (parseInt(e.target.value) === 0 || e.target.value === '') setCheck(false);
            }}
          />
        </Grid2>
        <Grid2 size={'auto'}>
          <Checkbox
            checked={check}
            onChange={() => {
              setCheck(!check);
            }}
          />
        </Grid2>
        <Grid2 size={'auto'} alignContent={'center'}>
          <Typography variant="h6">{degredation.name}</Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
