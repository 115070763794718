import { Box, Button, CircularProgress, Grid2 } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState, useLayoutEffect } from 'react';
import MatterportViewer2 from '~/app/components/MatterportViewer/MatterportViewer2';
import { ProductTitle } from '~/app/components/ProductTitle/ProductTitle';
import { appTheme } from '~/utils/theme';
import ToolIcon from './ToolIcon';
import { referentielEventTypes, toolLogo } from '~/utils/references';
import ViewerContentContainer from './ViewerContentContainer';
import './View3d.scss';
import { AppContext } from '~/utils/context/AppContext';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import {
  getEquipmentByTagUuid,
  getEventByTagUuid,
  getTagForEquipment,
  getTagForEvent,
  getTagsForEquipmentsList
} from './utilsView3D';
import _ from 'lodash';
import { api } from '~/api';
import gsap from 'gsap';
import { PlaceTagIndicator } from './PlaceTagIndicator';
import { View3dContentTitle } from './View3dContentTitle';

interface IProps {
  mode?:
    | 'viewer'
    | 'event-viewer'
    | 'pin'
    | 'administrate'
    | 'equipment-location'
    | 'event-location'
    | 'equipment-creation'
    | 'event-creation';
  productDatas?: any;
  setData?: any;
  tags?: any;
  tagIndexSelected?: number;
  mattertagsData?: any;
  equipmentTab?: any;
  refreshProductData?: any;
  preSelectedEquipment?: any;
  preSelectedEvent?: any;
  tagsEvents?: any;
  events?: any;
  setModalOpen?: any;
  appRouterNavigateEvent?: any;
  appRouterNavigateEquipment?: any;
}

export const View3d = ({
  productDatas,
  mode,
  // tags,
  refreshProductData = null,
  preSelectedEquipment = null,
  preSelectedEvent = null,
  // tagsEvents = null,
  events = null,
  setModalOpen = null,
  appRouterNavigateEvent = null,
  appRouterNavigateEquipment = null
}: IProps) => {
  const { equipmentReferencial } = useContext(AppContext);
  const mediaQueries = useContext(MediaQueryContext);

  //
  const [activeMainToolId, setActiveMainToolId] = useState<number | null>(null);
  const [isLandscape, setIsLandscape] = useState<boolean>(window.innerWidth > window.innerHeight);
  const [paddingContent, setPaddingContent] = useState<number>(10);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [contentWidth, setContentWidth] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  const [eventTags, setEventTags] = useState<any[]>([]);
  const [equipmentTags, setEquipmentTags] = useState<any[]>([]);
  const [mpSdk, setMpSdk] = useState<any | null>(null);
  const [startDrag, setStartDrag] = useState(false);
  const [separatorHeight, setSeparatorHeight] = useState(0);
  const [equipmentsList, setEquipmentsList] = useState<any[]>([]);
  const [eventsList, setEventsList] = useState<any[]>([]);
  const [entityHasTag, setEntityHasTag] = useState(false);
  const [firstLaunch, setFirstLaunch] = useState(true);
  const [createEntityMode, setCreateEntityMode] = useState(false);
  const [hasNoMatterportScan, setHasNoMatterportScan] = useState(false);
  const [linkedEntityUuid, setLinkedEntityUuid] = useState(null);
  const [waitingForApiResponse, setWaitingForApiResponse] = useState(false);
  const [tags, setTags] = useState<any[]>([]);
  const [tagsEvents, setTagsEvents] = useState<any[]>([]);
  const [newEntityCreated, setNewEntityCreated] = useState(false);

  //
  // REFS & States handlers
  //
  const [newTagPlaced, _setNewTagPlaced] = useState(false);
  const newTagPlacedRef = useRef(newTagPlaced);
  const setNewTagPlaced = (value: boolean) => {
    newTagPlacedRef.current = value;
    _setNewTagPlaced(value);
  };
  //
  const [finalTagUuid, _setFinalTagUuid] = useState<string | null>(null);
  const finalTagUuidRef = useRef(finalTagUuid);
  const setFinalTagUuid = (value: string) => {
    finalTagUuidRef.current = value;
    _setFinalTagUuid(value);
  };

  //
  const [finalTag, _setFinalTag] = useState<any | null>(null);
  const finalTagRef = useRef(finalTag);
  const setFinalTag = (value: any) => {
    finalTagRef.current = value;
    _setFinalTag(value);
  };

  //
  const [cameraMoving, _setCameraMoving] = useState(false);
  const cameraMovingRef = useRef(cameraMoving);
  const setCameraMoving = (value: boolean) => {
    cameraMovingRef.current = value;
    _setCameraMoving(value);
  };

  //
  const [newTagId, _setNewTagId] = useState(null);
  const newTagIdRef = useRef(newTagId);
  const setNewTagId = (value: string) => {
    newTagIdRef.current = value;
    _setNewTagId(value);
  };

  //
  const [newTagData, _setNewTagData] = useState(null);
  const newTagDataRef = useRef(newTagData);
  const setNewTagData = (value: any) => {
    newTagDataRef.current = value;
    _setNewTagData(value);
  };

  //
  const [fullTagsCollection, _setFullTagsCollection] = useState<any[]>([]);
  const fullTagsCollectionRef = useRef<any>(null);
  const setFullTagsCollection = (value: any) => {
    fullTagsCollectionRef.current = value;
    _setFullTagsCollection(value);
  };

  //
  const [appStateReady, _setAppStateReady] = useState(false);
  const appStateReadyRef = useRef(appStateReady);
  const setAppStateReady = (value: boolean) => {
    appStateReadyRef.current = value;
    _setAppStateReady(value);
  };

  //
  const [uuidTagSelected, _setUuidTagSelected] = useState<string | null>(null);
  const uuidTagSelectedRef = useRef(uuidTagSelected);
  const setUuidTagSelected = (tag, forceNavigateToTag = false) => {
    if (tag === null && uuidTagSelectedRef.current !== null) {
      mpSdk?.Tag?.close(uuidTagSelectedRef.current)
        .then(() => {})
        .catch((error) => {});
    }
    if (uuidTagSelectedRef.current !== tag) {
      uuidTagSelectedRef.current = tag;
      _setUuidTagSelected(tag);
      if (forceNavigateToTag) {
        navigateToTag(tag);
      }
    }
  };

  //
  const [equipmentSelected, _setEquipmentSelected] = useState<any | null>(null);
  const equipmentSelectedRef = useRef(equipmentSelected);
  const setEquipmentSelected = (equipment) => {
    if (
      equipment === null &&
      uuidTagSelected !== null &&
      equipmentSelected &&
      equipmentSelected?.uuid !== equipment?.uuid
    ) {
      setUuidTagSelected(null);
    }
    _setEquipmentSelected(equipment);
    equipmentSelectedRef.current = equipment;
  };

  //
  const [eventSelected, _setEventSelected] = useState(null);
  const eventSelectedRef = useRef(eventSelected);
  const setEventSelected = (event) => {
    if (event === null && uuidTagSelected !== null && eventSelected && eventSelected?.uuid !== event?.uuid) {
      setUuidTagSelected(null);
    }
    _setEventSelected(event);
    eventSelectedRef.current = event;
  };

  //
  const [changeTagPositionMode, _setChangeTagPositionMode] = useState(false);
  const changeTagPositionModeRef = useRef(changeTagPositionMode);
  const setChangeTagPositionMode = (value: boolean) => {
    changeTagPositionModeRef.current = value;
    _setChangeTagPositionMode(value);
  };

  //
  const [createTagMode, _setCreateTagMode] = useState(false);
  const createTagModeRef = useRef(createTagMode);
  const setCreateTagMode = (value: boolean) => {
    createTagModeRef.current = value;
    _setCreateTagMode(value);
  };

  //
  const [pointerDatas, _setPointerDatas] = useState<any>();
  const pointerDatasRef = useRef(pointerDatas);
  const setPointerDatas = (value: boolean) => {
    pointerDatasRef.current = value;
    _setPointerDatas(value);
  };

  //
  const [placeTagIndicatorVisibility, _setPlaceTagIndicatorVisibility] = useState('hidden');
  const placeTagIndicatorVisibilityRef = useRef(placeTagIndicatorVisibility);
  const setPlaceTagIndicatorVisibility = (value: string) => {
    placeTagIndicatorVisibilityRef.current = value;
    _setPlaceTagIndicatorVisibility(value);
  };

  const contentLandscapeRef = useRef(null);
  const contentPortraitRef = useRef(null);
  const titleLandscapeRef = useRef(null);
  const titlePortraitRef = useRef(null);
  const ProductTitleRef = useRef(null);
  const intervalRef = useRef(null);

  const placeTagIndicator = useRef(null);
  const circleIndicatorBackGround = useRef(null);

  const startTaggingCoords = useRef({ x: 0, y: 0 });

  const matterportFrameContainer = useRef(null);

  const delayPinTag = 800;
  const delayBeforePlaceTagIndicator = 300;
  const taggingTolerance = 20;
  const stemHeightScale = 0.15;

  const [pCentPlaceTag, setPCentPlaceTag] = useState(0);

  useEffect(() => {
    if (pointerDatas && (changeTagPositionMode === true || createTagMode === true)) {
      const idTag = changeTagPositionMode ? uuidTagSelected : newTagId;

      mpSdk.Tag.editPosition(idTag, {
        anchorPosition: {
          x: pointerDatas.position.x,
          y: pointerDatas.position.y,
          z: pointerDatas.position.z
        },
        stemVector: {
          x: pointerDatas.normal.x,
          y: pointerDatas.normal.y,
          z: pointerDatas.normal.z
        }
      }).catch((error) => {});
    }
  }, [pointerDatas]);

  useEffect(() => {
    if (
      preSelectedEquipment &&
      mpSdk &&
      appStateReadyRef.current === true &&
      fullTagsCollectionRef.current &&
      mode === 'equipment-location'
    ) {
      if (getTagForEquipment(preSelectedEquipment)) {
        navigateToTag(getTagForEquipment(preSelectedEquipment).uuid);
      }
    }

    if (
      preSelectedEvent &&
      mpSdk &&
      appStateReadyRef.current === true &&
      fullTagsCollectionRef.current &&
      mode === 'event-location'
    ) {
      if (getTagForEvent(preSelectedEvent)) {
        navigateToTag(getTagForEvent(preSelectedEvent).uuid);
      }
    }
  }, [mpSdk, appStateReadyRef.current, fullTagsCollectionRef.current]);

  // Met à jour uniquement si `activeMainToolId` change

  const takeScreenShot = async () => {
    if (mpSdk) {
      const resolution = { width: 1920, height: 1080 };
      const visibility = {
        mattertags: false,
        sweeps: false
      };
      const screenShotUri = await mpSdk.Renderer.takeScreenShot(resolution, visibility);

      return screenShotUri;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const isLandscape = window.innerWidth > window.innerHeight;
      setIsLandscape(isLandscape);
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    // islandscape
    if (contentLandscapeRef.current) {
      setContentHeight(
        contentLandscapeRef.current.clientHeight - contentLandscapeRef.current.clientHeight - 2 * paddingContent
      );
      setContentWidth(hasNoMatterportScan ? window.innerWidth : contentLandscapeRef.current.clientWidth);
    }
  }, [contentLandscapeRef, activeMainToolId, isLandscape, paddingContent, windowHeight, windowWidth, separatorHeight]);

  useLayoutEffect(() => {
    // is pportrait
    if (contentPortraitRef.current) {
      setContentHeight(
        !hasNoMatterportScan
          ? contentPortraitRef.current.clientHeight - titlePortraitRef.current.clientHeight - 2 * paddingContent
          : windowHeight - titlePortraitRef.current.clientHeight - 2 * paddingContent
      );
    }
  }, [contentPortraitRef, activeMainToolId, isLandscape, paddingContent, windowHeight, windowWidth, separatorHeight]);

  useEffect(() => {
    if (activeMainToolId === 2) {
      showTags(eventTags);
    } else if (activeMainToolId === 3) {
      showTags(equipmentTags);
    } else {
      hideTags(eventTags);
      hideTags(equipmentTags);
    }
    if (mode !== 'event-creation' && mode !== 'equipment-creation') {
      cancelTagCreation();
      setCreateEntityMode(false);
    }
  }, [activeMainToolId]);

  useEffect(() => {
    if (mpSdk) {
      mpSdk.App.state.subscribe(function (appState) {
        // app state has changed
        // console.log('The current application: ', appState.application);
        // console.log('The current phase: ', appState.phase);
        // console.log('Loaded at time ', appState.phaseTimes[mpSdk.App.Phase.LOADING]);
        // console.log('Started at time ', appState.phaseTimes[mpSdk.App.Phase.STARTING]);

        if (appState.phase === 'appphase.playing') {
          setAppStateReady(true);
          setTimeout(() => {
            mpSdk.Pointer.setVisible(false);
          }, 3000);
        }
      });

      mpSdk.Tag.data.subscribe({
        onAdded(index, item, collection) {
          // console.log('Tag added to the collection', index, item, collection);
          // collection[index].ownerData = { ...item.data };
        },
        onRemoved(index, item, collection) {
          // console.log('Tag removed from the collection', index, item, collection);
        },
        onUpdated(index, item, collection) {
          // console.log('Tag updated in place in the collection', index, item, collection);
        },
        onCollectionUpdated(collection) {
          // console.log('The full collection of Tags looks like', collection);
          // fullTagsCollectionRef.current = collection;
          setFullTagsCollection(collection);
        }
      });

      mpSdk.Sweep.current.subscribe(function (currentSweep) {
        // Change to the current sweep has occurred.
        if (currentSweep.sid === '') {
          // console.log('Not currently stationed at a sweep position');
          setCameraMoving(true);
        } else {
          setCameraMoving(false);
          // console.log('Currently at sweep', currentSweep.sid);
          // console.log('Current position', currentSweep.position);
          // console.log('On floor', currentSweep.floorInfo.sequence);
        }
      });

      // mpSdk.Tag.toggleNavControls(true);
      // mpSdk.Tag.toggleSharing(false);
      // mpSdk.Tag.toggleDocking(true);

      // console.log('mpSdk.pointer', mpSdk.Pointer);

      mpSdk.Scene.configure(function (renderer, three, effectComposer) {
        // console.log('renderer', renderer);
        // console.log('three', three);
        // configure PBR
        renderer.physicallyCorrectLights = false;

        // configure shadow mapping
        renderer.shadowMap.enabled = false;
        renderer.shadowMap.bias = 0.0001;
        renderer.shadowMap.type = three.PCFSoftShadowMap;

        if (effectComposer) {
          // add a custom pass here
        }
      });

      mpSdk.Pointer.intersection.subscribe(async function (intersectionData) {
        // await mpSdk.Pointer.setVisible(true);
        // Changes to the intersection data have occurred.
        // console.log('Intersection position:', intersectionData.position);
        // console.log('Intersection normal:', intersectionData.normal);
        // console.log('Intersection tag:', intersectionData.tag);
        // console.log('object', intersectionData.object);
        // console.log(intersectionData);

        if (changeTagPositionModeRef.current || createTagModeRef.current) {
          setPointerDatas({ ...intersectionData });
        }
      });

      mpSdk.Tag.openTags.subscribe({
        prevState: {
          hovered: null,
          docked: null,
          selected: null
        },
        onChanged(newState) {
          if (newState.hovered !== this.prevState.hovered) {
            if (newState.hovered) {
              // console.log(newState.hovered, 'was hovered');
            } else {
              if (
                uuidTagSelectedRef.current !== null &&
                cameraMovingRef.current === false &&
                this.prevState.hovered !== newState.hovered &&
                (equipmentSelectedRef.current !== null || eventSelectedRef.current !== null)
              ) {
                // console.log('reopen tag', uuidTagSelected);
                setTimeout(() => {
                  // console.log(equipmentSelectedRef.current);
                  // console.log(eventSelectedRef.current);
                  // console.log(getTagForEquipment);
                  if (equipmentSelectedRef.current !== null && getTagForEquipment(equipmentSelectedRef.current).uuid) {
                    // console.log(getTagForEquipment(equipmentSelectedRef.current));
                    mpSdk.Tag.open(getTagForEquipment(equipmentSelectedRef.current).uuid)
                      .then(() => {})
                      .catch((error) => {});
                  } else if (eventSelectedRef.current !== null && getTagForEvent(eventSelectedRef.current)?.uuid) {
                    // console.log(getTagForEvent(eventSelectedRef.current));
                    mpSdk.Tag.open(getTagForEvent(eventSelectedRef.current)?.uuid)
                      .then(() => {})
                      .catch((error) => {});
                  }
                }, 100);
              }
              // console.log(this.prevState.hovered, 'is no longer hovered');
            }
          }
          if (newState.docked !== this.prevState.docked) {
            if (newState.docked) {
              // console.log(newState.docked, 'was docked');
            } else {
              // console.log(this.prevState.docked, 'was undocked');
            }
          }

          // only compare the first 'selected' since only one tag is currently supported
          const [selected = null] = newState.selected; // destructure and coerce the first Set element to null
          if (selected !== this.prevState.selected) {
            if (selected) {
              // console.log(selected, 'was selected');

              setUuidTagSelected(selected);
              // cancelTagCreation();
            } else {
              // Reopen Tag
              if (
                uuidTagSelectedRef.current !== null &&
                cameraMovingRef.current === false &&
                (equipmentSelectedRef.current !== null || eventSelectedRef.current !== null) &&
                newState.hovered === null &&
                selected === null &&
                changeTagPositionModeRef.current === false
              ) {
                setTimeout(() => {
                  if (equipmentSelectedRef.current !== null && getTagForEquipment(equipmentSelectedRef.current)?.uuid) {
                    mpSdk.Tag.open(getTagForEquipment(equipmentSelectedRef.current)?.uuid).then(() => {});
                  } else if (eventSelectedRef.current !== null && getTagForEvent(eventSelectedRef.current)?.uuid) {
                    mpSdk.Tag.open(getTagForEvent(eventSelectedRef.current)?.uuid).then(() => {});
                  }
                }, 500);
              }

              // console.log(this.prevState.selected, 'was deselected');
            }
          }

          // clone and store the new state
          this.prevState = {
            ...newState,
            selected
          };
        }
      });
    }
  }, [mpSdk]);

  useEffect(() => {
    if (preSelectedEquipment && firstLaunch) {
      setActiveMainToolId(3);
      setEquipmentSelected(preSelectedEquipment);
    }
  }, [preSelectedEquipment]);

  useEffect(() => {
    if (preSelectedEvent && firstLaunch) {
      setActiveMainToolId(2);
      setEventSelected(preSelectedEvent);
    }
  }, [preSelectedEvent]);

  useEffect(() => {
    if (eventSelected && !getTagForEvent(eventSelected)) {
      setActiveMainToolId(2);
      setUuidTagSelected(null);
    } else if (eventSelected && getTagForEvent(eventSelected)) {
      setUuidTagSelected(getTagForEvent(eventSelected).uuid);
      if (mpSdk) navigateToTag(getTagForEvent(eventSelected).uuid);
    }
    scrollContentToView();
  }, [eventSelected, mpSdk]);

  useEffect(() => {
    if (equipmentSelected && !getTagForEquipment(equipmentSelected)) {
      setActiveMainToolId(3);
      setUuidTagSelected(null);
    }
    scrollContentToView();
  }, [equipmentSelected]);

  useEffect(() => {
    if (uuidTagSelected) {
      const isEquipmentTag = getEquipmentByTagUuid(equipmentsList, uuidTagSelected);
      const isEventTag = getEventByTagUuid(events, uuidTagSelected);

      if (isEventTag) {
        setEquipmentSelected(null);
        setEventSelected(isEventTag);
        setActiveMainToolId(2);
      }

      if (isEquipmentTag) {
        setEventSelected(null);
        setEquipmentSelected(isEquipmentTag);
        setActiveMainToolId(3);
      }

      scrollContentToView();
    }
  }, [uuidTagSelected]);

  useEffect(() => {
    if (productDatas && !productDatas?.matterport_scan) {
      setHasNoMatterportScan(true);
      if ((mode === 'event-creation' || mode === 'equipment-creation') && !newEntityCreated) {
        setCreateEntityMode(true);
        if (mode === 'event-creation') {
          setActiveMainToolId(2);
        }
        if (mode === 'equipment-creation') {
          setActiveMainToolId(3);
        }
      }
    } else {
      setHasNoMatterportScan(false);
    }
    if (productDatas) {
      setTags(getTagsForEquipmentsList(productDatas.equipments));
      setTagsEvents(getTagsForEquipmentsList(productDatas.events));
    }
  }, [productDatas]);

  const scrollContentToView = () => {
    const viewerContainer = document.getElementById('viewer-content-container');
    if (viewerContainer) {
      viewerContainer.scrollIntoView({ behavior: 'smooth' });
    }

    const selectedElementId = eventSelected?.uuid || null;
    if (selectedElementId) {
      const selectedElement = document.getElementById(selectedElementId);
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };
  const getEventDescription = (tagUuidOwner) => {
    const event = events.find((event) => event.uuid === tagUuidOwner);
    const eventDescription = event
      ? referentielEventTypes[event.kind]
        ? referentielEventTypes[event.kind] + '\n' + event.desc
        : event.desc
      : '';
    return eventDescription;
  };

  const getCategorytName = (tagUuidOwner) => {
    // Trouver l'équipement correspondant au tag
    let equipment = null;

    // Le tag est il dans un équipement ou dans un état d'équipement
    const prefixUuidOwner = tagUuidOwner.split('-')[0];

    // Trouver l'équipement correspondant au tag
    if (prefixUuidOwner === 'equ') {
      equipment = equipmentsList.find((equipment) => equipment.uuid === tagUuidOwner);
    } else if (prefixUuidOwner === 'eqh') {
      equipmentsList.forEach((equ) => {
        equ?.states?.forEach((state) => {
          if (state.uuid === tagUuidOwner) {
            equipment = equ;
          }
        });
      });
    }

    // Si l'équipement n'est pas trouvé, retourner une chaîne vide
    if (equipment === null || equipment === undefined) return '';

    // Récupérer le nom de l'équipement et de la catégorie
    const title = equipment.title && equipment.title !== '' ? equipment.title : '';
    const categoryName = equipment.kind
      ? equipmentReferencial.equipments.find((category) => category.id === equipment.kind)?.name
      : '';
    const categoryKindName = equipment.category_kind
      ? 'Catégorie : ' +
        equipmentReferencial.equipmentCategoryKinds.find((category) => category.id === equipment.category_kind)?.name
      : equipment.tab !== ''
        ? 'Onglet : ' + equipment.tab
        : '';

    // Retourner le titre de l'équipement ou la catégorie
    return `${title !== '' ? title : categoryName}\n${categoryKindName}`;
  };

  const hideTags = (tagsUuids) => {
    tagsUuids.forEach((tagUuid) => {
      mpSdk.Tag.editOpacity(tagUuid, 0).catch((error) => {});
      mpSdk.Tag.editStem(tagUuid, {
        stemVisible: false,
        stemHeight: -100000000
      }).catch((error) => {});
      // mpSdk.Tag.allowAction(tagUuid, { navigating: false, opening: false });
    });
  };

  const showTags = (tagsUuids) => {
    if (mpSdk === null) return;
    const allTags = [...tags, ...tagsEvents];
    allTags.forEach((tagData) => {
      if (tagsUuids.includes(tagData.uuid)) {
        mpSdk.Tag.editOpacity(tagData.uuid, 1).catch((error) => {});
        mpSdk.Tag.editStem(tagData.uuid, { stemVisible: true, stemHeight: 0.15 }).catch((error) => {});
        // mpSdk.Tag.allowAction(tagData.uuid, { navigating: true, opening: true });
      } else {
        mpSdk.Tag.editOpacity(tagData.uuid, 0).catch((error) => {});
        mpSdk.Tag.editStem(tagData.uuid, {
          stemVisible: false,
          stemHeight: -100000000
        }).catch((error) => {});
        // mpSdk.Tag.allowAction(tagData.uuid, { navigating: false, opening: false });
      }
    });
  };

  const createMatterportTag = async (tagData) => {
    const tagColor =
      tagData.uuid_owner.includes('equ') || tagData.uuid_owner.includes('eqh')
        ? { r: 0 / 255, g: 153 / 255, b: 51 / 255 }
        : { r: 255 / 255, g: 204 / 255, b: 0 / 255 };
    const description =
      tagData.uuid_owner.includes('equ') || tagData.uuid_owner.includes('eqh')
        ? getCategorytName(tagData.uuid_owner) !== '\n'
          ? getCategorytName(tagData.uuid_owner)
          : ''
        : getEventDescription(tagData.uuid_owner);
    const tagIds = await mpSdk.Tag.add({
      id: tagData.uuid,
      description: description,
      icon: 'public_characters_circle-e',
      color: tagColor,
      stemVector: {
        x: tagData.position?.steam_x || 0,
        y: tagData.position?.steam_y || 0,
        z: tagData.position?.steam_z || 0
      },
      anchorPosition: {
        x: tagData.position.x,
        y: tagData.position.y,
        z: tagData.position.z
      }
    });

    const tagId = tagIds[0];
    const isEquipmentTag = tagData.uuid_owner.includes('equ') || tagData.uuid_owner.includes('eqh');
    const isEventTag = tagData.uuid_owner.includes('eve');

    await mpSdk.Tag.allowAction(tagId, {
      navigating: true,
      opening: description === '' || description === '\n' ? false : true
    });

    await mpSdk.Tag.editStem(tagId, { stemHeight: 0.15 });

    await mpSdk.Tag.editIcon(
      tagId,
      isEquipmentTag ? 'public_characters_circle-e' : isEventTag ? 'public_symbols_exclamation-square' : ''
    );

    if (isEquipmentTag) {
      setEquipmentTags((prev) => {
        return [...prev, tagId];
      });
    }

    if (isEventTag) {
      setEventTags((prev) => {
        return [...prev, tagId];
      });
    }
    return tagId;
  };

  useEffect(() => {
    const addTags = async () => {
      if (tags && tagsEvents && (tags.length > 0 || tagsEvents.length > 0) && mpSdk) {
        try {
          // const EventTags = [];
          const allTags = [...tags, ...tagsEvents];
          const tagPromises = allTags.map(async (tagData) => {
            mpSdk.Tag.remove(tagData.uuid);
            return await createMatterportTag(tagData);
          });

          const tagIds = await Promise.all(tagPromises);

          const tagEvents = [];
          const tagEquipments = [];

          tagIds.forEach((tagId) => {
            if (getEquipmentByTagUuid(productDatas.equipments, tagId)) {
              tagEquipments.push(tagId);
            }

            if (getEventByTagUuid(productDatas.events, tagId)) {
              tagEvents.push(tagId);
            }
          });
          if (mode === 'viewer') {
            hideTags([...tagEquipments, ...tagEvents]);
          } else if (mode === 'equipment-location') {
            hideTags([...tagEvents]);
          } else if (mode === 'event-location') {
            hideTags([...tagEquipments]);
          } else if (mode === 'event-creation' || mode === 'equipment-creation') {
            if (mode === 'event-creation') {
              setActiveMainToolId(2);
              hideTags([...tagEquipments]);
            } else if (mode === 'equipment-creation') {
              setActiveMainToolId(3);
              hideTags([...tagEvents]);
            }
            setCreateEntityMode(true);
          }
        } catch (error) {
          console.error('Error adding tags:', error);
        }
        setFirstLaunch(false);
      } else {
        if (mode === 'event-creation' || mode === 'equipment-creation') {
          if (mode === 'event-creation') {
            setActiveMainToolId(2);
            // hideTags([...tagEquipments]);
          } else if (mode === 'equipment-creation') {
            setActiveMainToolId(3);
            // hideTags([...tagEvents]);
          }
          setCreateEntityMode(true);
        }
      }
    };

    addTags();
  }, [mpSdk, tags, tagsEvents]);

  useEffect(() => {
    if (productDatas) {
      const equipments = productDatas?.equipments;
      const events = productDatas?.events;

      if (equipments) {
        setEquipmentsList(equipments);
      }

      if (events) {
        setEventsList(events);
      }
    }
  }, [productDatas]);

  const updateEntityWithCreatedTag = (tmpTagId, entity) => {
    if (!finalTagUuid) return;

    const tmpEntity = { ...entity };

    const isEquipment = tmpEntity.uuid.includes('equ') || tmpEntity.uuid.includes('eqh');
    const list = isEquipment ? equipmentsList : eventsList;
    const setList = isEquipment ? setEquipmentsList : setEventsList;
    const setSelected = isEquipment ? setEquipmentSelected : setEventSelected;

    const entityItem = list.find((item) => item.uuid === tmpEntity.uuid);
    if (!entityItem) return;

    entityItem.tags = entityItem.tags || [];
    entityItem.tags.push(finalTag);

    const updatedList = list.map((item) => (item.uuid === tmpEntity.uuid ? entityItem : item));
    setList(updatedList);
    setSelected(entityItem);
    createMatterportTag(finalTag);
    setEntityHasTag(true);

    setTimeout(() => {
      mpSdk.Tag.open(getTagForEvent(entityItem)?.uuid || getTagForEquipment(entityItem)?.uuid)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    }, 300);
  };

  useEffect(() => {
    if ((equipmentsList || eventsList) && createTagMode && newTagId !== null && mpSdk && newTagPlaced) {
      if (equipmentSelected) {
        updateEntityWithCreatedTag(newTagId, equipmentSelected);
      } else if (eventSelected) {
        updateEntityWithCreatedTag(newTagId, eventSelected);
      }

      mpSdk.Tag.remove(newTagId);
      setCreateTagMode(false);
      setNewTagId(null);
      setNewTagData(null);
      setNewTagPlaced(false);
      setFinalTagUuid(null);
      setFinalTag(null);
    }
  }, [equipmentsList, eventsList, createTagMode, newTagId, equipmentSelected, eventSelected, newTagPlaced]);

  useEffect(() => {
    setEntityHasTag(
      (activeMainToolId === 3 && getTagForEquipment(equipmentSelected) !== null) ||
        (activeMainToolId === 2 && getTagForEvent(eventSelected) !== null)
    );
    cancelTagCreation();
    cancelTagPositionChange();
    if (
      activeMainToolId === null ||
      activeMainToolId === 5 ||
      (activeMainToolId === 3 && !equipmentSelected) ||
      (activeMainToolId === 2 && !eventSelected)
    ) {
      appRouterNavigateEvent(null);
      appRouterNavigateEquipment(null);
    } else if (activeMainToolId === 3 && equipmentSelected) {
      appRouterNavigateEquipment(equipmentSelected.uuid);
    } else if (activeMainToolId === 2 && eventSelected) {
      appRouterNavigateEvent(eventSelected.uuid);
    }
  }, [equipmentSelected, eventSelected, activeMainToolId]);

  const cancelTagCreation = () => {
    if (newTagId && mpSdk) {
      mpSdk.Tag.remove(newTagId);
      setNewTagId(null);
    }
    setCreateTagMode(false);
  };

  const cancelTagPositionChange = () => {
    if (uuidTagSelected && mpSdk) {
      const isEquipment =
        getEquipmentByTagUuid(productDatas.equipments, uuidTagSelected)?.uuid.includes('equ') ||
        getEquipmentByTagUuid(productDatas.equipments, uuidTagSelected)?.uuid.includes('eqh');

      const tagBackupDatas = isEquipment
        ? _.find(tags, { uuid: uuidTagSelected })
        : _.find(tagsEvents, { uuid: uuidTagSelected });

      if (tagBackupDatas) {
        mpSdk.Tag.editPosition(uuidTagSelected, {
          anchorPosition: {
            x: tagBackupDatas.position.x,
            y: tagBackupDatas.position.y,
            z: tagBackupDatas.position.z
          },
          stemVector: {
            x: tagBackupDatas.position.steam_x,
            y: tagBackupDatas.position.steam_y,
            z: tagBackupDatas.position.steam_z
          }
        }).catch((error) => {});
        mpSdk.Tag.editColor(
          uuidTagSelected,
          isEquipment ? { r: 0, g: 153 / 255, b: 51 / 255 } : { r: 255 / 255, g: 204 / 255, b: 0 / 255 }
        ).catch((error) => {});
        mpSdk.Tag.editStem(uuidTagSelected, { stemHeight: 0.15 }).catch((error) => {});
        mpSdk.Tag.allowAction(uuidTagSelected, { navigating: true, opening: true }).catch((error) => {});
        mpSdk.Tag.editOpacity(uuidTagSelected, 1).catch((error) => {});
      }
    }

    setChangeTagPositionMode(false);
    setPointerDatas(null);
  };

  useEffect(() => {
    const handleMove = (e) => {
      const minHeight = 250;
      if (!startDrag) return;

      // Détecter si c'est un événement tactile ou souris
      let clientY = e.touches ? e.touches[0].clientY : e.clientY;

      let sepHeight = clientY + 22;
      if (sepHeight < minHeight) sepHeight = minHeight;
      if (sepHeight > window.innerHeight - minHeight) sepHeight = window.innerHeight - minHeight;

      setSeparatorHeight(sepHeight);
    };

    const handleEnd = () => {
      setStartDrag(false);
      document.removeEventListener('mousemove', handleMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
    };

    if (startDrag) {
      document.addEventListener('mousemove', handleMove);
      document.addEventListener('mouseup', handleEnd);
      document.addEventListener('touchmove', handleMove);
      document.addEventListener('touchend', handleEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [startDrag]);

  // const mainTools = [0, 1, 2, 3, 4, 5, 6];
  const mainTools = [2, 3, 5, 7];
  const mainToolsNames = [
    'Informations',
    'Etat des lieux',
    'Tickets',
    'Equipements',
    'Documents',
    'Photos',
    'Réglages',
    'Pilotage'
  ];

  /** Rend les icônes d'outils */
  const renderTools = () =>
    mainTools.map((toolId) => (
      <ToolIcon
        key={toolId}
        toolId={toolId}
        activeId={activeMainToolId}
        setActiveIconId={setActiveMainToolId}
        isLandscape={isLandscape}
        generateIcon={toolLogo}
        name={mainToolsNames[toolId]}
      />
    ));

  const navigateToTag = (tagUuid) => {
    mpSdk.Tag.editStem(tagUuid, { stemVisible: true, stemHeight: 0.15 })
      .then(() => {
        mpSdk.Mattertag.navigateToTag(tagUuid, mpSdk.Mattertag.Transition.FLY, true)
          .then(() => {})
          .catch((error) => {
            console.error('Error opening tag:', error);
          });
      })
      .catch((error) => {});
  };

  const setIndicatorPosition = (e) => {
    const yOffset = matterportFrameContainer.current.getBoundingClientRect().top;

    const offsetIndicatorR = 125 / 2;

    if (e.targetTouches && e.targetTouches.length > 0) {
      if (e.targetTouches.length > 1) {
        return;
      }
      placeTagIndicator.current.style.left = `${e.targetTouches[0].clientX - offsetIndicatorR}px`;
      placeTagIndicator.current.style.top = `${e.targetTouches[0].clientY + yOffset - offsetIndicatorR}px`;
    } else {
      placeTagIndicator.current.style.left = `${e.clientX - offsetIndicatorR}px`;
      placeTagIndicator.current.style.top = `${e.clientY + yOffset - offsetIndicatorR}px`;
    }
  };

  const iDownHandler = (e) => {
    if (changeTagPositionModeRef.current === true || createTagModeRef.current === true) {
      mpSdk.Pointer.setVisible(true);

      setIndicatorPosition(e);

      circleIndicatorBackGround.current.setAttribute('fill', 'white');
      circleIndicatorBackGround.current.setAttribute('opacity', 0);

      const startIntervalTime = new Date().getTime();

      if (e.targetTouches && e.targetTouches.length > 0) {
        if (e.targetTouches.length > 1) {
          return;
        }
        startTaggingCoords.current = { x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY };
      } else {
        startTaggingCoords.current = { x: e.clientX, y: e.clientY };
      }

      gsap
        .fromTo(
          '#circleIndicator',
          { scale: 0 },
          { scale: 1, duration: delayPinTag / 1000, delay: delayBeforePlaceTagIndicator / 1000, ease: 'expo.out' }
        )
        .then(() => {});

      intervalRef.current = setInterval(() => {
        const currentTime = new Date().getTime();
        if (currentTime - startIntervalTime - delayBeforePlaceTagIndicator > 0) {
          const pCent =
            ((currentTime - startIntervalTime - delayBeforePlaceTagIndicator) / delayPinTag) * 100 > 100
              ? 100
              : ((currentTime - startIntervalTime - delayBeforePlaceTagIndicator) / delayPinTag) * 100;

          setPCentPlaceTag(pCent);
          setPlaceTagIndicatorVisibility('visible');
          if (pCent >= 100) {
            clearInterval(intervalRef.current);
            onPlacedTag();
          }
        }
      }, 16);
    }
  };

  const animateTagAppearance = (tagId) => {
    const obj = { scale: -0.8 };
    const obj2 = { opacity: 0 };

    gsap.to(obj, 0.7, {
      scale: stemHeightScale,
      onUpdate: (o) => {
        mpSdk.Tag.editStem(tagId, { stemHeight: o.scale }).catch((error) => {});
      },
      onUpdateParams: [obj],
      ease: 'elastic.out(1.5,0.3)'
    });

    gsap.to(obj2, 0.5, {
      opacity: 1,
      onUpdate: (o) => mpSdk.Tag.editOpacity(tagId, o.opacity).catch((error) => {}),
      onComplete: () => mpSdk.Tag.open(tagId).catch((error) => {}),
      onUpdateParams: [obj2],
      ease: 'expo.out'
    });
  };

  const updateOrCreateTag = (tagId, isExisting) => {
    mpSdk.Tag.editStem(tagId, { stemVisible: true }).catch((error) => {});
    if (isExisting) {
      api.tags
        .update(tagId, {
          position: {
            x: pointerDatasRef.current.position.x,
            y: pointerDatasRef.current.position.y,
            z: pointerDatasRef.current.position.z,
            steam_x: pointerDatasRef.current.normal.x,
            steam_y: pointerDatasRef.current.normal.y,
            steam_z: pointerDatasRef.current.normal.z
          }
        })
        .then(() => {
          mpSdk.Tag.editColor(
            tagId,
            getEquipmentByTagUuid(productDatas.equipments, tagId)
              ? { r: 0, g: 153 / 255, b: 51 / 255 }
              : { r: 1, g: 204 / 255, b: 0 }
          ).catch((error) => {});
          setChangeTagPositionMode(false);
          mpSdk.Tag.editStem(tagId, { stemHeight: 0.15 });
          mpSdk.Tag.allowAction(tagId, { navigating: true, opening: true });
          refreshProductData();
        });
    } else {
      const newTagData = {
        ...newTagDataRef.current,
        position: {
          x: fullTagsCollectionRef.current[tagId].anchorPosition.x,
          y: fullTagsCollectionRef.current[tagId].anchorPosition.y,
          z: fullTagsCollectionRef.current[tagId].anchorPosition.z,
          steam_x: fullTagsCollectionRef.current[tagId].stemVector.x,
          steam_y: fullTagsCollectionRef.current[tagId].stemVector.y,
          steam_z: fullTagsCollectionRef.current[tagId].stemVector.z
        }
      };
      api.tags.create([newTagData]).then((response) => {
        setFinalTagUuid(response[0].uuid);
        setFinalTag(response[0]);
        refreshProductData();
        setNewTagPlaced(true);
      });
    }
  };

  const onPlacedTag = () => {
    circleIndicatorBackGround.current.setAttribute('opacity', '1');
    gsap.fromTo(
      '#circleIndicatorBackGround',
      { fill: 'white' },
      { fill: 'rgb(241, 118, 51)', duration: 0.7, ease: 'expo.out' }
    );
    gsap.to('#circleIndicator', { scale: 0.02, duration: 0.7, ease: 'elastic.out(1.5,0.75)' }).then(() => {
      setPlaceTagIndicatorVisibility('hidden');
      mpSdk.Pointer.setVisible(false);
    });

    const tagId = uuidTagSelectedRef.current || newTagIdRef.current;
    if (!tagId) return;

    animateTagAppearance(tagId);
    updateOrCreateTag(tagId, !!uuidTagSelectedRef.current);
  };

  const onStopTagging = () => {
    setPlaceTagIndicatorVisibility('hidden');
    clearInterval(intervalRef.current);
    mpSdk.Pointer.setVisible(false);
  };

  const iStopLongClickHandler = (e) => {
    setIndicatorPosition(e);
    if (e.type !== 'touchend' && e.type !== 'mouseup') {
      if (e.targetTouches && e.targetTouches.length > 0) {
        if (
          e.targetTouches[0].clientX < startTaggingCoords.current.x - taggingTolerance ||
          e.targetTouches[0].clientX > startTaggingCoords.current.x + taggingTolerance ||
          e.targetTouches[0].clientY < startTaggingCoords.current.y - taggingTolerance ||
          e.targetTouches[0].clientY > startTaggingCoords.current.y + taggingTolerance
        ) {
          onStopTagging();
        }
      } else {
        if (
          e.clientX < startTaggingCoords.current.x - taggingTolerance ||
          e.clientX > startTaggingCoords.current.x + taggingTolerance ||
          e.clientY < startTaggingCoords.current.y - taggingTolerance ||
          e.clientY > startTaggingCoords.current.y + taggingTolerance
        ) {
          onStopTagging();
        }
      }
    } else {
      onStopTagging();
    }
  };

  const viewerContent = useMemo(() => {
    if (activeMainToolId === null) return null;

    return (
      <ViewerContentContainer
        productDatas={productDatas}
        context="View3D"
        takeScreenShot={takeScreenShot}
        refreshProductData={refreshProductData}
        contentId={activeMainToolId}
        equipmentSelected={equipmentSelected}
        setEquipmentSelected={setEquipmentSelected}
        uuidTagSelected={uuidTagSelected}
        setUuidTagSelected={setUuidTagSelected}
        navigateToTag={navigateToTag}
        hideTags={hideTags}
        showTags={showTags}
        cameraMoving={cameraMoving}
        eventSelected={eventSelected}
        events={events}
        setEventSelected={setEventSelected}
        createEntityMode={createEntityMode}
        setCreateEntityMode={setCreateEntityMode}
        hasNoMatterportScan={hasNoMatterportScan}
        setModalOpen={setModalOpen}
        setActiveMainToolId={setActiveMainToolId}
        setLinkedEntityUuid={setLinkedEntityUuid}
        linkedEntityUuid={linkedEntityUuid}
        setWaitingForApiResponse={setWaitingForApiResponse}
        waitingForApiResponse={waitingForApiResponse}
        setNewEntityCreated={setNewEntityCreated}
        isLandscape={isLandscape}
        mpSdk={mpSdk}
      />
    );
  }, [
    activeMainToolId,
    productDatas,
    refreshProductData,
    takeScreenShot,
    events,
    eventSelected,
    equipmentSelected,
    preSelectedEquipment,
    preSelectedEvent,
    appStateReady
  ]);

  return (
    <Grid2 container spacing={0} sx={{ height: '100dvh', pointerEvents: waitingForApiResponse ? 'none' : 'auto' }}>
      {/* Colonne principale */}
      <Grid2 size={{ xs: 12, md: 'grow' }} sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Box ref={ProductTitleRef} sx={{ flexShrink: 0 }}>
          <ProductTitle data={productDatas} isLandscape={isLandscape} context="View3D" />
        </Box>

        {/* Viewer + Contenu */}
        <Box sx={{ flex: 1, minHeight: 0 }}>
          <Grid2 container spacing={0} sx={{ height: '100%' }}>
            {productDatas?.matterport_scan?.model_id && (
              <Grid2 size={activeMainToolId === null || !isLandscape ? 12 : { xs: 6, sm: 7, md: 8 }} sx={{ flex: 1 }}>
                <Box
                  ref={matterportFrameContainer}
                  sx={{ height: '100%', position: 'relative', pointerEvents: startDrag ? 'none' : 'auto' }}
                >
                  {productDatas?.matterport_scan?.model_id && (
                    <MatterportViewer2
                      modelId={productDatas?.matterport_scan?.model_id}
                      mpDh={mode !== 'equipment-location' && mode !== 'event-location' && mode !== 'viewer' ? 0 : 1}
                      // mpDh={1}
                      mpLang="fr"
                      mpSearch={0}
                      tags={tags}
                      mpSdk={mpSdk}
                      setMpSdk={setMpSdk}
                      iDownHandler={iDownHandler}
                      iStopLongClickHandler={iStopLongClickHandler}
                    />
                  )}
                </Box>
                {appStateReady &&
                  !cameraMoving &&
                  uuidTagSelected &&
                  ((getEquipmentByTagUuid(equipmentsList, uuidTagSelected) && activeMainToolId === 3) ||
                    (getEventByTagUuid(eventsList, uuidTagSelected) && activeMainToolId === 2)) && (
                    <Box
                      sx={{
                        position: 'relative',
                        bottom: '50px',
                        width: mediaQueries.matchesMd ? '130px' : '100px',
                        left: isLandscape
                          ? contentWidth - 65 + 'px'
                          : mediaQueries.matchesMd
                            ? 'calc(50% - 65px)'
                            : 'calc(50% - 50px)',
                        zIndex: 1000,
                        backgroundColor: '#F17633',
                        borderRadius: '20px',
                        height: mediaQueries.matchesMd ? '30px' : '20px',
                        textAlign: 'center',
                        border: 'solid white 2px',
                        cursor: 'pointer',
                        display: 'block'
                      }}
                      onClick={() => {
                        if (changeTagPositionMode === true) {
                          cancelTagPositionChange();
                          mpSdk.Tag.open(uuidTagSelected).catch((error) => {});
                        } else {
                          mpSdk.Tag.close(uuidTagSelected).catch((error) => {});
                          mpSdk.Tag.editStem(uuidTagSelected, {
                            stemHeight: -10000000000000,
                            stemVisible: false
                          }).catch((error) => {});
                          mpSdk.Tag.editOpacity(uuidTagSelected, 0).catch((error) => {});
                          mpSdk.Tag.allowAction(uuidTagSelected, { navigating: false, opening: false }).catch(
                            (error) => {}
                          );
                        }

                        setChangeTagPositionMode(!changeTagPositionMode);
                      }}
                    >
                      <Button
                        size="small"
                        sx={{
                          color: 'white',
                          position: 'relative',
                          top: mediaQueries.matchesMd ? '4px' : '0px',
                          fontSize: mediaQueries.matchesMd ? '12px' : '10px',
                          padding: mediaQueries.matchesMd ? '0px 20px' : '0px 10px'
                        }}
                      >
                        {changeTagPositionMode ? 'ANNULER' : 'REPOSITIONNER'}
                      </Button>
                    </Box>
                  )}

                {((eventSelected && activeMainToolId === 2) || (equipmentSelected && activeMainToolId === 3)) &&
                  !entityHasTag &&
                  appStateReady &&
                  !cameraMoving && (
                    <Box
                      sx={{
                        position: 'relative',
                        bottom: '50px',
                        width: mediaQueries.matchesMd ? '130px' : '100px',
                        left: isLandscape
                          ? contentWidth - 65 + 'px'
                          : mediaQueries.matchesMd
                            ? 'calc(50% - 65px)'
                            : 'calc(50% - 50px)',
                        zIndex: 1000,
                        backgroundColor: '#F17633',
                        borderRadius: '20px',
                        height: mediaQueries.matchesMd ? '30px' : '20px',
                        textAlign: 'center',
                        border: 'solid white 2px',
                        cursor: 'pointer',
                        display: 'block'
                      }}
                      onClick={() => {
                        if (createTagMode) {
                          if (newTagId) {
                            mpSdk.Tag.remove(newTagId).then(() => {
                              setNewTagId(null);
                            });
                          }
                        } else {
                          const tagOwner =
                            eventSelected && activeMainToolId === 2 ? eventSelected.uuid : equipmentSelected.uuid;

                          const tmpTagData = {
                            uuid_organization: productDatas.uuid_organization,
                            uuid_owner: tagOwner,
                            owner_kind: eventSelected && activeMainToolId === 2 ? 'event' : 'equipment',
                            position: {
                              x: 0,
                              y: 0,
                              z: 0,
                              steam_x: 0,
                              steam_y: 0,
                              steam_z: 0
                            }
                          };

                          createMatterportTag(tmpTagData).then((tagId) => {
                            setNewTagId(tagId);
                            setNewTagData(tmpTagData);
                            mpSdk.Tag.editStem(tagId, { stemHeight: -10000000000000, stemVisible: false }).catch(
                              (error) => {}
                            );
                            mpSdk.Tag.editOpacity(tagId, 0).catch((error) => {});
                          });
                        }
                        setCreateTagMode(!createTagMode);
                      }}
                    >
                      <Button
                        size="small"
                        sx={{
                          color: 'white',
                          position: 'relative',
                          top: mediaQueries.matchesMd ? '4px' : '0px',
                          fontSize: mediaQueries.matchesMd ? '12px' : '10px',
                          padding: mediaQueries.matchesMd ? '0px 20px' : '0px 10px'
                        }}
                      >
                        {createTagMode ? 'ANNULER' : 'POSER UN TAG'}
                      </Button>
                    </Box>
                  )}

                <PlaceTagIndicator
                  placeTagIndicator={placeTagIndicator}
                  pCent={pCentPlaceTag}
                  visibility={placeTagIndicatorVisibility}
                  circleIndicatorBackGround={circleIndicatorBackGround}
                ></PlaceTagIndicator>
              </Grid2>
            )}

            <Grid2
              id="content-viewer"
              size={12}
              sx={{
                height: contentHeight + 'px',
                overflow: 'auto',
                position: 'absolute',
                top: isLandscape ? '110px' : 'auto',
                bottom: '0px',
                width: isLandscape ? contentWidth + 'px' : '100%',
                right: isLandscape && !hasNoMatterportScan ? '45px' : '0%'
              }}
            >
              {viewerContent}
            </Grid2>

            {/* SubTools + contenu Format Paysage */}
            {isLandscape && activeMainToolId !== null && (
              <>
                <Grid2 size={productDatas.matterport_scan ? { xs: 6, sm: 5, md: 4 } : 12}>
                  <Grid2 container spacing={0} sx={{ height: '100%' }}>
                    <Grid2 size={{ xs: 'grow' }} sx={{ display: 'flex' }}>
                      <Grid2
                        size={12}
                        sx={{
                          overflow: 'hidden',
                          overflowY: 'auto',
                          position: 'relative',
                          height: waitingForApiResponse ? '100%' : 'fit-content'
                        }}
                        ref={contentLandscapeRef}
                      >
                        <Grid2 container spacing={0} flexDirection="row" sx={{ p: paddingContent + 'px' }}>
                          {waitingForApiResponse && (
                            <Grid2
                              size={12}
                              sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgb(255 255 255 / 50%)',
                                zIndex: 100000
                              }}
                            >
                              <CircularProgress />
                            </Grid2>
                          )}
                          <Grid2
                            ref={titleLandscapeRef}
                            size={12}
                            sx={{ height: '45px', backgroundColor: 'white', position: 'sticky', textAlign: 'center' }}
                          >
                            <View3dContentTitle
                              entityHasTag={entityHasTag}
                              navigateToTag={navigateToTag}
                              mainToolsNames={mainToolsNames}
                              activeMainToolId={activeMainToolId}
                              setUuidTagSelected={setUuidTagSelected}
                              equipmentSelected={equipmentSelected}
                              setEquipmentSelected={setEquipmentSelected}
                              eventSelected={eventSelected}
                              setEventSelected={setEventSelected}
                              cancelTagCreation={cancelTagCreation}
                              cancelTagPositionChange={cancelTagPositionChange}
                              createEntityMode={createEntityMode}
                              setCreateEntityMode={setCreateEntityMode}
                              hasNoMatterportScan={hasNoMatterportScan}
                            />
                          </Grid2>
                          <Grid2
                            id="content-landscape"
                            size={12}
                            sx={{
                              overflow: 'auto', // Permet le scroll si nécessaire
                              minHeight: 0, // Empêche le conteneur de forcer une hauteur fixe
                              height: contentHeight + 'px'
                            }}
                          >
                            {/* {viewerContentContainerRef.current} */}
                          </Grid2>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </Grid2>
                </Grid2>
              </>
            )}
          </Grid2>
        </Box>

        {/* Barre d'outils + contenu en mode portrait */}
        {!isLandscape && (
          <>
            {!hasNoMatterportScan && (
              <Grid2
                size={12}
                sx={{
                  height: '45px',
                  width: '100%',
                  display: 'flex',
                  backgroundColor: appTheme.palette.primary.main
                }}
              >
                <Box sx={{ flex: 1, overflow: 'auto', display: 'flex' }} style={{ overflow: 'hidden' }}>
                  {renderTools()}
                </Box>

                {activeMainToolId !== null && (
                  <div
                    onMouseDown={() => setStartDrag(true)}
                    onTouchStart={() => setStartDrag(true)}
                    style={{ cursor: 'grab' }}
                  >
                    <DragHandleIcon sx={{ fontSize: '45px', color: 'white', width: '30px' }} />
                  </div>
                )}
              </Grid2>
            )}
            {activeMainToolId !== null && (
              <>
                <Grid2
                  size={12}
                  ref={contentPortraitRef}
                  sx={{
                    height: separatorHeight === 0 ? '60%' : window.innerHeight - separatorHeight + 'px',
                    overflow: 'hidden',
                    overflowY: 'auto'
                    // position: 'relative'
                    //  height: waitingForApiResponse ? '100%' : 'fit-content'
                  }}
                >
                  <Grid2
                    container
                    spacing={0}
                    flexDirection="row"
                    sx={{
                      p: paddingContent + 'px',
                      position: waitingForApiResponse ? 'relative' : 'unset',
                      height: waitingForApiResponse ? '100%' : 'fit-content'
                    }}
                  >
                    {waitingForApiResponse && (
                      <Grid2
                        size={12}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'rgb(255 255 255 / 50%)',
                          zIndex: 100000
                        }}
                      >
                        <CircularProgress />
                      </Grid2>
                    )}
                    <Grid2 ref={titlePortraitRef} size={12} sx={{ height: '45px', textAlign: 'center' }}>
                      {!hasNoMatterportScan && (
                        <View3dContentTitle
                          entityHasTag={entityHasTag}
                          navigateToTag={navigateToTag}
                          mainToolsNames={mainToolsNames}
                          activeMainToolId={activeMainToolId}
                          setUuidTagSelected={setUuidTagSelected}
                          equipmentSelected={equipmentSelected}
                          setEquipmentSelected={setEquipmentSelected}
                          eventSelected={eventSelected}
                          setEventSelected={setEventSelected}
                          cancelTagCreation={cancelTagCreation}
                          cancelTagPositionChange={cancelTagPositionChange}
                          createEntityMode={createEntityMode}
                          setCreateEntityMode={setCreateEntityMode}
                          hasNoMatterportScan={hasNoMatterportScan}
                        />
                      )}
                    </Grid2>
                    <Grid2
                      id="content-portrait"
                      size={12}
                      sx={{
                        height: contentHeight + 'px',
                        overflow: 'hidden',
                        overflowY: 'auto'
                      }}
                    ></Grid2>
                  </Grid2>
                </Grid2>
              </>
            )}
          </>
        )}
      </Grid2>

      {/* Barre d'outils en mode paysage */}
      {isLandscape && !hasNoMatterportScan && (
        <Grid2
          sx={{
            width: '45px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: appTheme.palette.primary.main
          }}
        >
          <Box sx={{ flex: 1, overflow: 'auto', pt: '50px' }}>{renderTools()}</Box>
        </Grid2>
      )}
    </Grid2>
  );
};

export default View3d;
