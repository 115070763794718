import { Box, Typography, Chip } from '@mui/material';
import { AppContext } from '~/utils/context/AppContext';
import { useContext, useState } from 'react';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import SyncProblemOutlinedIcon from '@mui/icons-material/SyncProblemOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { LeaseAddUpdate } from './LeaseAddUpdate';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

const LeaseLine = ({ productDatas, leaseData, setPropertyData, refreshProductData }) => {
  const { referencial } = useContext(AppContext);
  const mediaQueries = useContext(MediaQueryContext);

  const getStatusChip = () => {
    let icon = null;
    let label = null;
    let color = null;
    let backgroundColor = null;
    switch (leaseData.status) {
      case 0:
        label = 'Inconnu';
        icon = <HelpOutlineOutlinedIcon style={{ color: '#f5a623' }} />;
        color = '#f5a623';
        backgroundColor = '#fff3e0';
        break;
      case 1:
        label = 'Brouillon';
        icon = <EditOffOutlinedIcon style={{ color: '#f5a623' }} />;
        color = '#f5a623';
        backgroundColor = '#fff3e0';
        break;
      case 2:
        label = 'Actif';
        icon = <ToggleOnOutlinedIcon style={{ color: '#4caf50' }} />;
        color = '#4caf50';
        backgroundColor = '#e8f5e9';
        break;
      case 3:
        label = 'Non renouvellé';
        icon = <SyncProblemOutlinedIcon style={{ color: '#4caf50' }} />;
        color = '#4caf50';
        backgroundColor = '#e8f5e9';
        break;
      case 4:
        label = 'Clôturé';
        icon = <CloseIcon style={{ color: '#d32f2f' }} />; // Icône rouge
        color = '#d32f2f'; // Couleur rouge
        backgroundColor = '#ffcdd2'; // Fond rouge clair
        break;
      default:
        label = 'Inconnu';
        icon = <HelpOutlineOutlinedIcon style={{ color: '#f5a623' }} />;
        color = '#f5a623';
        backgroundColor = '#fff3e0';
        break;
    }

    return (
      <Chip
        label={label}
        size="small"
        icon={icon}
        sx={{
          backgroundColor,
          color,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start', // Alignement correct de l'icône et du texte
          paddingLeft: '4px'
        }}
      />
    );
  };

  const [openEditLease, setOpenEditLease] = useState(false);

  const hanldeOpenEditLease = () => {
    setOpenEditLease(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 0',
        cursor: 'pointer'
      }}
      onClick={(e) => {
        hanldeOpenEditLease();
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minWidth: !mediaQueries.matchesMd && '120px',
          flex: mediaQueries.matchesMd && 1
        }}
      >
        {getStatusChip()}
      </Box>

      <Box sx={{ flex: 3, paddingLeft: '16px' }}>
        <Typography variant="body2" color="textSecondary">
          {new Date(leaseData.start_at).toLocaleDateString()} - {new Date(leaseData.end_at).toLocaleDateString()}
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          {referencial['lease.kind'].find((leaseKind) => leaseKind.id === leaseData.kind).name} - {leaseData.duration}{' '}
          mois - {leaseData.contacts?.map((tenant) => tenant.name).join(' - ')}
        </Typography>
      </Box>

      {openEditLease && (
        <LeaseAddUpdate
          productDatas={productDatas}
          setLeaseAddOpen={setOpenEditLease}
          setPropertyData={setPropertyData}
          leaseData={leaseData}
          refreshProductData={refreshProductData}
        />
      )}
    </Box>
  );
};

export default LeaseLine;
