import { useEffect, useState } from 'react';
import LeaseLine from './LeaseLine';
import { ILease } from '~/interfaces';
import { Grid2 } from '@mui/material';
import { appTheme } from '~/utils/theme';

export const LeaseList = ({ isMobile, productDatas, setPropertyData, refreshProductData }) => {
  const [leaseList, setLeaseList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    setLeaseList(productDatas.leases);
  }, [productDatas.leases]);

  useEffect(() => {
    if (leaseList.length === 0) return;
    setHasMore(leaseList.length > 2);
  }, [leaseList]);

  return (
    <>
      {leaseList
        .sort((a: ILease, b: ILease) => new Date(b.end_at).getTime() - new Date(a.end_at).getTime())
        .map((lease: ILease, index) => (
          <div key={index}>
            {(index < 1 || !showMore) && (
              <LeaseLine
                key={index}
                productDatas={productDatas}
                leaseData={lease}
                setPropertyData={setPropertyData}
                refreshProductData={refreshProductData}
              />
            )}
          </div>
        ))}
      {hasMore && (
        <Grid2
          size={12}
          style={{ cursor: 'pointer', marginTop: '10px', textAlign: 'right' }}
          onClick={() => {
            setShowMore(!showMore);
          }}
          sx={{
            '&:hover': {
              color: appTheme.palette.secondary.main // Change this to your desired color
            }
          }}
        >
          {showMore ? 'Voir plus' : 'Voir moins'}
        </Grid2>
      )}
    </>
  );
};
