import { Button, FormControl, Grid2, InputLabel, MenuItem, Select, TextField, Paper } from '@mui/material';
import { useEffect, useState, useMemo, useRef, useContext } from 'react';
import { api } from '~/api';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { DateValidationError } from '@mui/x-date-pickers/models';
import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';
import './EditEquipmentInfos.scss';
import { energyClassRef, periodicityRef } from '~/utils/references';
import { ProductTitle } from '../ProductTitle/ProductTitle';
import { EquipmentSubTitle } from '../EquipmentSubTitle/EquipmentSubTitle';

export const EditEquipmentInfos = ({
  setModalOpen,
  refreshData = null,
  equipmentData,
  productDatas = null,
  generateSubtitleEquipment = null,
  equipmentReferential
}) => {
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [tmpEquipmentData, setTmpEquipmentData] = useState(null);
  const [error, setError] = useState<DateValidationError | null>(null);
  const [contentHeight, setContentHeight] = useState(0);
  const { equipmentReferencial } = useContext(AppContext);

  const [kindList, setKindList] = useState([]);

  const disableKeyboardEntry = (e: any) => {
    if (e?.keyCode === 40 || e?.keyCode === 38) {
      if (e?.keyCode === 38 && Number(e.target.value) >= dayjs().year()) {
        e?.preventDefault();
        e?.stopPropagation();
      }
    }
  };

  useEffect(() => {
    if (equipmentData) {
      setTmpEquipmentData(equipmentData);
    }
  }, [equipmentData]);

  const headerRef = useRef(null);
  const footerRef = useRef(null);

  const energyClassList = Object.entries(energyClassRef).map(([key, value]) => {
    return <MenuItem value={key}>{value}</MenuItem>;
  });

  const categoryKindList = Object.entries(equipmentReferencial.equipmentCategoryKinds).map(([key, value]) => {
    return <MenuItem value={value.id}>{value.name}</MenuItem>;
  });

  useEffect(() => {
    if (tmpEquipmentData) {
      if (tmpEquipmentData.category_kind) {
        const kindList = Object.entries(equipmentReferencial.equipments).map(([key, value]) => {
          if (value.kinds.includes(tmpEquipmentData.category_kind)) {
            return <MenuItem value={value.id}>{value.name}</MenuItem>;
          }
        });
        setKindList(kindList);
      } else {
        const kindList = Object.entries(equipmentReferencial.equipments).map(([key, value]) => {
          return <MenuItem value={value.id}>{value.name}</MenuItem>;
        });
        setKindList(kindList);
      }
    }
  }, [tmpEquipmentData]);

  const periodicityList = Object.entries(periodicityRef).map(([key, value]) => {
    return <MenuItem value={Number(key)}>{value}</MenuItem>;
  });

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'maxDate': {
        return 'Date supérieure à la date maximale';
      }
      case 'minDate': {
        return 'Date inférieure à la date minimale';
      }

      case 'invalidDate': {
        return "La date saisie n'est pas valide";
      }

      default: {
        return '';
      }
    }
  }, [error]);

  useEffect(() => {
    if (headerRef.current && footerRef.current) {
      setContentHeight(window.innerHeight - headerRef.current.offsetHeight - footerRef.current.offsetHeight - 60);
    }
  });

  return (
    <>
      {tmpEquipmentData && (
        <>
          {/* HEADER */}
          <Grid2 container ref={headerRef}>
            <div style={{ backgroundColor: '#fff7f3', width: '100vw', paddingLeft: '15px' }}>
              <Grid2 size={12}>
                <ProductTitle data={productDatas} />
              </Grid2>
            </div>
            {generateSubtitleEquipment && equipmentData && equipmentReferential && (
              <Grid2 size={12}>
                <EquipmentSubTitle
                  generateSubtitleEquipment={generateSubtitleEquipment}
                  equipmentData={equipmentData}
                  equipmentReferencial={equipmentReferential}
                />
              </Grid2>
            )}
          </Grid2>
          <Grid2
            sx={{ p: '25px' }}
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="stretch"
            wrap="wrap"
          >
            <Grid2
              size={12}
              style={{
                maxHeight: contentHeight + 'px',
                overflow: 'auto',
                paddingBottom: '10px',
                paddingRight: '10px'
              }}
            >
              <Grid2
                container
                spacing={5}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="wrap"
              >
                {/* Classification */}

                <Grid2 size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Grid2
                      container
                      spacing={3}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      alignContent="stretch"
                      wrap="wrap"
                    >
                      <Grid2 size={12}>
                        <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Classification</h3>
                      </Grid2>

                      <Grid2 size={12}>
                        <FormControl fullWidth>
                          <InputLabel size="small" id="energy">
                            Catégorie d'équipement
                          </InputLabel>
                          <Select
                            size="small"
                            labelId="equipment_category_kind"
                            label="Catégorie d'équipement"
                            value={tmpEquipmentData.category_kind ? tmpEquipmentData.category_kind : ''}
                            onChange={(e) => {
                              setTmpEquipmentData({ ...tmpEquipmentData, category_kind: Number(e.target.value) });
                            }}
                          >
                            {categoryKindList}
                          </Select>
                        </FormControl>
                      </Grid2>

                      <Grid2 size={12}>
                        <FormControl fullWidth>
                          <InputLabel size="small" id="energy">
                            Type d'équipement
                          </InputLabel>
                          <Select
                            size="small"
                            labelId="equipment_kind"
                            label="Type d'équipement"
                            value={tmpEquipmentData.kind ? tmpEquipmentData.kind : ''}
                            onChange={(e) => {
                              setTmpEquipmentData({ ...tmpEquipmentData, kind: Number(e.target.value) });
                            }}
                          >
                            {kindList}
                          </Select>
                        </FormControl>
                      </Grid2>

                      <Grid2 size={12}>
                        <TextField
                          label="Nom"
                          size="small"
                          type={'text'}
                          fullWidth
                          // placeholder='Ex: "étage: 2, porte: 3"'
                          value={tmpEquipmentData?.title}
                          onChange={(e) => {
                            setTmpEquipmentData({
                              ...tmpEquipmentData,
                              title: e.target.value
                            });
                          }}
                          autoComplete="off"
                        />
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>

                {/* GENERAL */}

                <Grid2 size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Grid2
                      container
                      spacing={3}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      alignContent="stretch"
                      wrap="wrap"
                    >
                      <Grid2 size={12}>
                        <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Général</h3>
                      </Grid2>

                      <Grid2 size={12}>
                        <TextField
                          label="Marque"
                          size="small"
                          type={'text'}
                          fullWidth
                          // placeholder='Ex: "étage: 2, porte: 3"'
                          value={tmpEquipmentData.data?.model_brand}
                          onChange={(e) => {
                            setTmpEquipmentData({
                              ...tmpEquipmentData,
                              data: { ...tmpEquipmentData.data, model_brand: e.target.value }
                            });
                          }}
                          autoComplete="off"
                        />
                      </Grid2>
                      <Grid2 size={12}>
                        <TextField
                          label="Modèle"
                          size="small"
                          type={'text'}
                          fullWidth
                          // placeholder='Ex: "étage: 2, porte: 3"'
                          value={tmpEquipmentData.data?.model_id}
                          onChange={(e) => {
                            setTmpEquipmentData({
                              ...tmpEquipmentData,
                              data: { ...tmpEquipmentData.data, model_id: e.target.value }
                            });
                          }}
                          autoComplete="off"
                        />
                      </Grid2>
                      <Grid2 size={12}>
                        <TextField
                          label="Numéro de série"
                          size="small"
                          type={'text'}
                          fullWidth
                          // placeholder='Ex: "étage: 2, porte: 3"'
                          value={tmpEquipmentData.data?.model_serial_number}
                          onChange={(e) => {
                            setTmpEquipmentData({
                              ...tmpEquipmentData,
                              data: { ...tmpEquipmentData.data, model_serial_number: e.target.value }
                            });
                          }}
                          autoComplete="off"
                        />
                      </Grid2>

                      <Grid2 size={12}>
                        <FormControl fullWidth>
                          <InputLabel size="small" id="energy">
                            Classe énergétique
                          </InputLabel>
                          <Select
                            size="small"
                            labelId="energy"
                            label="Classe énergétique"
                            value={tmpEquipmentData.energy_class ? tmpEquipmentData.energy_class : ''}
                            onChange={(e) => {
                              setTmpEquipmentData({ ...tmpEquipmentData, energy_class: Number(e.target.value) });
                            }}
                          >
                            {energyClassList}
                          </Select>
                        </FormControl>
                      </Grid2>

                      <Grid2 size={12}>
                        <TextField
                          label="Point de livraison"
                          size="small"
                          type={'text'}
                          fullWidth
                          // placeholder='Ex: "étage: 2, porte: 3"'
                          value={tmpEquipmentData.data?.meter_pmr}
                          onChange={(e) => {
                            setTmpEquipmentData({
                              ...tmpEquipmentData,
                              data: { ...tmpEquipmentData.data, meter_pmr: e.target.value }
                            });
                          }}
                          autoComplete="off"
                        />
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>

                {/* DATES BLOC */}

                <Grid2 size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Grid2
                      container
                      spacing={3}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      alignContent="stretch"
                      wrap="wrap"
                    >
                      <Grid2 size={12}>
                        <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Dates</h3>
                      </Grid2>

                      <Grid2 size={12}>
                        <DatePicker
                          label="Date d'installation"
                          value={tmpEquipmentData?.installed_at ? dayjs(tmpEquipmentData.installed_at) : null}
                          onChange={(e) => {
                            if (dayjs(e).format() !== 'Invalid Date') {
                              setTmpEquipmentData({
                                ...tmpEquipmentData,
                                installed_at: dayjs(e).toISOString()
                              });
                            }
                            if (e === null) {
                              setTmpEquipmentData({
                                ...tmpEquipmentData,
                                installed_at: null
                              });
                            }
                          }}
                          onError={(newError) => setError(newError)}
                          minDate={minDate}
                          maxDate={maxDate}
                          slotProps={{
                            textField: {
                              size: 'small',
                              onKeyDownCapture: disableKeyboardEntry,
                              helperText: errorMessage,
                              fullWidth: true
                            }
                          }}
                          disableFuture
                        ></DatePicker>
                      </Grid2>

                      <Grid2 size={12}>
                        <FormControl fullWidth>
                          <InputLabel size="small" id="periodicity">
                            Périodicité de la maintenance
                          </InputLabel>
                          <Select
                            size="small"
                            labelId="periodicity"
                            label="Périodicité de la maintenance"
                            value={
                              tmpEquipmentData.maintenance_periodicity ? tmpEquipmentData.maintenance_periodicity : ''
                            }
                            onChange={(e) => {
                              setTmpEquipmentData({
                                ...tmpEquipmentData,
                                maintenance_periodicity: Number(e.target.value)
                              });
                            }}
                          >
                            {periodicityList}
                          </Select>
                        </FormControl>
                      </Grid2>

                      <Grid2 size={12}>
                        <DatePicker
                          label="Date de fin de garantie"
                          value={tmpEquipmentData?.warranty_end ? dayjs(tmpEquipmentData.warranty_end) : null}
                          onChange={(e) => {
                            if (dayjs(e).format() !== 'Invalid Date') {
                              setTmpEquipmentData({
                                ...tmpEquipmentData,
                                warranty_end: dayjs(e).toISOString()
                              });
                            }
                            if (e === null) {
                              setTmpEquipmentData({
                                ...tmpEquipmentData,
                                warranty_end: null
                              });
                            }
                          }}
                          onError={(newError) => setError(newError)}
                          minDate={minDate}
                          maxDate={maxDate}
                          slotProps={{
                            textField: {
                              size: 'small',
                              onKeyDownCapture: disableKeyboardEntry,
                              helperText: errorMessage,
                              fullWidth: true
                            }
                          }}
                        ></DatePicker>
                      </Grid2>

                      <Grid2 size={12}>
                        <DatePicker
                          label="Date de prochaine intervention"
                          value={
                            tmpEquipmentData?.maintenance_next_date
                              ? dayjs(tmpEquipmentData.maintenance_next_date)
                              : null
                          }
                          onChange={(e) => {
                            if (dayjs(e).format() !== 'Invalid Date') {
                              setTmpEquipmentData({
                                ...tmpEquipmentData,
                                maintenance_next_date: dayjs(e).toISOString()
                              });
                            }
                            if (e === null) {
                              setTmpEquipmentData({
                                ...tmpEquipmentData,
                                maintenance_next_date: null
                              });
                            }
                          }}
                          onError={(newError) => setError(newError)}
                          minDate={minDate}
                          maxDate={maxDate}
                          slotProps={{
                            textField: {
                              size: 'small',
                              onKeyDownCapture: disableKeyboardEntry,
                              helperText: errorMessage,
                              fullWidth: true
                            }
                          }}
                        ></DatePicker>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>

                {/* Informations BLOC */}

                <Grid2 size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Grid2
                      container
                      spacing={3}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      alignContent="stretch"
                      wrap="wrap"
                    >
                      <Grid2 size={12}>
                        <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Informations</h3>
                      </Grid2>

                      <Grid2 size={12}>
                        <TextField
                          label="Caractéristiques techniques"
                          size="small"
                          type={'text'}
                          fullWidth
                          // placeholder='Ex: "étage: 2, porte: 3"'
                          value={tmpEquipmentData.data?.tech_specs}
                          onChange={(e) => {
                            setTmpEquipmentData({
                              ...tmpEquipmentData,
                              data: { ...tmpEquipmentData.data, tech_specs: e.target.value }
                            });
                          }}
                          autoComplete="off"
                          multiline={true}
                          rows={4}
                        />
                      </Grid2>

                      <Grid2 size={12}>
                        <TextField
                          label="Informations complémentaires"
                          size="small"
                          type={'text'}
                          fullWidth
                          // placeholder='Ex: "étage: 2, porte: 3"'
                          value={tmpEquipmentData.data?.more_infos}
                          onChange={(e) => {
                            setTmpEquipmentData({
                              ...tmpEquipmentData,
                              data: { ...tmpEquipmentData.data, more_infos: e.target.value }
                            });
                          }}
                          autoComplete="off"
                          multiline={true}
                          rows={4}
                        />
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
              </Grid2>
            </Grid2>

            {/* FOOTER */}

            <Grid2 ref={footerRef} size={12} sx={{ mt: '15px' }}>
              <Grid2 container spacing={2} justifyContent={'center'} alignItems={'center'}>
                <Grid2>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    Annuler
                  </Button>
                </Grid2>
                <Grid2>
                  <Button
                    variant="contained"
                    onClick={() => {
                      const promises = [];

                      const tmp = { ...tmpEquipmentData };

                      delete tmp.states;
                      delete tmp.images;
                      delete tmp.events;
                      delete tmp.tags;
                      delete tmp.desc;
                      delete tmp.documents;
                      delete tmp.image_thumbnail;
                      delete tmp.room;
                      delete tmp.sub_tab;
                      delete tmp.tab;

                      api.equipments.update(equipmentData.uuid, tmp).then((resp) => {
                        refreshData(resp);
                        setModalOpen(false);
                      });
                    }}
                  >
                    Valider
                  </Button>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </>
      )}
    </>
  );
};

export default EditEquipmentInfos;
