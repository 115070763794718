import axios from 'axios';
import { IContact, IProductCreate } from '~/interfaces';
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from '.';
import { ContactRelKind } from './contact.models';

export const addProduct = (data: IProductCreate) => {
  return axiosPost('/products', data);
};

export const getProduct = (idProduct: string) => {
  return axiosGet(`/products/${idProduct}`);
};

export const getProducts = () => {
  return axiosGet('products');
};

export const patchProduct = (idProduct: string, data: any) => {
  return axiosPatch(`/products/${idProduct}`, data);
};

export const deleteProduct = (idProduct: string) => {
  return axiosDelete(`/products/${idProduct}`);
};

export const getProductShareLink = (idProduct: string) => {
  return axiosGet(`/products/${idProduct}/product-share-links`);
};

export const getProductImages = (idProduct: string) => {
  return axiosGet(`/products/${idProduct}/images`);
};

export const getProductMatterportImages = (idProduct: string) => {
  return axiosGet(`/products/${idProduct}/matterport-images`);
};

export const getProductMatterportScans = (idProduct: string) => {
  return axiosGet(`/products/${idProduct}/matterport`);
};

export const getDocuments = (idProduct: string) => {
  return axiosGet(`/products/${idProduct}/documents`);
};

export const activateMatterportScan = (idProduct: string) => {
  return axiosPost(`/products/${idProduct}/activate`, {});
};

export const unactivateMatterportScan = (idProduct: string) => {
  return axiosPost(`/products/${idProduct}/unactivate`, {});
};

export const getProductEquipments = (idProduct: string) => {
  return axiosGet(`/products/${idProduct}/equipments`);
};

export const getProductEvents = (idProduct: string) => {
  return axiosGet(`/products/${idProduct}/events`);
};

// Contacts
export const getProductContacts = (idProduct: string) => {
  return axiosGet(`/products/${idProduct}/contacts`);
};

/**
 * @deprecated use instead createProductContactLink
 */
export const assignContact = (idProduct: string, idContact: string) => {
  return axiosPost(`/products/${idProduct}/contacts/${idContact}`, {});
};

/**
 * @deprecated use instead deleteProductContactLink
 */
export const unassignContact = (idProduct: string, idContact: string) => {
  return axiosDelete(`/products/${idProduct}/contacts/${idContact}`);
};

// Create Contact and assign directly on product
export const createProductContact = (idProduct: string, data: any) => {
  return axiosPost(`/products/${idProduct}/contacts`, data);
};

// Creates a relation between an existing Product and an existing Contact.
export const createProductContactLink = (idProduct: string, idContact: string, kind: ContactRelKind) => {
  return axiosPost(`/products/${idProduct}/contacts/${idContact}/kind/${kind}`, {});
};

// Delete the relation between an existing Product and a Contact.
export const deleteProductContactLink = (idProduct: string, idContact: string, kind: ContactRelKind) => {
  return axiosDelete(`/products/${idProduct}/contacts/${idContact}/kind/${kind}`);
};

// Set Thumbnail
export const setThumbnail = (idProduct: string, idfile: string) => {
  return axiosPost(`/products/${idProduct}/set-thumbnail/${idfile}`, {});
};
