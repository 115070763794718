import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { StepsViewer } from '~/app/components/StepsViewer/StepsViewer';

import { WizzardMissionOrder } from '~/app/components/WizzardMissionOrder/WizzardMissionOrder';

export const WizzardMissionOrderForm = ({ spinnerOpen, setSpinnerOpen, handleClose, productData, scanOrder }) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const steps = ['Choix des missions', 'Informations nécessaires', 'Informations pratiques'];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1" sx={{ color: 'primary.main' }}>
          Nouvel ordre de service
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ maxWidth: 600, ml: '5vw' }}>
          <StepsViewer steps={steps} currentStep={activeStep} />
        </Box>
      </Grid>
      <Grid item xs={7}>
        <WizzardMissionOrder
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          product={productData ? productData : null}
          closeModal={handleClose}
          scanOrder={scanOrder}
        />
      </Grid>
    </Grid>
  );
};
