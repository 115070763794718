// import { UploadFile } from '@mui/icons-material';
import { Grid2, FormControl, InputLabel, Select, MenuItem, TextField, Button, Typography } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { AppContext } from '~/utils/context/AppContext';
import { UploadFile } from '~/app/components/CustomDropZoneArea/CustomDropZoneArea';
import { api } from '~/api';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

export const CreateEventForm = ({
  setCreateEntityMode,
  refreshData,
  productDatas,
  setSelectedEvent,
  hasNoMatterportScan,
  setModalOpen,
  setActiveMainToolId = null,
  setLinkedEntityUuid = null,
  linkedEntityUuid = null,
  setWaitingForApiResponse,
  setNewEntityCreated
}) => {
  const [elementDatas, setElementDatas] = useState<any>({ uuid_owner: productDatas.uuid });
  const { referencial } = useContext(AppContext);
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const mediaQueries = useContext(MediaQueryContext);
  const [padding, setPadding] = useState(20);

  useEffect(() => {
    if (mediaQueries.matchesXl) {
      setPadding(50);
    } else if (mediaQueries.matchesLg) {
      setPadding(40);
    } else if (mediaQueries.matchesMd) {
      setPadding(20);
    } else if (mediaQueries.matchesSm) {
      setPadding(10);
    } else if (mediaQueries.matchesXs) {
      setPadding(0);
    }
  }, [mediaQueries]);

  return (
    <Grid2 container spacing={2} sx={hasNoMatterportScan ? { pl: padding, pr: padding } : {}}>
      <Grid2 size={12}>
        <Typography variant={'h6'}>Création d'un nouveau ticket</Typography>
      </Grid2>
      <Grid2 size={12}>
        <Grid2 container spacing={2} justifyContent="center">
          <Grid2 size={12}>
            <Grid2 container spacing={2} justifyContent="center">
              <Grid2 size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth>
                  <InputLabel id="category">Type de ticket *</InputLabel>
                  <Select
                    // disabled={currentElementUUID === '' ? false : true}
                    labelId="category"
                    label="Type de ticket *"
                    value={elementDatas.kind ? elementDatas.kind : ''}
                    onChange={(e) => setElementDatas({ ...elementDatas, kind: Number(e.target.value) })}
                  >
                    {referencial['event.kind'] &&
                      referencial['event.kind'].map((EventType, index) => (
                        <MenuItem key={index + 1} value={EventType.id}>
                          {EventType.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth>
                  <InputLabel id="category">Statut *</InputLabel>
                  <Select
                    // disabled={currentElementUUID === '' ? false : true}
                    labelId="statut"
                    label="Statut *"
                    value={elementDatas.status ? elementDatas.status : ''}
                    onChange={(e) => setElementDatas({ ...elementDatas, status: Number(e.target.value) })}
                  >
                    {referencial['event.status'] &&
                      referencial['event.status'].map((EventStatus, index) => (
                        <MenuItem key={index + 1} value={EventStatus.id}>
                          {EventStatus.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={12}>
                <TextField
                  label="Description *"
                  fullWidth
                  value={elementDatas.desc ? elementDatas.desc : ''}
                  onChange={(e) => setElementDatas({ ...elementDatas, desc: e.target.value })}
                  autoComplete="off"
                  multiline
                  rows={4}
                />
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2 size={!hasNoMatterportScan ? 12 : { xs: 12, md: 6 }}>
            <Grid2 size={12} sx={{ pt: '20px' }}>
              <Typography variant="h6">Ajouter des images</Typography>
            </Grid2>
            <Grid2 size={12} sx={{ p: '20px' }}>
              <UploadFile
                filesType="images"
                setFile={setImages}
                text={
                  <pre>
                    Faites glisser vos images ici <br />
                    (10 megas maximum)
                  </pre>
                }
              ></UploadFile>
            </Grid2>
          </Grid2>

          <Grid2 size={!hasNoMatterportScan ? 12 : { xs: 12, md: 6 }}>
            <Grid2 size={12} sx={{ pt: '20px' }}>
              <Typography variant="h6">Ajouter des documents</Typography>
            </Grid2>

            <Grid2 size={12} sx={{ p: '20px' }}>
              <UploadFile
                filesType="documents"
                setFile={setFiles}
                text={
                  <pre>
                    Faites glisser vos documents ici <br />
                    (10 megas maximum)
                  </pre>
                }
              ></UploadFile>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2
        size={12}
        style={{ position: 'sticky', bottom: '0px', backgroundColor: 'white', zIndex: 1, paddingBottom: '0px' }}
      >
        <Grid2 container spacing={2} justifyContent="center">
          <Grid2 size={6}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                setElementDatas({});
                setCreateEntityMode(false);
                setNewEntityCreated(true);
                if (linkedEntityUuid) {
                  setLinkedEntityUuid(null);
                  setActiveMainToolId(3);
                }
              }}
            >
              Annuler
            </Button>
          </Grid2>
          <Grid2 size={6}>
            <Button
              variant="contained"
              fullWidth
              disabled={
                elementDatas.kind === undefined ||
                elementDatas.kind === null ||
                elementDatas.status === undefined ||
                elementDatas.status === null ||
                elementDatas.desc === '' ||
                elementDatas.desc === null ||
                !elementDatas.desc
              }
              onClick={() => {
                if (setWaitingForApiResponse) setWaitingForApiResponse(true);
                api.events.create(elementDatas).then((response) => {
                  const promisesFiles = [];

                  images.forEach((image) => {
                    const imgdata = new FormData();
                    imgdata.append('uuid_owner', response.uuid);
                    imgdata.append('kind', 'image');
                    imgdata.append('file', image);
                    promisesFiles.push(api.files.create(imgdata));
                  });

                  files.forEach((file) => {
                    const imgdata = new FormData();
                    imgdata.append('uuid_owner', response.uuid);
                    imgdata.append('kind', 'document');
                    imgdata.append('file', file);
                    promisesFiles.push(api.files.create(imgdata));
                  });

                  if (linkedEntityUuid) {
                    promisesFiles.push(api.events.equipment.assign(response.uuid, linkedEntityUuid));
                  }

                  Promise.all(promisesFiles).then(() => {
                    api.events.update(response.uuid, { status: elementDatas.status }).then(() => {
                      refreshData().then(() => {
                        if (hasNoMatterportScan) {
                          setModalOpen(false);
                        } else {
                          setElementDatas({});
                          setCreateEntityMode(false);
                          setSelectedEvent(response);

                          if (setWaitingForApiResponse) setWaitingForApiResponse(false);
                        }
                        setNewEntityCreated(true);
                      });
                    });
                  });
                });
              }}
            >
              Valider
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
