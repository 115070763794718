import { Grid2, FormControl, InputLabel, Select, MenuItem, TextField, Button, Typography } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { api } from '~/api';
import { AppContext } from '~/utils/context/AppContext';
import { UploadFile } from '~/app/components/CustomDropZoneArea/CustomDropZoneArea';
import _ from 'lodash';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import { energyClassRef, periodicityRef } from '~/utils/references';
import dayjs from 'dayjs';

export const CreateEquipmentForm = ({
  setCreateEntityMode,
  refreshData,
  productDatas,
  setSelectedEquipment,
  hasNoMatterportScan,
  setModalOpen,
  setActiveMainToolId = null,
  setLinkedEntityUuid = null,
  linkedEntityUuid = null,
  setWaitingForApiResponse,
  setNewEntityCreated
}) => {
  const [elementDatas, setElementDatas] = useState<any>({ uuid_owner: productDatas.uuid });

  const [equipmentFamily, setEquipmentFamily] = useState([]);
  const [equipmentKinds, setEquipmentKinds] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const { equipmentReferencial } = useContext(AppContext);

  const [images, setImages] = useState([]);

  useEffect(() => {
    setEquipmentFamily(equipmentReferencial?.equipmentCategoryKinds);
    setEquipmentKinds(equipmentReferencial?.equipments);
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      setEquipmentKinds(_.find(equipmentFamily, { id: selectedCategory }).equipments);
    }
    setElementDatas({ ...elementDatas, kind: null });
  }, [selectedCategory]);

  const updateDatas = (e, isInDataObject, key) => {
    const newDatas = JSON.parse(JSON.stringify(elementDatas));
    if (isInDataObject) {
      if (newDatas.data === undefined || newDatas.data === null) newDatas.data = {};
      newDatas.data[key] = e;
    } else {
      newDatas[key] = e;
    }
    setElementDatas(newDatas);
  };

  const energyClassList = Object.entries(energyClassRef).map(([key, value]) => {
    return <MenuItem value={key}>{value}</MenuItem>;
  });

  const periodicityList = Object.entries(periodicityRef).map(([key, value]) => {
    return <MenuItem value={Number(key)}>{value}</MenuItem>;
  });

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Typography variant={'h6'}>Création d'un nouvel équipment</Typography>
      </Grid2>
      <Grid2 size={12}>
        <Grid2 size={12}>
          <Grid2 container spacing={2} justifyContent="center">
            <Grid2 size={12}>
              <FormControl fullWidth>
                <InputLabel id="category">Catégorie</InputLabel>
                <Select
                  labelId="category"
                  label="Catégorie"
                  value={selectedCategory ? _.find(equipmentFamily, { id: selectedCategory }).id : ''}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  {equipmentFamily &&
                    equipmentFamily.map((EquipmentType, index) => (
                      <MenuItem key={index + 1} value={EquipmentType.id}>
                        {EquipmentType.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid2>

            <Grid2 size={12}>
              <FormControl fullWidth>
                <InputLabel id="equipment">Equipement *</InputLabel>
                <Select
                  labelId="equipment"
                  label="Equipement *"
                  value={elementDatas.kind ? elementDatas.kind : ''}
                  onChange={(e) =>
                    setElementDatas({ ...elementDatas, kind: e.target.value, category_kind: selectedCategory })
                  }
                >
                  {equipmentKinds &&
                    equipmentKinds.map((EquipmentType, index) => (
                      <MenuItem key={index + 1} value={EquipmentType.id}>
                        {EquipmentType.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid2>
            <Grid2 size={12}>
              <TextField
                label="Nom"
                fullWidth
                value={elementDatas.title ? elementDatas.title : ''}
                onChange={(e) => setElementDatas({ ...elementDatas, title: e.target.value })}
                autoComplete="off"
              />
            </Grid2>
          </Grid2>
          <Grid2 size={12} sx={{ pt: '20px' }}>
            <Typography variant="h6">Ajouter des images</Typography>
          </Grid2>
          <Grid2 size={12} sx={{ p: '20px' }}>
            <UploadFile
              filesType="images"
              setFile={setImages}
              text={
                <pre>
                  Faites glisser vos images ici <br />
                  (10 megas maximum)
                </pre>
              }
            ></UploadFile>
          </Grid2>

          <Grid2 size={12}>
            <Typography variant="h6">Informations complémentaires</Typography>
            <Grid2
              container
              spacing={2}
              direction="row"
              justifyContent={'center'}
              alignContent="center"
              sx={{ pt: '20px' }}
            >
              <Grid2 size={12}>
                <TextField
                  label="Marque"
                  fullWidth
                  value={elementDatas.data?.model_brand}
                  onChange={(e) => updateDatas(e.target.value, true, 'model_brand')}
                  autoComplete="off"
                />
              </Grid2>
              <Grid2 size={12}>
                <TextField
                  fullWidth
                  label="Modèle"
                  value={elementDatas.data?.model_id}
                  onChange={(e) => updateDatas(e.target.value, true, 'model_id')}
                  autoComplete="off"
                />
              </Grid2>
              <Grid2 size={12}>
                <TextField
                  fullWidth
                  label="Numéro de série"
                  value={elementDatas.data?.model_serial_number}
                  onChange={(e) => updateDatas(e.target.value, true, 'model_serial_number')}
                  autoComplete="off"
                />
              </Grid2>
              <Grid2 size={12}>
                <FormControl fullWidth>
                  <InputLabel id="energy">Energie</InputLabel>
                  <Select
                    labelId="energy"
                    label="Energie"
                    value={elementDatas.energy_class ? elementDatas.energy_class : ''}
                    onChange={(e) => updateDatas(Number(e.target.value), false, 'energy_class')}
                  >
                    {energyClassList}
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={12}>
                <TextField
                  fullWidth
                  label="Point de livraison"
                  value={elementDatas.data?.meter_pmr}
                  onChange={(e) => updateDatas(e.target.value, true, 'meter_pmr')}
                  autoComplete="off"
                />
              </Grid2>

              <Grid2 size={12}>
                <TextField
                  fullWidth
                  label="Caractéristiques techniques"
                  value={elementDatas.data?.tech_specs}
                  onChange={(e) => updateDatas(e.target.value, true, 'tech_specs')}
                  autoComplete="off"
                />
              </Grid2>

              <Grid2 size={12}>
                <FormControl fullWidth>
                  <DesktopDatePicker
                    label="Date d'installation"
                    value={elementDatas.installed_at ? dayjs(elementDatas.installed_at) : null}
                    onChange={(e) => updateDatas(e, false, 'installed_at')}
                  />
                </FormControl>
              </Grid2>

              <Grid2 size={12}>
                <FormControl fullWidth>
                  <InputLabel id="periodicity">Périodicité de la maintenance</InputLabel>
                  <Select
                    labelId="periodicity"
                    label="Périodicité de la maintenance"
                    value={elementDatas.maintenance_periodicity ? elementDatas.maintenance_periodicity : ''}
                    onChange={(e) => updateDatas(Number(e.target.value), false, 'maintenance_periodicity')}
                  >
                    {periodicityList}
                  </Select>
                </FormControl>
              </Grid2>

              <Grid2 size={12}>
                <FormControl fullWidth>
                  <DesktopDatePicker
                    label="Date de fin de garantie"
                    value={elementDatas.warranty_end ? dayjs(elementDatas.warranty_end) : null}
                    onChange={(e) => updateDatas(e, false, 'warranty_end')}
                  />
                </FormControl>
              </Grid2>

              <Grid2 size={12}>
                <FormControl fullWidth>
                  <DesktopDatePicker
                    label="Date de prochaine intervention"
                    value={elementDatas.maintenance_next_date ? dayjs(elementDatas.maintenance_next_date) : null}
                    onChange={(e) => updateDatas(e, false, 'maintenance_next_date')}
                  />
                </FormControl>
              </Grid2>

              <Grid2 size={12}>
                <TextField
                  fullWidth
                  label="Informations complémetaires"
                  value={elementDatas.data?.more_infos}
                  onChange={(e) => updateDatas(e.target.value, true, 'more_infos')}
                  autoComplete="off"
                  multiline
                  rows={4}
                />
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2
        size={12}
        style={{ position: 'sticky', bottom: '0px', backgroundColor: 'white', zIndex: 1, paddingBottom: '0px' }}
      >
        <Grid2 container spacing={2} justifyContent="center">
          <Grid2 size={6}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                setElementDatas({});
                setCreateEntityMode(false);

                setNewEntityCreated(true);
                if (linkedEntityUuid) {
                  setLinkedEntityUuid(null);
                  setActiveMainToolId(2);
                }
              }}
            >
              Annuler
            </Button>
          </Grid2>
          <Grid2 size={6}>
            <Button
              variant="contained"
              fullWidth
              disabled={elementDatas.kind === undefined || elementDatas.kind === null}
              onClick={() => {
                if (setWaitingForApiResponse) setWaitingForApiResponse(true);
                api.equipments.create(elementDatas).then((response) => {
                  const promisesFiles = [];

                  images.forEach((image) => {
                    const imgdata = new FormData();
                    imgdata.append('uuid_owner', response.uuid);
                    imgdata.append('kind', 'image');
                    imgdata.append('file', image);
                    promisesFiles.push(api.files.create(imgdata));
                  });

                  if (linkedEntityUuid) {
                    promisesFiles.push(api.events.equipment.assign(linkedEntityUuid, response.uuid));
                  }

                  Promise.all(promisesFiles).then(() => {
                    refreshData().then(() => {
                      if (hasNoMatterportScan) {
                        setModalOpen(false);
                      } else {
                        setElementDatas({});
                        setCreateEntityMode(false);
                        setSelectedEquipment(response);
                        if (setWaitingForApiResponse) setWaitingForApiResponse(false);
                      }
                      setNewEntityCreated(true);
                    });
                  });
                });
              }}
            >
              Valider
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
