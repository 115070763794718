import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {
  Backdrop,
  Box,
  Fade,
  Grid2,
  IconButton,
  Link,
  Modal,
  Pagination,
  Snackbar,
  Typography,
  useTheme
} from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import { ReactNode, useEffect, useState, useContext, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { api } from '~/api';
import { IProduct, ModalContentType } from '~/interfaces';
import { useWindowSize } from '~/utils/useWindowSize';
import { View3d } from '~/app/components/View3d/View3d';
import { Gallery } from '~/app/components/Gallery/Gallery';
import BasicModal from '~/app/components/BasicModal/BasicModal';
import Breadcrumb from '~/app/components/Breadcrumb/Breadcrumb';
import ProductCard from '~/app/components/ProductCard/ProductCard';
import PropertyInfo from '~/app/components/PropertyInfo/PropertyInfo';
import WizzardEquipment from '~/app/components/WizzardEquipment/WizzardEquipment';
import WizzardEvent from '~/app/components/WizzardEvent/WizzardEvent';
import auth, { isAdmin, isSuperAdmin } from '~/app/components/Auth/AuthApi';
import ProductContact from '~/app/components/ProductContact/ProductContact';
import { WizzardDocument } from '~/app/components/WizzardDocument/WizzardDocument';
import ImagePicker from '~/app/components/ImagePicker/ImagePicker';
import { EditOutlined } from '@mui/icons-material';
import EditPropertyInfos from '~/app/components/EditPropertyInfos/EditPropertyInfos';
import { appTheme } from '~/utils/theme';
import { ProductTitle } from '~/app/components/ProductTitle/ProductTitle';
import { EventShareDialog } from '~/app/components/EventShareDialog/EventShareDialog';

import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';
import { referentielEventTypes, renderEquipmentIcon } from '~/utils/references';
import { WizzardMissionOrderForm } from '~/app/components/WizzardMissionOrder/WizzardMissionOrderFom';
import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import normalizedString from '~/utils/normalizedString';
import BuildingInfo from '~/app/components/BuildingInfo/BuildingInfo';
import EditBuildingInfos from '~/app/components/EditBuildingInfos/EditBuildingInfos';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import './Product.scss';
import { EditCharacteristics } from '~/app/components/Characteristics/EditCharacteristics';
import CharacteristicsList from '~/app/components/Characteristics/CharacteristicsList';
import { LeaseAddUpdate } from '~/app/components/Lease/LeaseAddUpdate';
import { LeaseList } from '~/app/components/Lease/LeaseList';
import { Description } from '~/app/components/ProductDescription/Description';
import { LotsAdd } from '~/app/components/ProductDescription/LotsAdd';
import { LotsList } from '~/app/components/ProductDescription/LotsList';
import { OdmsList } from '~/app/components/OdmList/OdmList';
import { getTagsForEquipmentsList } from '~/app/components/View3d/utilsView3D';
import EditModels from '~/app/components/EditModels/EditModels';
import DocumentItem from '~/app/components/EquipmentsList/DocumentItem';
import { WizzardContact } from '~/app/components/WizzardContact/WizzardContact';
import { EventItem } from '~/app/components/EventsList/EventItem';
import Map from '~/app/components/Map/Map';
import { cp } from 'fs';
import { set } from 'date-fns';

export const Product = ({ setSpinnerOpen, spinnerOpen }) => {
  const mediaQueries = useContext(MediaQueryContext);
  type TuseParams = {
    id: string;
    idEvent: string;
    idEquipment: string;
  };
  const { id } = useParams<TuseParams>();
  const { idEvent } = useParams<TuseParams>();
  const { idEquipment } = useParams<TuseParams>();
  const { isMobile } = useWindowSize();
  const [productDatas, setProductDatas] = useState<null | IProduct>(null);
  const [eventId, setEventId] = useState<any>(null);
  const [error, setError] = useState({ isError: false, error: {} });
  const [modalType, setModalType] = useState<ModalContentType>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [rowsTickets, setRowsTickets] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [images, setImages] = useState([]);
  const [headerImage, setHeaderImage] = useState([]);
  const [equipKinds, setEquipmentKinds] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [wizzardEquipmentOpen, setWizzardEquipmentOpen] = useState(false);
  const [wizzardEventOpen, setWizzardEventOpen] = useState<any>(false);
  const [wizzardDocumentOpen, setWizzardDocumentOpen] = useState<any>(false);
  const [wizzardEquipmentCreationMode, setWizzardEquipmentCreationMode] = useState<boolean>(false);
  const [wizzardEventCreationMode, setWizzardEventCreationMode] = useState<boolean>(false);
  const [characteristicsOpen, setCharacteristicsOpen] = useState<boolean>(false);
  const [leaseAddOpen, setLeaseAddOpen] = useState<boolean>(false);
  const [lotsAddOpen, setLotsAddOpen] = useState<boolean>(false);
  const [selectedUUIDEvent, setSelectedUUIDEvent] = useState<string>('');
  const [selectedUUIDEquipment, setSelectedUUIDEquipment] = useState<string>('');
  const [uuidEventToRowId, setUuidEventToRowId] = useState({});
  const [contacts, setContacts] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  const [updateContact, setUpdateContact] = useState(true);
  const [searchTickets, setSearchTickets] = useState<string>('');
  const [searchDocuments, setSearchDocuments] = useState<string>('');
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [eventListFiltered, setEventListFiltered] = useState([]);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [isMissionOrderOpen, setIsMissionOrderOpen] = useState(false);
  const [links, setLinks] = useState([]);
  const [shortLinkKind, setShortLinkKind] = useState('');
  const [selectedEquipmentsFamily, setSelectedEquipmentsFamily] = useState(null);
  const [selectedEquipmentsList, setSelectedEquipmentsList] = useState([]);
  const [searchedEquipmentsValue, setSearchedEquipmentsValue] = useState('');

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState('');
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [dataToShare, setDataToShare] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [scanOrder, setScanOrder] = useState(false);
  const [dpeList, setDpeList] = useState(null);
  const [lastDpe, setLastDpe] = useState(null);
  const [view3DContext, setView3DContext] = useState<
    | 'viewer'
    | 'event-viewer'
    | 'pin'
    | 'administrate'
    | 'equipment-location'
    | 'event-location'
    | 'equipment-creation'
    | 'event-creation'
    | 'event-creation'
  >('viewer');
  const [openWizzardContact, setOpenWizzardContact] = useState(false);
  const [displayedEvents, setDisplayedEvents] = useState(null);
  // const [displayedDocuments, setDisplayedDocuments] = useState(null);
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [eventSelected, setEventSelected] = useState(null);
  const [equipmentSelected, setEquipmentSelected] = useState(null);
  const [allEvents, setAllEvents] = useState([]);

  const [eventPage, setEventPage] = useState(1);
  const totalPagesEvents = Math.ceil(eventListFiltered.length / 5);

  const [equipmentData, setEquipmentData] = useState<any>(null);
  const [eventData, setEventData] = useState<any>(null);

  const { referencial } = useContext(AppContext);
  const { ReactGA } = useContext(AppContext);
  const [firstLaunch, setFirstLaunch] = useState(true);
  const [googleApiLoaded, setGoogleApiLoaded] = useState<boolean>(false);

  const [listOfAllDocuments, setListOfAllDocuments] = useState([]);
  const [listOfDisplayedDocuments, setListOfDisplayedDocuments] = useState([]);
  const [pageDocuments, setPageDocuments] = useState(1);
  const [totalPagesDocuments, setTotalPagesDocuments] = useState(0);

  useEffect(() => {
    if (productDatas?.files) {
      setListOfAllDocuments(productDatas.files);
    }
  }, [productDatas]);

  useEffect(() => {
    if (listOfAllDocuments.length > 0) {
      const startIndex = (pageDocuments - 1) * 5;
      const endIndex = startIndex + 5;
      setListOfDisplayedDocuments(listOfAllDocuments.slice(startIndex, endIndex));
      setTotalPagesDocuments(Math.ceil(listOfAllDocuments.length / 5));
    }
  }, [listOfAllDocuments, pageDocuments]);

  useEffect(() => {
    let paginatedList = [];
    if (listOfDisplayedDocuments && searchDocuments !== '') {
      const filteredList = listOfAllDocuments.filter((item) => {
        const normalizedSearch = normalizedString(searchDocuments);
        return normalizedString(item.name).indexOf(normalizedSearch) >= 0;
      });
      if (filteredList.length > 4) {
        paginatedList = filteredList.slice((pageDocuments - 1) * 5, pageDocuments * 5);
        setListOfDisplayedDocuments(paginatedList);
      } else {
        setListOfDisplayedDocuments(filteredList);
      }
      setTotalPagesDocuments(Math.ceil(filteredList.length / 5));
    } else if (searchDocuments === '') {
      setListOfDisplayedDocuments(listOfAllDocuments.slice((pageDocuments - 1) * 5, pageDocuments * 5));
      setTotalPagesDocuments(Math.ceil(listOfAllDocuments.length / 5));
    }
  }, [searchDocuments, pageDocuments]);

  const handleContentModal = (type: ModalContentType) => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + ' - modal - ' + type });

    const fullProductDatas = { ...productDatas, allEvents, equipments };

    const tagEvents = getTagsForEquipmentsList(productDatas?.events);

    const tags = getTagsForEquipmentsList(productDatas?.equipments);
    switch (type) {
      case 'View3d':
        return (
          <View3d
            productDatas={productDatas}
            tags={tags}
            events={productDatas?.events}
            tagsEvents={tagEvents}
            mode={'viewer'}
            preSelectedEquipment={null}
            preSelectedEvent={null}
            refreshProductData={refreshProductFullData}
            setModalOpen={setModalOpen}
            appRouterNavigateEvent={setEventSelected}
            appRouterNavigateEquipment={setEquipmentSelected}
          />
        );
      case 'View3d-event-preselected':
        return (
          <View3d
            productDatas={fullProductDatas}
            tags={tags}
            events={productDatas?.events}
            tagsEvents={tagEvents}
            mode={'event-location'}
            preSelectedEquipment={null}
            preSelectedEvent={eventData}
            refreshProductData={refreshProductFullData}
            setModalOpen={setModalOpen}
            appRouterNavigateEvent={setEventSelected}
            appRouterNavigateEquipment={setEquipmentSelected}
          />
        );
      case 'View3d-equipment-preselected':
        return (
          <View3d
            productDatas={fullProductDatas}
            tags={tags}
            events={productDatas?.events}
            tagsEvents={tagEvents}
            mode={'equipment-location'}
            preSelectedEquipment={equipmentData}
            preSelectedEvent={null}
            refreshProductData={refreshProductFullData}
            setModalOpen={setModalOpen}
            appRouterNavigateEvent={setEventSelected}
            appRouterNavigateEquipment={setEquipmentSelected}
          />
        );

      case 'View3d-equipment-creation':
        return (
          <View3d
            productDatas={fullProductDatas}
            tags={tags}
            events={productDatas?.events}
            tagsEvents={tagEvents}
            mode={'equipment-creation'}
            preSelectedEquipment={null}
            preSelectedEvent={null}
            refreshProductData={refreshProductDatasAndEquipments}
            setModalOpen={setModalOpen}
            appRouterNavigateEvent={setEventSelected}
            appRouterNavigateEquipment={setEquipmentSelected}
          />
        );
      case 'View3d-event-creation':
        return (
          <View3d
            productDatas={fullProductDatas}
            tags={tags}
            events={productDatas?.events}
            tagsEvents={tagEvents}
            mode={'event-creation'}
            preSelectedEquipment={null}
            preSelectedEvent={null}
            refreshProductData={refreshProductFullData}
            setModalOpen={setModalOpen}
            appRouterNavigateEvent={setEventSelected}
            appRouterNavigateEquipment={setEquipmentSelected}
          />
        );
      case 'Gallery':
        return <Gallery images={images} startIndex={galleryIndex} />;
      case 'EditPhotos':
        return (
          <ImagePicker
            matterport_model_id={productDatas?.matterport_scan?.model_id}
            refreshProductImages={refreshProductImages}
            productData={productDatas}
            refreshProductData={refreshProductImages}
          />
        );
      case 'EditProductInfos':
        return (
          <div style={{ padding: '25px' }}>
            <EditPropertyInfos
              setSpinnerOpen={test}
              spinnerOpen={false}
              setModalOpen={setModalOpen}
              refreshData={getProductData}
              propertyData={productDatas}
              setPropertyData={setProductDatas}
              lastDpe={lastDpe}
              setLastDpe={setLastDpe}
            ></EditPropertyInfos>
          </div>
        );

      case 'EditBuildingInfos':
        return (
          <div style={{ padding: '25px' }}>
            <EditBuildingInfos
              setSpinnerOpen={test}
              spinnerOpen={false}
              setModalOpen={setModalOpen}
              refreshData={getProductData}
              propertyData={productDatas}
              setPropertyData={setProductDatas}
              lastDpe={lastDpe}
              setLastDpe={setLastDpe}
            ></EditBuildingInfos>
          </div>
        );
      case 'EditModels':
        return (
          <div style={{ padding: '25px' }}>
            <EditModels propertyData={productDatas}></EditModels>
          </div>
        );
      case 'Suppress':
        return (
          <div>
            Êtes-vous sûr de vouloir supprimer cet évènement
            <Stack direction="row-reverse" spacing={1} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
              <Button variant="contained" color={'success'} onClick={handleSuppressEvent}>
                Valider
              </Button>
              <Button variant="outlined" color={'success'} onClick={handleCancelSuppressEvent}>
                Annuler
              </Button>
            </Stack>
          </div>
        );
    }
    // return null;
  };
  const handleSuppressEvent = () => {
    api.events.delete(eventId).then(() => {
      const newEventsArr = JSON.parse(JSON.stringify(productDatas.events));
      productDatas.events.forEach((event, index) => {
        if (event.uuid === eventId) {
          newEventsArr.splice(index, 1);
        }
      });

      const newDatas = JSON.parse(JSON.stringify(productDatas));
      newDatas.events = newEventsArr.reverse();

      setProductDatas(newDatas);
      setModalOpen(!modalOpen);
    });
  };
  const [modalContent, setModalContent] = useState<ReactNode>(null);

  useEffect(() => {
    const fetchModalContent = () => {
      const content = handleContentModal(modalType);
      setModalContent(content);
    };

    fetchModalContent();
  }, [modalType, view3DContext, equipmentData, productDatas, eventData, galleryIndex]);

  const navigate = useNavigate();

  const { equipmentReferencial } = useContext(AppContext);

  const theme = useTheme();

  const handleModal = (type: ModalContentType, context = null) => {
    // setView3DContext(context);
    setSpinnerOpen(false);
    setModalType(type);
    setModalOpen(true);
  };

  useEffect(() => {
    if (!modalOpen) {
      document.body.focus(); // Déplace le focus hors du modal
    }
  }, [modalOpen]);

  useEffect(() => {
    if (idEvent && productDatas && firstLaunch) {
      api.events.get(idEvent).then((res) => {
        setEventData(res);
        setView3DContext('event-location');
        handleModal('View3d-event-preselected');
        setFirstLaunch(false);
      });
    }
  }, [idEvent, productDatas]);

  useEffect(() => {
    if (idEquipment && productDatas && firstLaunch) {
      api.equipments.get(idEquipment).then((res) => {
        setEquipmentData(res);
        setView3DContext('equipment-location');
        handleModal('View3d-equipment-preselected');
        setFirstLaunch(false);
      });
    }
  }, [idEquipment, productDatas]);

  useEffect(() => {
    if (eventSelected) {
      navigate(`/product/${id}/events/${eventSelected}`);
    } else if (!firstLaunch) {
      navigate(`/product/${id}`);
    }
  }, [eventSelected]);

  useEffect(() => {
    if (equipmentSelected) {
      navigate(`/product/${id}/equipments/${equipmentSelected}`);
    } else if (!firstLaunch) {
      navigate(`/product/${id}`);
    }
  }, [equipmentSelected]);

  const GetEquipmentKind = (kind) => {
    return _.find(equipmentReferencial['equipmentCategoryKinds'], { id: kind }) &&
      _.find(equipmentReferencial['equipmentCategoryKinds'], { id: kind }).name
      ? _.find(equipmentReferencial['equipmentCategoryKinds'], { id: kind }).name
      : null;
  };

  const generateSubtitle = (equipmentData) => {
    const labels = [];
    if (
      equipmentReferencial &&
      equipmentData?.category_kind &&
      _.find(equipmentReferencial.equipmentCategoryKinds, {
        id: equipmentData.category_kind
      }) &&
      _.find(equipmentReferencial.equipmentCategoryKinds, {
        id: equipmentData.category_kind
      }).name
    ) {
      labels.push(
        _.find(equipmentReferencial.equipmentCategoryKinds, {
          id: equipmentData.category_kind
        }).name
      );
    }

    let equipmentName = null;
    if (
      equipmentReferencial &&
      equipmentData?.kind &&
      _.find(equipmentReferencial.equipments, { id: equipmentData.kind }) &&
      _.find(equipmentReferencial.equipments, { id: equipmentData.kind }).name
    ) {
      labels.push(_.find(equipmentReferencial.equipments, { id: equipmentData.kind }).name);
      equipmentName = _.find(equipmentReferencial.equipments, { id: equipmentData.kind }).name;
    }

    if (equipmentData?.title && equipmentData.title !== equipmentName) {
      labels.push(equipmentData.title);
    }

    if (equipmentData?.room && equipmentData.room.name) {
      labels.push(equipmentData.room.name);
    }
    return labels.join(' - ');
  };

  const handleCancelSuppressEvent = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (selectedDocument?.uuid_owner.includes('equ')) {
      setEquipmentData(_.find(equipments, { uuid: selectedDocument.uuid_owner }));
    } else {
      setEquipmentData(null);
    }
  }, [selectedDocument]);

  const refreshProductImages = () => {
    api.product
      .getImages(id)
      .then((images) => {
        if (!images || images.length === 0) return;
        setImages(images);
        const feat = images.filter((image) => {
          return image.kind === 'thumbnail';
        });
        if (feat.length === 0 && images && images.length > 0) {
          feat.push(images[0]);
        }
        setHeaderImage(feat);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (searchedEquipmentsValue === '') {
      setSelectedEquipmentsFamily(null);
      setSelectedEquipmentsList([]);
    } else {
      const equipmentsInthisFamily = [];
      equipments.forEach((equipment) => {
        if (equipment.kind && equipment.kind !== 0) {
          const equipmentCategories =
            equipment.category_kind !== 1 && equipment.kind !== 1
              ? _.find(equipmentReferencial['equipments'], { id: equipment.kind }).kinds
              : [1];
          if (
            normalizedString(_.find(equipmentReferencial['equipments'], { id: equipment.kind }).name).includes(
              normalizedString(searchedEquipmentsValue)
            )
          ) {
            equipmentsInthisFamily.push(equipment);
          } else {
            if (equipment.room && equipment.room.name) {
              if (normalizedString(equipment.room.name).includes(normalizedString(searchedEquipmentsValue))) {
                equipmentsInthisFamily.push(equipment);
              }
            } else {
              equipmentCategories.forEach((kind) => {
                if (normalizedString(GetEquipmentKind(kind)).includes(normalizedString(searchedEquipmentsValue))) {
                  equipmentsInthisFamily.push(equipment);
                }
              });
            }
          }
        }
      });
      setSelectedEquipmentsFamily(null);
      setSelectedEquipmentsList(equipmentsInthisFamily);
      setSearchParams({ equ_search: searchedEquipmentsValue });
    }
  }, [searchedEquipmentsValue]);

  useEffect(() => {
    if (searchParams.get('equ_cat') && equipmentReferencial && equipments.length > 0) {
      const index = Number(searchParams.get('equ_cat'));
      const equipmentsInthisFamily = [];
      equipments.forEach((equipment) => {
        if (index !== 18 && equipment.kind && equipment.kind !== 0 && equipment.category_kind === index) {
          equipmentsInthisFamily.push(equipment);
        } else if (index !== 18 && equipment.category_kind === null) {
          if (equipment.kind !== 1 && index !== 1) {
            const referentialEquipment = _.find(equipmentReferencial['equipments'], {
              id: equipment.kind
            });

            const referentialCategoryEquipment = referentialEquipment?.kinds?.map((categoryKindId) => {
              return _.find(equipmentReferencial['equipmentCategoryKinds'], {
                id: categoryKindId
              });
            });

            referentialCategoryEquipment?.forEach((category) => {
              if (category.id === index) {
                equipmentsInthisFamily.push(equipment);
              }
            });
          } else if (index === 1) {
            const referentialEquipment = _.find(equipmentReferencial['equipments'], {
              id: equipment.kind
            });

            const referentialCategoryEquipment = referentialEquipment?.kinds?.map((categoryKindId) => {
              return _.find(equipmentReferencial['equipmentCategoryKinds'], {
                id: categoryKindId
              });
            });

            referentialCategoryEquipment?.forEach((category) => {
              if (category.id === index) {
                equipmentsInthisFamily.push(equipment);
              }
            });
          }
        } else {
          if (index === 18 && equipment.tab === 'inventoryEquipments') {
            if (
              equipment.states &&
              equipment.states[0] &&
              (equipment.states[0]?.data?.inventoryNumber || equipment.states[0]?.images?.length > 0)
            ) {
              equipmentsInthisFamily.push(equipment);
            }
          }
        }
      });
      equipmentsInthisFamily.sort((a, b) => {
        if (a.room === null) return 1;
        if (b.room === null) return -1;
        return a.room.name.localeCompare(b.room.name);
      });
      setSelectedEquipmentsFamily(index);
      setSelectedEquipmentsList(equipmentsInthisFamily);
    }
    if (searchParams.get('equ_search') && equipmentReferencial && equipments.length > 0) {
      setSearchedEquipmentsValue(searchParams.get('equ_search'));
    }
  }, [searchParams, equipmentReferencial, equipments]);

  useEffect(() => {
    if (productDatas && productDatas.links) {
      const tempLinks = [];

      productDatas.links.forEach((link) => {
        if (link.kind === 3) {
          tempLinks.push(link);
        }
        if (link.kind === 2) {
          tempLinks.push(link);
        }
        if (link.kind === 1) {
          tempLinks.push(link);
        }
      });

      setLinks(tempLinks);
    }
    if (productDatas && productDatas.dpes) setDpeList(productDatas.dpes);
  }, [productDatas]);

  useEffect(() => {
    if (dpeList && dpeList.length > 0) {
      const lastDpe = _.maxBy(dpeList, 'update_at');
      setLastDpe(lastDpe);
    }
  }, [dpeList]);

  useEffect(() => {
    if (referencial) {
      setShortLinkKind(_.sortBy(referencial['short_link.kind'], ['id']));
    }
  }, [referencial]);

  const handleShareDialog = (data, isLink) => {
    setOpenShareDialog(!openShareDialog);
    if (isLink) {
      const avrtBaseUrl = process.env.REACT_APP_BASEURL_AVRT;
      const link = `${avrtBaseUrl}/${data.uuid}`;
      setDataToShare(link);
    } else {
      setDataToShare(data);
    }
  };
  useEffect(() => {
    if (productDatas && productDatas.events) {
      const filteredList = rowsTickets.filter((item) => {
        const normalizedSearch = normalizedString(searchTickets);
        return normalizedString(item.desc).indexOf(normalizedSearch) >= 0;
      });
      setEventListFiltered(filteredList);
    }
  }, [searchTickets]);

  useEffect(() => {
    getProductData();
  }, [id]);

  const getProductData = async () => {
    refreshProductFullData();
  };

  const refreshProductFullData = async () => {
    try {
      const productData = await api.product.get(id);
      setProductDatas(productData);
      setEventListFiltered(
        productData.events
          .filter((event) => event.status !== 5 && event.status !== 4)
          .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      );
      setRowsTickets(productData.events);
      setEquipments(productData.equipments);
      setAllEvents(
        productData.events
          .filter((event) => event.status !== 5 && event.status !== 4)
          .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      );

      const productEventFullDatas = [];
      const productEvents = await api.product.events.get(id);

      productEvents.forEach(async (event) => {
        await api.events.equipment.get(event.uuid).then((res) => {
          productEventFullDatas.push({ ...event, equipments: res });
        });
      });

      const productEquipments = await api.product.equipments.get(id);

      const tmpFullProductData = { ...productData, events: productEventFullDatas, equipments: productEquipments };

      setProductDatas(tmpFullProductData);
      setEventListFiltered(
        tmpFullProductData.events
          .filter((event) => event.status !== 5 && event.status !== 4)
          .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      );
      setRowsTickets(tmpFullProductData.events);
      setEquipments(tmpFullProductData.equipments);
      setAllEvents(
        tmpFullProductData.events
          .filter((event) => event.status !== 5 && event.status !== 4)
          .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setSpinnerOpen(false);
    }
  };

  const refreshProductDatasAndEquipments = () => {
    refreshEquipments();
    return refreshProductFullData();
  };

  const refreshProductDatas = () => {
    api.product.get(id).then((res) => {
      setProductDatas(res);
    });
  };

  // Filter Search
  const handleChangeSearchFilterTickets = (e) => {
    const search = e.target.value;
    setSearchTickets(search);
  };

  const handleSearchTicketsType = (type) => {
    if (!type) {
      setEventPage(1);
      setEventListFiltered(rowsTickets);
    } else {
      const filteredList = rowsTickets.filter((item) => {
        return item.kind === type;
      });
      setEventPage(1);
      setEventListFiltered(filteredList);
    }
  };

  const handleChangeSearchFilterDcuments = (e) => {
    const search = e.target.value;
    setSearchDocuments(search);
    if (search === '') {
      setPageDocuments(1);
    }
    if (search.length === 1) {
      setPageDocuments(1);
    }
  };

  const handleChangeSearchFilterEquipments = (e) => {
    const search = e.target.value;
    setSearchedEquipmentsValue(search);
    if (search === '') {
      setSearchParams({});
    }
  };

  useEffect(() => {
    refreshProductImages();
  }, [id]);

  useEffect(() => {
    const claims = auth.getJWT();

    if (updateContact) {
      api.product.contact
        .get(id)
        .then((resp) => {
          setContacts(resp);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setUpdateContact(false);
        });

      api.organizations.contact
        .get(claims.uuid_organization)
        .then((orgcontacts) => {
          const ctcs = orgcontacts.filter((contact) => {
            return contact.uuid_owner !== id;
          });
          setAllContacts(ctcs.reverse());
        })
        .finally(() => {
          setUpdateContact(false);
        });
    }
  }, [updateContact]);

  const updateSelectedDocumentData = (documentData) => {
    api.product
      .get(id)
      .then((res) => {
        setListOfAllDocuments(res.files);
        setProductDatas(res);
      })
      .catch((error) => {
        setError({ isError: true, error });
      })
      .finally(() => {
        setIsLoading(false);
        setSpinnerOpen(false);
      });
  };

  const saveChanges = (dataForm) => {
    handleModifyEvent(dataForm);
  };

  const handleModifyEvent = (dataForm) => {
    if (dataForm === null) return;
    const newEvent = JSON.parse(JSON.stringify(dataForm));
    if (newEvent.tags !== null) delete newEvent.tags;
    if (newEvent.diagnosticType !== null) delete newEvent.diagnosticType;
    if (newEvent.files !== null) delete newEvent.files;
    if (newEvent.data !== null) delete newEvent.data;

    newEvent.status = dataForm.status;
    newEvent.urgent = dataForm.urgent;
    newEvent.kind = dataForm.kind;
    newEvent.desc = dataForm.desc;

    api.events
      .update(dataForm.uuid, newEvent)
      .then((res) => {
        let selectedEventIndex = null;
        for (const [id, value] of Object.entries(rowsTickets)) {
          if (uuidEventToRowId[dataForm.uuid] === value.id) {
            selectedEventIndex = Number(id);
          }
        }
        const newRows = JSON.parse(JSON.stringify(rowsTickets));
        newRows[selectedEventIndex].status = res.status;
        newRows[selectedEventIndex].urgent = res.urgent;
        newRows[selectedEventIndex].diagnosticType = referentielEventTypes[res.kind];
        newRows[selectedEventIndex].kind = res.kind;
        newRows[selectedEventIndex].desc = res.desc;
        setRowsTickets(newRows);
        // setRowsData(newRows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const refreshEquipments = () => {
    api.product.equipments
      .get(id)
      .then((resp) => {
        const kinds = [];
        const equips = [];

        resp.forEach((equipment) => {
          if (_.find(equipmentReferencial['equipments'], { id: equipment.kind }) !== undefined) {
            const arrKindsFamily = [];

            if (equipment.category_kind === 1) {
              arrKindsFamily.push(1);
            } else {
              if (equipment.kind !== 1) {
                if (_.find(equipmentReferencial['equipments'], { id: equipment.kind }).kinds.length > 0) {
                  _.find(equipmentReferencial['equipments'], { id: equipment.kind }).kinds.forEach((kind) => {
                    arrKindsFamily.push(kind);
                  });
                }
              } else {
                arrKindsFamily.push(equipment.category_kind);
              }
            }
            if (arrKindsFamily.length > 0) {
              arrKindsFamily.forEach((kind) => {
                if (kind !== null) {
                  kinds.push(kind);
                  if (!equips[kind]) {
                    equips[kind] = [equipment.uuid];
                  } else {
                    equips[kind].push(equipment.uuid);
                    equips[kind] = _.uniq(equips[kind]);
                  }
                }
              });
            } else {
              if (equipment.tab === 'inventoryEquipments') {
                if (
                  equipment.states &&
                  equipment.states[0] &&
                  (equipment.states[0]?.data?.inventoryNumber || equipment.states[0]?.images?.length > 0)
                ) {
                  kinds.push(18);

                  if (!equips[18]) {
                    equips[18] = [equipment.uuid];
                  } else {
                    equips[18].push(equipment.uuid);
                    equips[18] = _.uniq(equips[18]);
                  }
                }
              }
            }
          } else {
          }
        });

        setEquipmentKinds(_.uniq(kinds));
        setEquipments(resp);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    setDisplayedEvents(eventListFiltered?.slice((eventPage - 1) * 5, eventPage * 5));
  }, [showAllEvents, eventListFiltered, eventPage]);

  // Get equipments data
  useEffect(() => {
    if (equipmentReferencial) {
      refreshEquipments();
    }
  }, [equipmentReferencial]);

  const onRowClickHandler = (row) => {
    navigate(`/product/${id}/events/${row.uuid}`);
  };

  const orderComponents = useMemo(() => {
    if (productDatas) {
      let leftColumn = [
        <ProductCard
          redirect={null}
          title="Ordres de service"
          action={!mediaQueries.matchesMd ? 'Ordre de service' : 'Ajouter un ordre de service'}
          onAction={() => {
            setIsMissionOrderOpen(true);
          }}
        >
          {productDatas?.odms && productDatas?.odms?.length > 0 && (
            <OdmsList
              setProductDatas={setProductDatas}
              odms={productDatas.odms}
              refreshData={getProductData}
            ></OdmsList>
          )}
        </ProductCard>, //Ordre de service
        <ProductCard
          redirect={null}
          title="Derniers tickets"
          action={!mediaQueries.matchesMd ? 'Tickets' : 'Ajouter un ticket'}
          onAction={() => {
            setEventData(null);
            setEquipmentData(null);
            setView3DContext('event-creation');
            handleModal('View3d-event-creation');
          }}
          id={id}
          search={searchTickets}
          handleChangeSearchFilter={rowsTickets.length > 4 ? handleChangeSearchFilterTickets : null}
          events={allEvents}
          handleSearchTicketsType={handleSearchTicketsType}
          isEvent={true}
        >
          {displayedEvents.map((event, index) => (
            <Grid2
              size={12}
              key={event.uuid + '-' + index + Math.random()}
              onClick={() => {
                api.events.get(event.uuid).then((res) => {
                  setEventData(res);
                  setEquipmentData(null);
                  setView3DContext('event-location');
                  handleModal('View3d-event-preselected');
                });
              }}
            >
              <EventItem
                key={event.uuid + '-' + index + Math.random()}
                event={event}
                productDatas={productDatas}
                setEventSelected={setEventSelected}
                context="product"
                isLast={index === displayedEvents.length - 1}
              ></EventItem>
            </Grid2>
          ))}
          {totalPagesEvents > 1 && (
            <Grid2
              size={12}
              sx={{
                textAlign: 'center',
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Pagination
                count={totalPagesEvents}
                page={eventPage}
                onChange={(event, value) => setEventPage(value)}
                color="primary"
                size="small"
              />
            </Grid2>
          )}
        </ProductCard>, //Derniers tickets
        <ProductCard
          redirect={null}
          title="Caractéristiques"
          action={!mediaQueries.matchesMd ? 'Caractéristiques' : 'Ajouter des caractéristiques'}
          onAction={() => {
            setCharacteristicsOpen(true);
          }}
        >
          {productDatas?.characteristics && productDatas?.characteristics?.length > 0 && (
            <CharacteristicsList isMobile={isMobile} productDatas={productDatas}></CharacteristicsList>
          )}
        </ProductCard>, //Caractéristiques
        <ProductCard
          redirect={null}
          title="Liste des équipements"
          action={!mediaQueries.matchesMd ? 'Equipements' : 'Ajouter un équipement'}
          onAction={() => {
            setEquipmentData(null);
            setView3DContext('equipment-creation');
            handleModal('View3d-equipment-creation');
          }}
          handleChangeSearchFilter={equipments.length > 0 ? handleChangeSearchFilterEquipments : null}
          search={searchedEquipmentsValue}
        >
          {equipKinds && equipKinds.length > 0 && (
            <Grid2 container spacing={0}>
              {equipmentReferencial &&
                selectedEquipmentsFamily === null &&
                selectedEquipmentsList.length === 0 &&
                searchedEquipmentsValue === '' &&
                equipKinds.map((kind, index) => (
                  <Grid2 size={{ xs: 6, sm: 6, md: 4 }} key={kind + '-' + index + Math.random()}>
                    {renderEquipmentIcon(kind)}
                    <Link
                      style={{
                        position: 'relative',
                        top: '-6px',
                        paddingLeft: '5px',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        const equipmentsInthisFamily = [];
                        equipments.forEach((equipment) => {
                          if (
                            kind !== 18 &&
                            equipment.kind &&
                            equipment.kind !== 0 &&
                            equipment.category_kind === equipKinds[index]
                          ) {
                            equipmentsInthisFamily.push(equipment);
                          } else if (kind !== 18 && !equipment.category_kind) {
                            if (equipment.kind !== 1 && kind !== 1) {
                              const referentialEquipment = _.find(equipmentReferencial['equipments'], {
                                id: equipment.kind
                              });

                              const referentialCategoryEquipment = referentialEquipment?.kinds?.map(
                                (categoryKindId) => {
                                  return _.find(equipmentReferencial['equipmentCategoryKinds'], {
                                    id: categoryKindId
                                  });
                                }
                              );

                              referentialCategoryEquipment?.forEach((category) => {
                                if (category.id === kind) {
                                  equipmentsInthisFamily.push(equipment);
                                }
                              });
                            } else if (index === 1) {
                              const referentialEquipment = _.find(equipmentReferencial['equipments'], {
                                id: equipment.kind
                              });

                              const referentialCategoryEquipment = referentialEquipment?.kinds?.map(
                                (categoryKindId) => {
                                  return _.find(equipmentReferencial['equipmentCategoryKinds'], {
                                    id: categoryKindId
                                  });
                                }
                              );

                              referentialCategoryEquipment?.forEach((category) => {
                                if (category.id === kind) {
                                  equipmentsInthisFamily.push(equipment);
                                }
                              });
                            }
                          } else {
                            if (kind === 18 && equipment.tab === 'inventoryEquipments') {
                              if (
                                equipment.states &&
                                equipment.states[0] &&
                                (equipment.states[0]?.data?.inventoryNumber || equipment.states[0]?.images?.length > 0)
                              ) {
                                equipmentsInthisFamily.push(equipment);
                              }
                            }
                          }
                        });

                        equipmentsInthisFamily.sort((a, b) => {
                          if (a.room === null) return 1;
                          if (b.room === null) return -1;
                          return a.room.name.localeCompare(b.room.name);
                        });

                        setSearchParams({ equ_cat: equipKinds[index] });
                        setSelectedEquipmentsFamily(equipKinds[index]);
                        setSelectedEquipmentsList(equipmentsInthisFamily);
                      }}
                    >
                      <span style={{ fontSize: '14px' }}>{GetEquipmentKind(kind)}</span>
                    </Link>
                  </Grid2>
                ))}

              {searchedEquipmentsValue !== '' &&
                selectedEquipmentsFamily === null &&
                selectedEquipmentsList.length === 0 && <Grid2 size={12}>Aucun réultat</Grid2>}

              <>
                {selectedEquipmentsFamily !== null && (
                  <Grid2 size={12}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Link
                        sx={{ '&:hover': { textDecoration: 'none', color: '#F17633' } }}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedEquipmentsFamily(null);
                          setSelectedEquipmentsList([]);
                          setSearchParams({});
                        }}
                      >
                        <div style={{ borderRadius: '25px' }}>
                          <ArrowBackIosIcon sx={{ position: 'relative', pt: '3px' }} />
                        </div>
                      </Link>
                      {renderEquipmentIcon(selectedEquipmentsFamily)}
                      <span style={{ fontWeight: '1000', fontSize: '14px', paddingLeft: '3px' }}>
                        {GetEquipmentKind(selectedEquipmentsFamily)}
                      </span>
                    </div>
                  </Grid2>
                )}
                <Grid2 size={12}>
                  <Grid2 container spacing={1} sx={{ pl: '45px', pt: '10px', justifyContent: 'flex-start' }}>
                    {selectedEquipmentsList.length > 0 &&
                      selectedEquipmentsList.map((equipment, index) => (
                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={equipment.uuid + '-' + index + Math.random()}>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                            onClick={() => {
                              setEventData(null);
                              setEquipmentData(equipment);
                              // setView3DContext('equipment-location');
                              handleModal('View3d-equipment-preselected');
                            }}
                          >
                            <Link
                              style={{ cursor: 'pointer' }}
                              // href={`/equipments/${equipment.uuid}`}
                            >
                              {`${_.find(equipmentReferencial['equipments'], { id: equipment.kind }).name}${equipment.title && equipment.title !== '' && _.find(equipmentReferencial['equipments'], { id: equipment.kind }).name !== equipment.title ? ' - ' + equipment.title : ''}${
                                equipment.room !== null && equipment.room.name ? ' - ' + equipment.room.name : ''
                              }${
                                equipment.sub_tab !== null &&
                                selectedEquipmentsFamily === 18 &&
                                _.find(equipmentReferencial['inventoryKinds'], {
                                  id: Number(equipment.sub_tab)
                                })
                                  ? ' - ' +
                                    _.find(equipmentReferencial['inventoryKinds'], {
                                      id: Number(equipment.sub_tab)
                                    }).name
                                  : ''
                              }`}
                            </Link>
                          </div>
                        </Grid2>
                      ))}
                  </Grid2>
                </Grid2>
              </>
            </Grid2>
          )}
        </ProductCard>, //Liste des équipements
        <ProductCard
          redirect={null}
          title="Documents du bien "
          action={!mediaQueries.matchesMd ? 'Documents' : 'Ajouter un document'}
          onAction={() => {
            setSelectedDocument(null);
            setWizzardDocumentOpen(true);
          }}
          search={searchDocuments}
          handleChangeSearchFilter={listOfAllDocuments?.length > 4 ? handleChangeSearchFilterDcuments : null}
        >
          {listOfDisplayedDocuments?.map((document, index) => (
            <div style={{ width: '100%' }} key={document.uuid + '-' + index + Math.random()}>
              <DocumentItem
                document={document}
                setWizzardDocumentOpen={setWizzardDocumentOpen}
                setSelectedDocument={setSelectedDocument}
                isLast={index === listOfDisplayedDocuments.length - 1}
              />
            </div>
          ))}
          {totalPagesDocuments > 1 && (
            <Pagination
              count={totalPagesDocuments}
              page={pageDocuments}
              onChange={(event, value) => setPageDocuments(value)}
              color="primary"
              size="small"
              sx={{ textAlign: 'center', marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}
            />
          )}
        </ProductCard>, //Documents du bien
        <>
          {productDatas.kind !== 3 && ( //Si le bien n'est pas une partie commune
            <ProductCard
              redirect={null}
              title="Liste des baux"
              action={!mediaQueries.matchesMd ? 'Bail' : 'Ajouter un bail'}
              onAction={() => {
                setLeaseAddOpen(true);
              }}
            >
              {productDatas?.leases && productDatas?.leases?.length > 0 && (
                <LeaseList
                  isMobile={isMobile}
                  productDatas={productDatas}
                  setPropertyData={setProductDatas}
                  refreshProductData={getProductData}
                ></LeaseList>
              )}
            </ProductCard>
          )}
        </>, //Liste des baux

        <ProductCard
          redirect={null}
          title="Description"
          action={!mediaQueries.matchesMd ? 'Lot' : 'Ajouter un lot'}
          onAction={() => {
            setLotsAddOpen(true);
          }}
        >
          <Description
            productDatas={productDatas}
            setProductDatas={setProductDatas}
            productDescription={
              productDatas?.description && productDatas?.description !== '' ? productDatas?.description : ''
            }
          ></Description>
          {productDatas?.lots && productDatas?.lots?.length > 0 && (
            <LotsList setProductDatas={setProductDatas} lots={productDatas.lots}></LotsList>
          )}
        </ProductCard> //Description
      ];
      let rightColumn = [
        <>
          {(isSuperAdmin || isAdmin) && (
            <Grid2 size={12}>
              <div className="BlockTitle">
                <div className="Product__lineBlock" style={{ marginBottom: '5px' }}>
                  <div className="Product__lineBlock__title">Modèle</div>

                  {productDatas?.matterport_scan &&
                    productDatas?.matterport_scan?.model_id &&
                    productDatas?.matterport_scan?.scanned_at &&
                    productDatas?.matterport_scan.scanned_at !== '0001-01-01T00:09:21Z' && (
                      <>
                        <span>{new Date(productDatas?.matterport_scan?.scanned_at).toLocaleDateString('fr-FR')}</span>
                      </>
                    )}
                  {isSuperAdmin && (
                    <Link className="Product__link" onClick={() => handleModal('EditModels')} color="primary">
                      <EditOutlined /> {mediaQueries.matchesSm ? 'Editer' : ''}
                    </Link>
                  )}
                </div>
              </div>
            </Grid2>
          )}
          <Grid2 size={12} sx={{ mb: '10px' }}>
            <div
              className={
                productDatas?.matterport_scan && productDatas?.matterport_scan?.model_id
                  ? 'product_sheet_preview_pointer'
                  : 'product_sheet_preview_no_pointer'
              }
              onClick={() => {
                if (productDatas?.matterport_scan && productDatas?.matterport_scan?.model_id) {
                  setEventData(null);
                  setEquipmentData(null);
                  // setView3DContext('viewer');
                  handleModal('View3d');
                }
              }}
              {...(headerImage[0] && {
                style: {
                  borderRadius: '15px',
                  backgroundImage: `url(${process.env.REACT_APP_BASEURL_MEDIAS}/${headerImage[0].uri})`
                }
              })}
              {...((!images || !headerImage || headerImage.length === 0) && {
                style: {
                  borderRadius: '15px',
                  background: 'repeating-linear-gradient(-45deg,#0627461d,#0627461d 20px,#06274614 20px,#06274614 40px'
                }
              })}
            >
              {!productDatas?.matterport_scan && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography variant="h4" sx={{ color: 'gray', textAlign: 'center' }}>
                    Vous ne disposez pas encore d'un jumeau numérique pour ce bien
                  </Typography>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                      borderRadius: '20px',
                      borderColor: 'secondary.main',
                      color: 'white',
                      marginTop: '30px'
                    }}
                    onClick={() => {
                      handleScanOrder();
                    }}
                  >
                    <AddOutlinedIcon fontSize="small" />
                    &nbsp;Commander le Scan 3D
                  </Button>
                </div>
              )}

              <div>
                <div>
                  {productDatas?.matterport_scan && productDatas?.matterport_scan?.model_id && (
                    <PlayCircleIcon
                      fontSize="large"
                      sx={{
                        color:
                          !images || !headerImage || headerImage.length === 0
                            ? appTheme.palette.primary.main
                            : grey[50],
                        transform: 'scale(2)'
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Grid2>
        </>, //Viewer
        <>
          <Grid2 container spacing={2} size={12} className="zer">
            <Grid2 size={12}>
              <div className="BlockTitle">
                <div className="Product__lineBlock" style={{ marginBottom: '0px' }}>
                  <div className="Product__lineBlock__title">Photos</div>
                  <Link className="Product__link" onClick={() => handleModal('EditPhotos')} color="primary">
                    <EditOutlined /> {mediaQueries.matchesSm ? 'Editer' : ''}
                  </Link>
                </div>
              </div>
            </Grid2>
            {!images && (
              <Grid2
                size={12}
                sx={{ pb: '15px', textAlign: 'center' }}
                style={{
                  margin: 0,
                  fontSize: '14px',
                  fontWeight: 400,
                  fontFamily: "'Roboto',sans-serif",
                  lineHeight: 1.5
                }}
              >
                Pas de photos
              </Grid2>
            )}
            {images && (
              <Grid2 size={12}>
                <div className="hide_scrollbar" style={{ width: '100%', overflowX: 'scroll' }}>
                  <div style={{ position: 'relative', display: 'inline-flex' }}>
                    {images
                      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                      .map((image, index) => (
                        <div
                          className="product_sheet_image"
                          key={image.uuid + '-' + index + Math.random()}
                          onClick={() => {
                            setGalleryIndex(index);
                            handleModal('Gallery');
                          }}
                          style={{
                            backgroundImage: `url(${process.env.REACT_APP_BASEURL_MEDIAS}/${image.uri})`
                          }}
                        ></div>
                      ))}
                  </div>
                </div>
              </Grid2>
            )}
          </Grid2>
        </>, //Photos
        <>
          <Grid2 size={12}>
            <div className="BlockTitle">
              <div className="Product__lineBlock" style={{ marginTop: '10px' }}>
                <div className="Product__lineBlock__title">Informations utiles</div>
                <Link
                  className="Product__link"
                  onClick={() => {
                    handleModal('EditProductInfos');
                  }}
                  color="primary"
                >
                  <EditOutlined /> {mediaQueries.matchesSm ? 'Editer' : ''}
                </Link>
              </div>
            </div>
          </Grid2>
          <PropertyInfo
            onModalType={(type: ModalContentType) => setModalType(type)}
            onOpen={(open: boolean) => setModalOpen(open)}
            data={productDatas}
            lastDpe={lastDpe}
          />
        </>, //Informations utiles
        <>
          <Grid2 size={12}>
            <div className="BlockTitle">
              <div className="Product__lineBlock" style={{ marginTop: '10px' }}>
                <div className="Product__lineBlock__title">Informations du bâtiment</div>
                <Link
                  className="Product__link"
                  onClick={() => {
                    handleModal('EditBuildingInfos');
                  }}
                  color="primary"
                >
                  <EditOutlined /> {mediaQueries.matchesSm ? 'Editer' : ''}
                </Link>
              </div>
            </div>
          </Grid2>
          {productDatas &&
            productDatas.address &&
            ((productDatas.address.building_access && productDatas.address.building_access.length > 0) ||
              (productDatas.address.building_name && productDatas.address.building_name.length > 0) ||
              productDatas.address?.data?.construction_period ||
              (productDatas.address.data.construction_year &&
                productDatas.address.data.construction_year.length > 0)) && (
              <BuildingInfo
                onModalType={(type: ModalContentType) => setModalType(type)}
                onOpen={(open: boolean) => setModalOpen(open)}
                data={productDatas}
                lastDpe={lastDpe}
              />
            )}
        </>, //Informations du bâtiment
        <>
          <ProductCard
            redirect={null}
            title="Contacts Utiles"
            action={!mediaQueries.matchesMd ? 'Contact' : 'Associer un contact'}
            onAction={() => {
              setOpenWizzardContact(true);
            }}
          >
            <ProductContact
              contacts={contacts}
              productId={id}
              allContacts={allContacts}
              setUpdateContact={setUpdateContact}
              setAllContacts={setAllContacts}
            />
          </ProductCard>
        </>, //Contacts Utiles
        <>
          <Grid2 size={12}>
            <div className="BlockTitle">
              <div className="Product__lineBlock" style={{ marginBottom: '0px' }}>
                <div className="Product__lineBlock__title">Liens Utiles</div>
              </div>
            </div>
          </Grid2>

          <Grid2 size={12} sx={{ mt: 1, pb: 4 }}>
            {links.length > 0 && (
              <div>
                {links.map((link, index) => {
                  if (shortLinkKind[link.kind] !== undefined) {
                    if (link.kind === 1) {
                      return (
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                          key={link.uuid + '-' + index + Math.random()}
                        >
                          <Typography
                            variant="body1"
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => {
                              window.open(link.link, '_blank');
                            }}
                            sx={{ '&:hover': { color: theme.palette.secondary.main } }}
                          >
                            Ticketing
                          </Typography>
                          <IconButton
                            sx={{
                              '&:hover': { color: appTheme.palette.secondary.main },
                              paddingRight: '0px',
                              ml: 1
                            }}
                            aria-label="link"
                            size="small"
                            onClick={() => handleCopyLink(link)}
                            disableRipple
                            color="primary"
                            data-index={index}
                            data-type="declareEvent"
                          >
                            <LinkIcon />
                          </IconButton>
                          <IconButton
                            sx={{
                              '&:hover': { color: appTheme.palette.secondary.main },
                              paddingRight: '0px',
                              ml: 1
                            }}
                            aria-label="link"
                            size="small"
                            disableRipple
                            color="primary"
                            data-index={index}
                            onClick={() => handleShareDialog(link, true)}
                          >
                            <ShareIcon fontSize="small" />
                          </IconButton>
                        </div>
                      );
                    } else if (link.kind === 2) {
                      return (
                        <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                          <Typography
                            variant="body1"
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => {
                              window.open(link.link, '_blank');
                            }}
                            sx={{ '&:hover': { color: theme.palette.secondary.main } }}
                          >
                            Vue 3D{' '}
                          </Typography>
                          <IconButton
                            sx={{
                              '&:hover': { color: appTheme.palette.secondary.main },
                              paddingRight: '0px',
                              ml: 1
                            }}
                            aria-label="link"
                            size="small"
                            onClick={() => handleCopyLink(link)}
                            disableRipple
                            color="primary"
                            data-index={index}
                            data-type="declareEvent"
                          >
                            <LinkIcon />
                          </IconButton>
                          <IconButton
                            sx={{
                              '&:hover': { color: appTheme.palette.secondary.main },
                              paddingRight: '0px',
                              ml: 1
                            }}
                            aria-label="link"
                            size="small"
                            disableRipple
                            color="primary"
                            data-index={index}
                            onClick={() => handleShareDialog(link, true)}
                          >
                            <ShareIcon fontSize="small" />
                          </IconButton>
                        </div>
                      );
                    } else if (link.kind === 3) {
                      const date = new Date(link.created_at);
                      return (
                        <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                          <Typography
                            variant="body1"
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            sx={{ '&:hover': { color: theme.palette.secondary.main } }}
                            onClick={() => {
                              window.open(link.link, '_blank');
                            }}
                          >
                            {`EDL 3D - ${date.toLocaleDateString('fr-FR')} à ${date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`}
                          </Typography>
                          <IconButton
                            sx={{
                              '&:hover': { color: appTheme.palette.secondary.main },
                              paddingRight: '0px',
                              ml: 1
                            }}
                            aria-label="link"
                            size="small"
                            onClick={() => handleCopyLink(link)}
                            disableRipple
                            color="primary"
                            data-index={index}
                            data-type="declareEvent"
                          >
                            <LinkIcon />
                          </IconButton>
                          <IconButton
                            sx={{
                              '&:hover': { color: appTheme.palette.secondary.main },
                              paddingRight: '0px',
                              ml: 1
                            }}
                            aria-label="link"
                            size="small"
                            disableRipple
                            color="primary"
                            data-index={index}
                            onClick={() => handleShareDialog(link, true)}
                          >
                            <ShareIcon fontSize="small" />
                          </IconButton>
                        </div>
                      );
                    }
                  }
                  return null;
                })}
              </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1">Accés</Typography>
              <IconButton
                sx={{
                  '&:hover': { color: appTheme.palette.secondary.main },
                  paddingRight: '0px',
                  ml: 1
                }}
                aria-label="link"
                size="small"
                disableRipple
                color="primary"
                onClick={() => handleShareDialog(productDatas, false)}
              >
                <ShareIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid2>
        </>, //Liens Utiles
        <>
          <Grid2 size={12}>
            <div className="BlockTitle">
              <div className="Product__lineBlock" style={{ marginBottom: '0px' }}>
                <div className="Product__lineBlock__title">Localisation</div>
              </div>
            </div>
          </Grid2>

          <Grid2 size={12} sx={{ mt: 1, pb: 3 }}>
            <Map
              data={[productDatas]}
              coordsToMoveTo={null}
              noRoll={true}
              uuidHighlited={null}
              googleApiLoaded={setGoogleApiLoaded}
              context={'product-page'}
            ></Map>
          </Grid2>
        </> // Map
      ];
      if (isMobile) {
        const tempLayout = [
          rightColumn[0],
          rightColumn[1],
          rightColumn[2],
          rightColumn[3],
          leftColumn[0],
          leftColumn[1],
          leftColumn[2],
          leftColumn[3],
          leftColumn[4],
          leftColumn[5],
          leftColumn[6],
          rightColumn[4],
          rightColumn[5],
          rightColumn[6]
        ];
        return { leftColumn: tempLayout, rightColumn: [] };
      } else {
        return { leftColumn, rightColumn };
      }
    } else {
      return { leftColumn: [], rightColumn: [] };
    }
  }, [
    // eventListFiltered,
    // totalPagesEvents,
    eventPage,
    isMobile,
    productDatas,
    showAllEvents,
    mediaQueries.matchesMd,
    id,
    images,
    handleModal,
    lastDpe,
    allContacts,
    GetEquipmentKind,
    allEvents,
    contacts,
    // displayedEvents,
    equipKinds,
    equipmentReferencial,
    equipments,
    getProductData,
    handleChangeSearchFilterEquipments,
    handleSearchTicketsType,
    handleShareDialog,
    headerImage,
    links,
    mediaQueries.matchesSm,
    rowsTickets.length,
    searchDocuments,
    searchTickets,
    searchedEquipmentsValue,
    selectedEquipmentsFamily,
    selectedEquipmentsList,
    setSearchParams,
    shortLinkKind,
    theme.palette.secondary.main
  ]);

  if (error.isError) return <p>Une erreur est survenue</p>;

  const test = (value) => {
    setSpinnerOpen(value);
  };

  const modalClosed = (str: string) => {
    // Déplace le focus sur un élément accessible (ex: un bouton d’ouverture de la modale)
    document.getElementById('modal-trigger')?.focus();

    if (productDatas === null) return;
  };

  const refreshEvents = (e) => {
    api.product
      .get(id)
      .then((res) => {})
      .catch((error) => {});
  };

  const setSelectedProduct = (row) => {
    setSelectedUUIDEvent(row.uuid);
    setWizzardEventCreationMode(false);
    setWizzardEventOpen(true);
  };

  const handleClose = () => {
    setIsMissionOrderOpen(!isMissionOrderOpen);
    if (isMissionOrderOpen === true) {
      getProductData();
    }
  };

  const resetRouter = () => {
    navigate(`/product/${id}`);
  };

  const handleCopyLink = (link) => {
    const avrtBaseUrl = process.env.REACT_APP_BASEURL_AVRT;

    if (link.kind === 2) {
      setOpenSnackBarMessage('Le lien de la vue 3D a été copié dans le presse papier');
      navigator.clipboard.writeText(`${avrtBaseUrl}/${link.uuid}`);
    } else if (link.kind === 1) {
      setOpenSnackBarMessage("Le lien de l'outil de ticketing a été copié dans le presse papier");
      navigator.clipboard.writeText(`${avrtBaseUrl}/${link.uuid}`);
    } else if (link.kind === 3) {
      setOpenSnackBarMessage("Le lien de l'EDL 3D a été copié dans le presse papier");
      navigator.clipboard.writeText(`${avrtBaseUrl}/${link.uuid}`);
    }
    setOpenSnackBar(true);
  };

  const handleScanOrder = () => {
    setIsMissionOrderOpen(true);
    setScanOrder(true);
  };

  return (
    <div className="Product">
      <div>
        <BasicModal
          // aria-hidden={!modalOpen}
          modalOpen={modalOpen}
          onModalOpen={(open: boolean) => setModalOpen(open)}
          onModalClose={modalClosed}
          context={modalType}
          resetRouter={resetRouter}
        >
          {modalContent}
        </BasicModal>
      </div>

      {productDatas && (
        <>
          <ProductTitle data={productDatas} />
          <Box
            sx={{
              mb: 4,
              width: 1,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {productDatas.address && productDatas.address.street && (
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                  // paddingRight: '20px'
                }}
              >
                <Breadcrumb
                  data={[
                    { label: 'Accueil', href: '/' },
                    { label: 'Portefeuille', href: '/' }
                  ]}
                  last={`Fiche du Bien`}
                  setSpinnerOpen={setSpinnerOpen}
                />
              </Box>
            )}
          </Box>

          <div>
            <div className="Product__lineBlock">
              <Box
                sx={{
                  width: 1,
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <div className="Product__link">
                  {' '}
                  <div className="test"> </div>
                </div>
              </Box>
            </div>

            <div className="Product__info">
              <div className="Product__info__content">
                {!isMobile ? (
                  <>
                    <div className={'Product__info__content__start'}>{orderComponents.leftColumn}</div>
                    <div className={'Product__info__content__end'}>
                      {orderComponents.rightColumn}
                      <br />
                    </div>
                  </>
                ) : (
                  <>
                    <div className={'Product__info__content__start_mobile'}>{orderComponents.leftColumn}</div>
                  </>
                )}
              </div>
            </div>

            <Snackbar
              open={openSnackBar}
              autoHideDuration={2000}
              onClose={() => setOpenSnackBar(false)}
              message={openSnackBarMessage}
              ContentProps={{
                sx: {
                  background: appTheme.palette.primary.main
                }
              }}
            />

            {openShareDialog && (
              <EventShareDialog
                open={openShareDialog}
                onClose={() => setOpenShareDialog(false)}
                eventData={productDatas}
                isFromProductPage={true}
                dataToShare={dataToShare}
              />
            )}
            {wizzardEquipmentOpen && (
              <WizzardEquipment
                productDatas={productDatas}
                setWizzardEquipmentOpen={setWizzardEquipmentOpen}
                wizzardEquipmentCreationMode={wizzardEquipmentCreationMode}
                selectedUUIDEquipment={selectedUUIDEquipment}
                refreshEquipments={refreshEquipments}
              ></WizzardEquipment>
            )}
            {wizzardEventOpen && (
              <WizzardEvent
                saveChanges={saveChanges}
                productDatas={productDatas}
                setWizzardEventOpen={setWizzardEventOpen}
                refreshEvents={refreshEvents}
                wizzardEventCreationMode={wizzardEventCreationMode}
                selectedUUIDEvent={selectedUUIDEvent}
                wizzardOpen={wizzardEventOpen}
              ></WizzardEvent>
            )}
            {wizzardDocumentOpen && (
              <WizzardDocument
                productDatas={productDatas}
                setWizzardDocumentOpen={setWizzardDocumentOpen}
                documentData={selectedDocument}
                setDocumentData={setSelectedDocument}
                updateSelectedDocumentData={updateSelectedDocumentData}
                setSelectedUUIDEquipment={setSelectedUUIDEquipment}
                setWizzardEquipmentCreationMode={setWizzardEquipmentCreationMode}
                setWizzardEquipmentOpen={setWizzardEquipmentOpen}
                generateSubtitleEquipment={generateSubtitle}
                equipmentData={equipmentData}
                equipmentReferential={equipmentReferencial}
              ></WizzardDocument>
            )}
            {characteristicsOpen && (
              <EditCharacteristics
                productDatas={productDatas}
                setCharacteristicsOpen={setCharacteristicsOpen}
                setPropertyData={setProductDatas}
              ></EditCharacteristics>
            )}
            {leaseAddOpen && (
              <LeaseAddUpdate
                productDatas={productDatas}
                setLeaseAddOpen={setLeaseAddOpen}
                setPropertyData={setProductDatas}
                leaseData={null}
                refreshProductData={getProductData}
              />
            )}
            {lotsAddOpen && (
              <LotsAdd
                productDatas={productDatas}
                setLotsAddOpen={setLotsAddOpen}
                setPropertyData={setProductDatas}
              ></LotsAdd>
            )}
            {openWizzardContact && (
              <WizzardContact
                open={openWizzardContact}
                contactsAlreadyAssociated={contacts}
                onClose={() => {
                  setOpenWizzardContact(false);
                }}
                setUpdateContact={setUpdateContact}
                productId={id}
                contacts={allContacts}
                isProductPage={true}
                setContacts={setAllContacts}
              />
            )}
            {isMissionOrderOpen && (
              <Modal
                open={isMissionOrderOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 1000
                }}
              >
                <Fade in={isMissionOrderOpen}>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '98%',
                      height: '100%',
                      backgroundColor: 'white',
                      p: 3
                    }}
                  >
                    <Grid2 size={12} sx={{ display: 'flex', justifyContent: 'end' }} component="div">
                      <IconButton onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </Grid2>
                    <WizzardMissionOrderForm
                      setSpinnerOpen={setSpinnerOpen}
                      spinnerOpen={spinnerOpen}
                      handleClose={handleClose}
                      productData={productDatas}
                      scanOrder={scanOrder}
                    />
                  </Box>
                </Fade>
              </Modal>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Product;
