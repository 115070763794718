import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Grid2, Avatar, Modal, Box, IconButton } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { api } from '~/api';
import dayjs from 'dayjs';
import Resizer from 'react-image-file-resizer';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ConfirmationDialog } from '~/app/components/ConfirmationDialog/ConfirmationDialog';
import { useDialog } from '~/utils/useDialog';
import { useSwipeable } from 'react-swipeable';

export const MessageItem = ({ isCurrentUser, index, message, eventUuid, getEventFullData, isAdmin }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [userFullData, setUserFullData] = useState(null);
  const [images, setImages] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [deleteDialogOpen, handleOpenDeleteDialog, handleCloseDeleteDialog] = useDialog();

  const [suppressionType, setSuppressionType] = useState(null);
  const [fileToSuppress, setFileToSuppress] = useState(null);

  const styles = {
    previewContainer: {
      display: 'flex',
      justifyContent: 'left',
      flexWrap: 'wrap' as 'wrap',
      gap: '10px',
      marginTop: '20px'
    },
    previewItem: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      alignItems: 'center',
      border: '.1px solid #ccc',
      padding: '10px',
      borderRadius: '5px',
      width: '50px',
      position: 'relative' as 'relative',
      transition: 'background-color 0.3s',
      justifyContent: 'center'
    },
    image: {
      maxWidth: '100%',
      maxHeight: '50px',
      objectFit: 'contain' as 'contain',
      cursor: 'pointer'
    }
  };

  const handleOpen = (index) => {
    setSelectedImage(index);
    setSelectedImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const confirmDeleteMessage = (): void => {
    if (suppressionType === 'message') {
      api.events.message.delete(eventUuid, message.uuid).then((res) => {
        getEventFullData();
      });
    }
    if (suppressionType === 'file') {
      api.files.delete(fileToSuppress.uuid).then((res) => {
        getEventFullData();
      });
    }
    setFileToSuppress(null);
    setSuppressionType(null);
    handleCloseDeleteDialog();
  };

  const handleNext = useCallback(() => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images]);

  const handlePrev = useCallback(() => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  }, [images]);

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name)
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    };
  };

  const resizeFile = (file: Blob) =>
    new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        3000, // Largeur max pour l'upload
        3000, // Hauteur max pour l'upload
        'JPEG',
        80, // Qualité
        0, // Rotation
        (uri) => resolve(uri), // Retourne le fichier
        'file',
        undefined
      );
    });

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files);

    // Filtrer les fichiers déjà ajoutés
    const newFiles = files.filter((file) => !selectedFiles.some((f) => f.name === file.name));
    const resizedFiles = await Promise.all(
      newFiles.map(async (file) => {
        const resized = await resizeFile(file);
        return { ...file, resized };
      })
    );

    if (!resizedFiles || resizedFiles.length === 0) {
      return;
    }

    const file = resizedFiles[0].resized as Blob;
    const body = new FormData();

    body.append('file', file);
    body.append('uuid_owner', message.uuid);
    body.append('owner_kind', 'message');

    api.files.create(body).then((res) => {
      getEventFullData();
    });
  };

  useEffect(() => {
    if (message?.files) {
      setImages(message.files);
    }
  }, [message]);

  useEffect(() => {
    if (message?.uuid_user) {
      api.users.get(message.uuid_user).then((res) => {
        setUserFullData(res);
      });
    }
  }, [message]);

  return (
    <Grid2 container flexDirection={isCurrentUser ? 'row-reverse' : 'row'} key={index} sx={{}}>
      <input
        type="file"
        id={'fileInput-' + message.uuid}
        accept="image/*"
        style={{ display: 'none' }}
        multiple
        onChange={(e) => {
          handleFileChange(e);
        }}
      />
      <Grid2 size={12}>
        {!isCurrentUser ? (
          <Grid2 container sx={{ height: '100%' }} justifyContent={'left'}>
            <Grid2 size={'auto'} sx={{ height: '100%' }} justifyContent={'space-between'}>
              <Grid2 container flexDirection={'column'}>
                <Grid2 size={'auto'}>
                  <Avatar
                    {...stringAvatar(message.creator_name)}
                    src={
                      userFullData && userFullData.avatar_image_uri
                        ? process.env.REACT_APP_BASEURL_MEDIAS + '/' + userFullData.avatar_image_uri
                        : ''
                    }
                  />
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2
              sx={{
                mt: '20px',
                ml: '10px',
                backgroundColor: isCurrentUser ? '#152944' : '#f0f0f0',
                borderRadius: '4px 24px 24px 24px',
                color: isCurrentUser ? 'white' : 'black',
                padding: '10px'
              }}
              size={9}
            >
              {message.content}
              {message?.files && message?.files.length > 0 && (
                <div className="preview-container" style={styles.previewContainer}>
                  {message?.files?.map((file, index) => (
                    <div key={index + file.uuid} className="preview-item" style={styles.previewItem}>
                      {isAdmin && (
                        <CancelIcon
                          onClick={() => {
                            setFileToSuppress(file);
                            setSuppressionType('file');
                            handleOpenDeleteDialog();
                          }}
                          sx={{
                            cursor: 'pointer',
                            top: '-5px',
                            right: '-5px',
                            position: 'absolute',
                            margin: 0,
                            padding: 0,
                            backgroundColor: !isCurrentUser ? 'white' : 'black',
                            borderRadius: '50%'
                          }}
                        />
                      )}
                      <img
                        src={process.env.REACT_APP_BASEURL_MEDIAS + '/' + file.uri}
                        alt={file.name}
                        style={styles.image}
                        onClick={() => {
                          setSelectedImage(index);
                          handleOpen(index);
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
              <Grid2 container sx={{ pt: '10px', height: '30px' }}>
                {isAdmin && (
                  <Grid2 size={'grow'} sx={{}}>
                    <DeleteIcon
                      sx={{ cursor: 'pointer', fontSize: '20px' }}
                      onClick={() => {
                        setSuppressionType('message');
                        handleOpenDeleteDialog();
                      }}
                    ></DeleteIcon>
                    <AttachFileIcon
                      sx={{ cursor: 'pointer', fontSize: '20px' }}
                      onClick={() => {
                        document.getElementById('fileInput-' + message.uuid).click();
                      }}
                    ></AttachFileIcon>
                  </Grid2>
                )}
                <Grid2 size={'auto'} sx={{ alignContent: 'center' }}>
                  <div style={{ fontSize: '10px', textAlign: 'right', marginTop: '5px' }}>
                    {dayjs(message.created_at).format('DD/MM/YYYY') + ' - ' + dayjs(message.created_at).format('HH:mm')}{' '}
                  </div>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        ) : (
          <Grid2 container sx={{ height: '100%' }} justifyContent={'right'}>
            <Grid2
              sx={{
                mt: '20px',
                mr: '10px',
                backgroundColor: isCurrentUser ? '#152944' : '#f0f0f0',
                borderRadius: '24px 4px 24px 24px',
                color: isCurrentUser ? 'white' : 'black',
                padding: '10px'
              }}
              size={9}
            >
              {message.content} <br></br>
              {message?.files && message?.files.length > 0 && (
                <div className="preview-container" style={styles.previewContainer}>
                  {message?.files?.map((file, index) => (
                    <div key={index + file.uuid} className="preview-item" style={styles.previewItem}>
                      <CancelIcon
                        onClick={() => {
                          setFileToSuppress(file);
                          setSuppressionType('file');
                          handleOpenDeleteDialog();
                        }}
                        sx={{
                          cursor: 'pointer',
                          top: '-5px',
                          right: '-5px',
                          position: 'absolute',
                          margin: 0,
                          padding: 0,
                          backgroundColor: 'black',
                          borderRadius: '50%'
                        }}
                      />
                      <img
                        src={process.env.REACT_APP_BASEURL_MEDIAS + '/' + file.uri}
                        alt={file.name}
                        style={styles.image}
                        onClick={() => {
                          setSelectedImage(index);
                          handleOpen(index);
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
              <Grid2 container sx={{ pt: '10px', height: '30px' }}>
                <Grid2 size={'auto'} sx={{ alignContent: 'center' }}>
                  <div style={{ fontSize: '10px', textAlign: 'right', marginTop: '5px' }}>
                    {dayjs(message.created_at).format('DD/MM/YYYY') + ' - ' + dayjs(message.created_at).format('HH:mm')}{' '}
                  </div>
                </Grid2>
                <Grid2 size={'grow'} sx={{ textAlign: 'right' }}>
                  <DeleteIcon
                    sx={{ cursor: 'pointer', fontSize: '20px' }}
                    onClick={() => {
                      setSuppressionType('message');
                      handleOpenDeleteDialog();
                    }}
                  ></DeleteIcon>
                  <AttachFileIcon
                    sx={{ cursor: 'pointer', fontSize: '20px' }}
                    onClick={() => {
                      document.getElementById('fileInput-' + message.uuid).click();
                    }}
                  ></AttachFileIcon>
                </Grid2>
              </Grid2>
            </Grid2>

            <Grid2 size={'auto'} sx={{ height: '100%' }} justifyContent={'space-between'}>
              <Grid2 container flexDirection={'column'}>
                <Grid2 size={'auto'}>
                  <Avatar
                    {...stringAvatar(message.creator_name)}
                    src={
                      userFullData && userFullData.avatar_image_uri
                        ? process.env.REACT_APP_BASEURL_MEDIAS + '/' + userFullData.avatar_image_uri
                        : ''
                    }
                  />
                </Grid2>
              </Grid2>
              <Grid2></Grid2>
            </Grid2>
          </Grid2>
        )}
      </Grid2>

      <ConfirmationDialog
        message={
          suppressionType === 'message'
            ? 'Etes vous certain de vouloir supprimer définitivement ce message'
            : 'Etes vous certain de vouloir supprimer définitivement ce fichier'
        }
        onCancel={handleCloseDeleteDialog}
        onConfirm={confirmDeleteMessage}
        open={deleteDialogOpen}
        title={'Suppression'}
        variant={'error'}
        cancelText="Non"
        confirmText="Oui"
      />

      <Modal
        open={open}
        onClose={handleClose}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          style={{ position: 'relative', maxWidth: '90%', maxHeight: '90%', borderRadius: '20px', overflow: 'hidden' }}
          {...useSwipeable({
            onSwipedLeft: handleNext,
            onSwipedRight: handlePrev
          })}
        >
          {selectedImageIndex !== null && (
            <>
              {images.length > 1 && (
                <IconButton
                  onClick={handlePrev}
                  style={{
                    position: 'absolute',
                    left: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'rgba(0,0,0,0.5)'
                  }}
                >
                  <ArrowBackIosIcon style={{ color: 'white' }} />
                </IconButton>
              )}
              <img
                src={`${process.env.REACT_APP_BASEURL_MEDIAS}/${images[selectedImageIndex].uri}`}
                alt="Equipment"
                style={{ width: '100%', height: 'auto', maxHeight: '90vh' }}
                onClick={() => {
                  setSelectedImage(index);
                  handleOpen(index);
                }}
              />
              {images.length > 1 && (
                <IconButton
                  onClick={handleNext}
                  style={{
                    position: 'absolute',
                    right: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'rgba(0,0,0,0.5)'
                  }}
                >
                  <ArrowForwardIosIcon style={{ color: 'white' }} />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Modal>
    </Grid2>
  );
};
