import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Button, Card, CardActions, CardHeader, Chip, Link, Stack, Grid2 } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { deleteContact } from '~/api';
import { WizzardContact } from '~/app/components/WizzardContact/WizzardContact';
import { ConfirmationDialog } from '~/app/components/ConfirmationDialog/ConfirmationDialog';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Typography } from '@mui/material';

export const ContactBlock = ({ contact, setUpdateContact, contacts, setContacts }) => {
  const [openWizzardContact, setOpenWizzardContact] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDeleteContact = () => {
    deleteContact(contact.uuid)
      .then((resp) => {
        setUpdateContact(true);
        setEditMode(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleWebsite = () => {
    if (contact.data.website.includes('http://') || contact.data.website.includes('https://')) {
      return contact.data.website;
    } else if (contact.data.website) {
      return `https://${contact.data.website}`;
    }
  };

  return (
    <div>
      <Card
        variant="outlined"
        sx={{
          width: '275px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
          my: '16px',
          ml: '16px'
        }}
      >
        <CardHeader
          avatar={<PersonOutlineIcon />}
          title={
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {contact.name}
            </Typography>
          }
        />
        <Grid2 container spacing={2} sx={{ padding: '16px' }}>
          {contact.email && (
            <Grid2 size={12}>
              <Typography variant="body2" sx={{ display: 'inline-block', marginRight: '8px' }}>
                Email :
              </Typography>
              <Typography
                variant="body2"
                sx={{ display: 'inline-block', fontWeight: 'bold', color: 'primary', whiteSpace: 'nowrap' }}
              >
                {contact.email}
              </Typography>
            </Grid2>
          )}
          {contact.phone1 && (
            <Grid2 size={12}>
              <Typography variant="body2" sx={{ display: 'inline-block', marginRight: '8px' }}>
                N°:
              </Typography>
              <Typography variant="body2" sx={{ display: 'inline-block', fontWeight: 'bold', color: 'primary' }}>
                {contact.phone1}
              </Typography>
            </Grid2>
          )}
          {contact.keywords && (
            <Grid2 size={12}>
              <Stack direction="row" spacing={1}>
                {contact.keywords &&
                  contact.keywords.map((keyword) => (
                    <Chip
                      key={keyword}
                      label={keyword}
                      color="primary"
                      size="small"
                      style={{ fontSize: '11px', padding: '1px' }}
                    />
                  ))}
              </Stack>
            </Grid2>
          )}
          {contact.company_name && (
            <Grid2 size={12}>
              <Typography variant="body2" sx={{ display: 'inline-block', marginRight: '8px' }}>
                Société :
              </Typography>
              <Typography variant="body2" sx={{ display: 'inline-block', fontWeight: 'bold', color: 'primary' }}>
                {contact.company_name}
              </Typography>
            </Grid2>
          )}
          {contact.job_title && (
            <Grid2 size={12}>
              <Typography variant="body2" sx={{ display: 'inline-block', marginRight: '8px' }}>
                Fonction :
              </Typography>
              <Typography variant="body2" sx={{ display: 'inline-block', fontWeight: 'bold', color: 'primary' }}>
                {contact.job_title}
              </Typography>
            </Grid2>
          )}
          {contact.phone2 && (
            <Grid2 size={12}>
              <Typography variant="body2" sx={{ display: 'inline-block', marginRight: '8px' }}>
                N° :
              </Typography>
              <Typography variant="body2" sx={{ display: 'inline-block', fontWeight: 'bold', color: 'primary' }}>
                {contact.phone2}
              </Typography>
            </Grid2>
          )}
          {contact.data && contact.data.comment && (
            <Grid2 size={12}>
              <Typography variant="body2" sx={{ display: 'inline-block', marginRight: '8px' }}>
                Commentaire :
              </Typography>
              <Typography
                variant="body2"
                sx={{ display: 'inline-block', color: 'primary', fontWeight: 'bold', wordBreak: 'break-all' }}
              >
                {contact.data.comment}
              </Typography>
            </Grid2>
          )}

          {contact.data && contact.data.website && (
            <Grid2 size={12}>
              <Typography variant="body2" sx={{ display: 'inline-block', fontWeight: 'bold', marginRight: '8px' }}>
                Site Web :
              </Typography>
              <Typography
                variant="body2"
                sx={{ display: 'inline-block', color: 'primary', fontWeight: 'bold', wordBreak: 'break-all' }}
              >
                <Link href={handleWebsite()} target="_blank" rel="noopener">
                  {contact.data.website}
                </Link>
              </Typography>
            </Grid2>
          )}
        </Grid2>
        <CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}>
          {!editMode && (
            <>
              <Button size="small" variant="text" onClick={() => setOpenWizzardContact(true)}>
                <EditOutlinedIcon />
              </Button>
              <IconButton
                size="small"
                aria-label="supprimer le contact"
                component="span"
                onClick={() => setDeleteDialogOpen(true)}
              >
                <DeleteOutlineOutlinedIcon sx={{ color: 'orange' }} />
              </IconButton>
              <ConfirmationDialog
                message={'Etes-vous certain de vouloir supprimer ce contact ?'}
                onCancel={() => {
                  setDeleteDialogOpen(false);
                  setEditMode(false);
                }}
                onConfirm={handleDeleteContact}
                open={deleteDialogOpen}
                title={'Suppression'}
                variant={'error'}
              />
            </>
          )}
        </CardActions>
      </Card>
      {openWizzardContact && (
        <WizzardContact
          contact={contact}
          contacts={contacts}
          onClose={() => {
            setOpenWizzardContact(false);
            setEditMode(false);
            setUpdateContact(true);
          }}
          open={openWizzardContact}
          isNewContact={false}
          isProductPage={false}
          setContacts={setContacts}
          setUpdateContact={setUpdateContact}
          isEdit={true}
        />
      )}
    </div>
  );
};

export default ContactBlock;
