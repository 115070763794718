import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Stack, Grid2, Typography, IconButton, Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import { deleteContact } from '~/api';
import { WizzardContact } from '~/app/components/WizzardContact/WizzardContact';
import { ConfirmationDialog } from '~/app/components/ConfirmationDialog/ConfirmationDialog';

export const ContactLine = ({ contact, setUpdateContact, contacts, setContacts }) => {
  const [openWizzardContact, setOpenWizzardContact] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDeleteContact = () => {
    deleteContact(contact.uuid)
      .then((resp) => {
        setUpdateContact(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        onCloseAll();
      });
  };

  const handleEditClick = () => {
    setOpenWizzardContact(true);
  };

  const onCloseAll = () => {
    setOpenWizzardContact(false);
    setDeleteDialogOpen(false);
  };

  return (
    <>
      <Grid2
        container
        spacing={2}
        sx={{ borderBottom: '1px solid #ccc', width: '100%', cursor: 'pointer' }}
        onClick={(e) => {
          // Vérifier si le clic vient du bouton delete
          if (deleteDialogOpen) return;
          e.stopPropagation();
          handleEditClick();
        }}
      >
        <Grid2 size={12}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ padding: '16px', borderBottom: '1px solid #ccc' }}
          >
            <Grid2 size={2}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                <span style={{ fontWeight: 'bold', color: 'primary' }}>{contact.name}</span>
              </Typography>
            </Grid2>
            <Grid2 size={2.5}>
              {contact.email && (
                <Typography variant="body2">
                  <span style={{ fontWeight: 'bold', color: 'primary' }}>{contact.email}</span>
                </Typography>
              )}
            </Grid2>
            <Grid2 size={1.5}>
              {contact.phone1 && (
                <Typography variant="body2">
                  <span style={{ fontWeight: 'bold', color: 'primary' }}>{contact.phone1}</span>
                </Typography>
              )}
            </Grid2>
            <Grid2 size={1.5}>
              {contact.company_name && (
                <Typography variant="body2">
                  <span style={{ fontWeight: 'bold', color: 'primary' }}>{contact.company_name}</span>
                </Typography>
              )}
            </Grid2>
            <Grid2 size={1.5}>
              {contact.job_title && (
                <Typography variant="body2">
                  <span style={{ fontWeight: 'bold', color: 'primary' }}>{contact.job_title}</span>
                </Typography>
              )}
            </Grid2>
            <Grid2 size={2.5}>
              {contact.keywords && (
                <Stack direction="row" spacing={1}>
                  {contact.keywords.map((keyword) => (
                    <Chip
                      key={keyword}
                      label={keyword}
                      color="primary"
                      size="small"
                      style={{ fontSize: '11px', padding: '1px' }}
                    />
                  ))}
                </Stack>
              )}
            </Grid2>
            <Grid2 size={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                size="small"
                aria-label="supprimer le contact"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteDialogOpen(true);
                }}
              >
                <DeleteOutlineOutlinedIcon sx={{ color: 'orange' }} />
              </IconButton>
            </Grid2>
          </Stack>
        </Grid2>
        <ConfirmationDialog
          message={'Etes-vous certain de vouloir supprimer ce contact ?'}
          onCancel={onCloseAll}
          onConfirm={handleDeleteContact}
          open={deleteDialogOpen}
          title={'Suppression'}
          variant={'error'}
        />
      </Grid2>
      {openWizzardContact && (
        <WizzardContact
          contact={contact}
          contacts={contacts}
          onClose={() => {
            setOpenWizzardContact(false);
          }}
          open={openWizzardContact}
          isNewContact={false}
          isProductPage={false}
          setContacts={setContacts}
          setUpdateContact={setUpdateContact}
          isEdit={true}
        />
      )}
    </>
  );
};

export default ContactLine;
