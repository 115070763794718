import _ from 'lodash';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export const getEquipmentByTagUuid = (equipments, tagUuid) => {
  let equipmentToReturn = null;

  equipments.forEach((equipment) => {
    if (equipment.tags && equipment.tags.length > 0) {
      equipment.tags.forEach((tag) => {
        if (tag.uuid === tagUuid) {
          equipmentToReturn = equipment;
        }
      });
    }
    if (equipment.states) {
      equipment.states.forEach((state) => {
        if (state.tags && state.tags.length > 0) {
          state.tags.forEach((tag) => {
            if (tag.uuid === tagUuid) {
              equipmentToReturn = equipment;
            }
          });
        }
      });
    }
  });
  return equipmentToReturn;
};

export const getIcon = (type: any): JSX.Element => {
  switch (type) {
    case 5:
      return <LockOutlinedIcon />;
    case 4:
      return <CheckOutlinedIcon />;
    case 3:
      return <LockOpenOutlinedIcon />;
    default:
      return <ClearOutlinedIcon />;
  }
};

export const getVariant = (type: any): 'error' | 'success' | 'primary' | 'secondary' | 'warning' => {
  switch (type) {
    case 1:
      return 'success';
    case 2:
      return 'error';
    case 3:
      return 'warning';
    case 4:
      return 'success';
    case 5:
      return 'secondary';
    default:
      return 'success';
  }
};

export const getStatusWordingState = (type: any) => {
  switch (type) {
    case 2:
      return 'Fonctionnel';
    case 3:
      return 'Non fonctionnel';
    case 4:
      return 'Non vérifiable';
    case 1:
      return 'Autre';
  }
};

export const getIconState = (type: any): JSX.Element => {
  switch (type) {
    case 2:
      return <CheckOutlinedIcon />;
    case 3:
      return <LockOpenOutlinedIcon />;
    case 4:
      return <LockOutlinedIcon />;
    case 5:
      return <LockOutlinedIcon />;
    default:
      return null;
  }
};

export const getVariantState = (type: any): 'error' | 'success' | 'primary' | 'secondary' | 'warning' => {
  switch (type) {
    case 2:
      return 'success';
    case 3:
      return 'error';
    case 4:
      return 'warning';
    case 1:
      return 'secondary';
  }
};

export const getStatusWording = (type: any) => {
  switch (type) {
    case 1:
      return 'A qualifier';
    case 2:
      return 'A qualifier';
    case 3:
      return 'En cours';
    case 4:
      return 'Terminé';
    case 5:
      return 'Archivé';
  }
};

export const getEventByTagUuid = (events, tagUuid) => {
  let eventToReturn = null;

  events.forEach((event) => {
    if (event.tags && event.tags.length > 0) {
      event.tags.forEach((tag) => {
        if (tag.uuid === tagUuid) {
          eventToReturn = event;
        }
      });
    }
  });
  return eventToReturn;
};

export const getTagForEvent = (event) => {
  let tag = null;
  if (event?.tags && event?.tags?.length > 0) {
    tag = event.tags[0];
  }
  return tag;
};

export const getTagPinPoint = (equipment) => {
  let tag = null;

  if (equipment?.tags && equipment?.tags.length > 0) {
    tag = equipment.tags[0];
  }

  if (equipment?.states && equipment?.states.length > 0) {
    equipment?.states.forEach((state) => {
      if (state.tags && state.tags[0]) {
        tag = state.tags[0];
      }
    });
  }

  if (tag !== null) {
    return (
      <svg
        style={{ cursor: 'pointer' }}
        width="15"
        height="25"
        viewBox="0 0 20 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_9924_7990)">
          <path
            d="M10 0.714355C4.47143 0.714355 0 5.18578 0 10.7144C0 18.2144 10 29.2858 10 29.2858C10 29.2858 20 18.2144 20 10.7144C20 5.18578 15.5286 0.714355 10 0.714355ZM10 14.2858C8.02857 14.2858 6.42857 12.6858 6.42857 10.7144C6.42857 8.74293 8.02857 7.14293 10 7.14293C11.9714 7.14293 13.5714 8.74293 13.5714 10.7144C13.5714 12.6858 11.9714 14.2858 10 14.2858Z"
            fill="#F17633"
          />
        </g>
        <defs>
          <clipPath id="clip0_9924_7990">
            <rect width="20" height="28.5714" fill="white" transform="translate(0 0.714355)" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return null; // <svg width="15" height="25" viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>;
  }
};

export const getTagForEquipment = (equipment) => {
  let tag = null;
  if (equipment?.tags && equipment?.tags.length > 0) {
    tag = equipment.tags[0];
  }

  let lastState = null;

  if (equipment?.states && equipment?.states.length > 0) {
    lastState = _.maxBy(equipment?.states, 'created_at');
  }

  if (lastState && lastState.tags && lastState.tags.length > 0) {
    tag = lastState.tags[0];
  }
  return tag;
};

export const getTagsForEquipmentsList = (equipments) => {
  const tags = [];
  if (equipments && equipments.length > 0)
    equipments.forEach((equipment) => {
      const tag = getTagForEquipment(equipment);
      if (tag) {
        tags.push(tag);
      }
    });
  return tags;
};

export const iconSortingUp = () => {
  return (
    <svg
      style={{ transform: 'rotate(180deg)' }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z" fill="black" />
    </svg>
  );
};

export const iconSortingDown = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z" fill="black" />
    </svg>
  );
};

export const iconNoSorting = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0002 7.83L15.1702 11L16.5802 9.59L12.0002 5L7.41016 9.59L8.83016 11L12.0002 7.83ZM12.0002 16.17L8.83016 13L7.42016 14.41L12.0002 19L16.5902 14.41L15.1702 13L12.0002 16.17Z"
        fill="black"
      />
    </svg>
  );
};

export const iconFilters = (color) => {
  return (
    <svg width="22" height="19" viewBox="0 0 22 19" fill="red" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5374 3.28714H6.57106C6.35317 3.28714 6.14421 3.20058 5.99014 3.04651C5.83607 2.89244 5.74951 2.68348 5.74951 2.46559C5.74951 2.2477 5.83607 2.03874 5.99014 1.88467C6.14421 1.7306 6.35317 1.64404 6.57106 1.64404H20.5374C20.7552 1.64404 20.9642 1.7306 21.1183 1.88467C21.2724 2.03874 21.3589 2.2477 21.3589 2.46559C21.3589 2.68348 21.2724 2.89244 21.1183 3.04651C20.9642 3.20058 20.7552 3.28714 20.5374 3.28714Z"
        fill={color}
      />
      <path
        d="M3.28619 3.28714H0.821547C0.603659 3.28714 0.394696 3.20058 0.240626 3.04651C0.0865556 2.89244 0 2.68348 0 2.46559C0 2.2477 0.0865556 2.03874 0.240626 1.88467C0.394696 1.7306 0.603659 1.64404 0.821547 1.64404H3.28619C3.50408 1.64404 3.71304 1.7306 3.86711 1.88467C4.02118 2.03874 4.10774 2.2477 4.10774 2.46559C4.10774 2.68348 4.02118 2.89244 3.86711 3.04651C3.71304 3.20058 3.50408 3.28714 3.28619 3.28714Z"
        fill={color}
      />
      <path
        d="M14.7878 9.8594H0.821547C0.603659 9.8594 0.394696 9.77285 0.240626 9.61878C0.0865556 9.46471 0 9.25574 0 9.03786C0 8.81997 0.0865556 8.611 0.240626 8.45693C0.394696 8.30286 0.603659 8.21631 0.821547 8.21631H14.7878C15.0057 8.21631 15.2147 8.30286 15.3688 8.45693C15.5228 8.611 15.6094 8.81997 15.6094 9.03786C15.6094 9.25574 15.5228 9.46471 15.3688 9.61878C15.2147 9.77285 15.0057 9.8594 14.7878 9.8594Z"
        fill={color}
      />
      <path
        d="M6.57238 16.4312H0.821547C0.603659 16.4312 0.394695 16.3446 0.240626 16.1906C0.0865556 16.0365 0 15.8275 0 15.6096C0 15.3917 0.0865556 15.1828 0.240626 15.0287C0.394695 14.8746 0.603659 14.7881 0.821547 14.7881H6.57238C6.79026 14.7881 6.99923 14.8746 7.1533 15.0287C7.30737 15.1828 7.39392 15.3917 7.39392 15.6096C7.39392 15.8275 7.30737 16.0365 7.1533 16.1906C6.99923 16.3446 6.79026 16.4312 6.57238 16.4312Z"
        fill={color}
      />
      <path
        d="M4.92851 4.92928C4.44105 4.92928 3.96454 4.78473 3.55923 4.51392C3.15392 4.2431 2.83802 3.85817 2.65148 3.40782C2.46494 2.95746 2.41613 2.46191 2.51123 1.98381C2.60632 1.50572 2.84106 1.06656 3.18574 0.721878C3.53043 0.377191 3.96959 0.142457 4.44768 0.0473585C4.92578 -0.0477402 5.42133 0.00106789 5.87169 0.187611C6.32204 0.374154 6.70697 0.690053 6.97778 1.09536C7.2486 1.50067 7.39315 1.97718 7.39315 2.46464C7.39315 3.11831 7.13348 3.7452 6.67127 4.20741C6.20906 4.66962 5.58217 4.92928 4.92851 4.92928ZM4.92851 1.6431C4.76602 1.6431 4.60718 1.69128 4.47208 1.78155C4.33698 1.87182 4.23168 2.00013 4.1695 2.15025C4.10732 2.30037 4.09105 2.46555 4.12275 2.62492C4.15445 2.78428 4.23269 2.93067 4.34759 3.04556C4.46248 3.16046 4.60887 3.2387 4.76823 3.2704C4.9276 3.3021 5.09278 3.28583 5.2429 3.22365C5.39302 3.16147 5.52133 3.05617 5.6116 2.92107C5.70187 2.78597 5.75006 2.62713 5.75006 2.46464C5.75006 2.24675 5.6635 2.03779 5.50943 1.88372C5.35536 1.72965 5.1464 1.6431 4.92851 1.6431Z"
        fill={color}
      />
      <path
        d="M16.4305 11.5011C15.943 11.5011 15.4665 11.3565 15.0612 11.0857C14.6559 10.8149 14.34 10.43 14.1534 9.9796C13.9669 9.52924 13.9181 9.03369 14.0132 8.55559C14.1083 8.0775 14.343 7.63834 14.6877 7.29365C15.0324 6.94897 15.4715 6.71423 15.9496 6.61914C16.4277 6.52404 16.9233 6.57285 17.3736 6.75939C17.824 6.94593 18.2089 7.26183 18.4797 7.66714C18.7506 8.07245 18.8951 8.54896 18.8951 9.03642C18.8951 9.69008 18.6354 10.317 18.1732 10.7792C17.711 11.2414 17.0841 11.5011 16.4305 11.5011ZM16.4305 8.21487C16.268 8.21487 16.1091 8.26306 15.974 8.35333C15.8389 8.4436 15.7336 8.57191 15.6715 8.72203C15.6093 8.87214 15.593 9.03733 15.6247 9.1967C15.6564 9.35606 15.7346 9.50245 15.8495 9.61734C15.9644 9.73224 16.1108 9.81048 16.2702 9.84218C16.4296 9.87388 16.5947 9.85761 16.7449 9.79543C16.895 9.73325 17.0233 9.62795 17.1136 9.49285C17.2038 9.35774 17.252 9.19891 17.252 9.03642C17.252 8.81853 17.1655 8.60957 17.0114 8.4555C16.8573 8.30143 16.6484 8.21487 16.4305 8.21487Z"
        fill={color}
      />
      <path
        d="M8.21415 18.0738C7.72669 18.0738 7.25018 17.9293 6.84487 17.6584C6.43956 17.3876 6.12367 17.0027 5.93712 16.5524C5.75058 16.102 5.70177 15.6064 5.79687 15.1283C5.89197 14.6503 6.1267 14.2111 6.47139 13.8664C6.81608 13.5217 7.25523 13.287 7.73333 13.1919C8.21142 13.0968 8.70698 13.1456 9.15733 13.3321C9.60769 13.5187 9.99261 13.8346 10.2634 14.2399C10.5342 14.6452 10.6788 15.1217 10.6788 15.6092C10.6788 16.2628 10.4191 16.8897 9.95692 17.3519C9.49471 17.8141 8.86782 18.0738 8.21415 18.0738ZM8.21415 14.7876C8.05167 14.7876 7.89283 14.8358 7.75773 14.9261C7.62262 15.0164 7.51732 15.1447 7.45514 15.2948C7.39296 15.4449 7.37669 15.6101 7.40839 15.7695C7.44009 15.9288 7.51834 16.0752 7.63323 16.1901C7.74813 16.305 7.89451 16.3832 8.05388 16.4149C8.21324 16.4466 8.37843 16.4304 8.52855 16.3682C8.67867 16.306 8.80697 16.2007 8.89725 16.0656C8.98752 15.9305 9.0357 15.7717 9.0357 15.6092C9.0357 15.3913 8.94915 15.1823 8.79508 15.0283C8.64101 14.8742 8.43204 14.7876 8.21415 14.7876Z"
        fill={color}
      />
      <path
        d="M20.5381 9.8594H18.0735C17.8556 9.8594 17.6466 9.77285 17.4926 9.61878C17.3385 9.46471 17.252 9.25574 17.252 9.03786C17.252 8.81997 17.3385 8.611 17.4926 8.45693C17.6466 8.30286 17.8556 8.21631 18.0735 8.21631H20.5381C20.756 8.21631 20.965 8.30286 21.1191 8.45693C21.2731 8.611 21.3597 8.81997 21.3597 9.03786C21.3597 9.25574 21.2731 9.46471 21.1191 9.61878C20.965 9.77285 20.756 9.8594 20.5381 9.8594Z"
        fill={color}
      />
      <path
        d="M20.5378 16.4312H9.85768C9.63979 16.4312 9.43083 16.3446 9.27676 16.1906C9.12269 16.0365 9.03613 15.8275 9.03613 15.6096C9.03613 15.3917 9.12269 15.1828 9.27676 15.0287C9.43083 14.8746 9.63979 14.7881 9.85768 14.7881H20.5378C20.7557 14.7881 20.9646 14.8746 21.1187 15.0287C21.2728 15.1828 21.3593 15.3917 21.3593 15.6096C21.3593 15.8275 21.2728 16.0365 21.1187 16.1906C20.9646 16.3446 20.7557 16.4312 20.5378 16.4312Z"
        fill={color}
      />
    </svg>
  );
};
