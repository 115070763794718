import ImageGallery from 'react-image-gallery';
import './Gallery.scss';
import './image-gallery.scss';

export const Gallery = ({ images, startIndex }) => {
  const data = [];
  images.forEach((image) => {
    data.push({
      original: `${process.env.REACT_APP_BASEURL_MEDIAS}/${image.uri}`,
      thumbnail: `${process.env.REACT_APP_BASEURL_MEDIAS}/${image.uri}`
    });
  });

  return (
    <div className="Gallery">
      <ImageGallery
        // originalHeight="100dvh"
        originalHeight="100vh"
        originalWidth="100vw"
        items={data}
        showBullets={true}
        showFullscreenButton={false}
        showPlayButton={false}
        startIndex={startIndex}
      />
    </div>
  );
};

export default Gallery;
