import { useRef, useEffect, useState } from 'react';

export const PlaceTagIndicator = ({ placeTagIndicator, pCent, visibility, circleIndicatorBackGround }) => {
  const circleIndicatorFill = useRef<SVGCircleElement>(null);
  const [pathLength, setPathLength] = useState(0);

  useEffect(() => {
    if (!circleIndicatorBackGround.current || !circleIndicatorFill.current) return;
    const pathLength = circleIndicatorFill.current.getTotalLength();
    setPathLength(pathLength);
  }, [circleIndicatorFill]);

  useEffect(() => {
    if (!circleIndicatorFill.current) return;
    circleIndicatorFill.current.style.strokeDashoffset = `${pathLength - (pathLength * pCent) / 100}`;
  }, [pCent]);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={placeTagIndicator}
      id="circleIndicator"
      version="1.1"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        transform: 'rotate(-90deg)',
        zIndex: 1000
      }}
      width="125"
      height={125}
      viewBox="0 0 250 250"
      visibility={visibility}
    >
      <circle cx="125" cy="125" r="108" stroke="grey" strokeWidth="26" fill="none" />
      <circle
        //   ref={circleIndicatorGrey}
        cx="125"
        cy="125"
        r="108"
        stroke="rgb(21, 41, 68)"
        strokeWidth="24"
        fill="none"
        // visibility="hidden"
      />
      <circle
        ref={circleIndicatorBackGround}
        id="circleIndicatorBackGround"
        cx="125"
        cy="125"
        r="98"
        strokeWidth="0"
        fill="white"
        opacity={0.1}
      />
      <circle
        ref={circleIndicatorFill}
        cx="125"
        cy="125"
        r="108"
        stroke="#F17633"
        strokeWidth="20"
        fill="none"
        strokeDasharray={pathLength}
        strokeDashoffset={pathLength}
      />
    </svg>
  );
};
