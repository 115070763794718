import { useEffect, useState, useRef, useContext } from 'react';
import {
  Box,
  Button,
  Grid2,
  Typography,
  Modal,
  Backdrop,
  Fade,
  IconButton,
  InputBase,
  Pagination,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { appTheme } from '~/utils/theme';

import Breadcrumb from '~/app/components/Breadcrumb/Breadcrumb';

import { WizzardMissionOrderForm } from '~/app/components/WizzardMissionOrder/WizzardMissionOrderFom';
import { WizzardMissionOrderRecap } from '~/app/components/WizzardMissionOrder/WizzardMissionOrderRecap';

import { useLocation } from 'react-router-dom';
import { api } from '~/api';

import '../../components/DocumentsList/DocumentsList.scss';
import normalizedString from '~/utils/normalizedString';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import { MissionOrderItem } from '~/app/components/WizzardMissionOrder/MissionOrderItem';

export const MissionOrder = ({ spinnerOpen, setSpinnerOpen }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [odms, setOdms] = useState([]);
  const [filteredOdm, setFilteredOdm] = useState([]);
  const [isSummary, _setIsSummary] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [selectFinished, setSelectFinished] = useState(false);
  const [selectInProgress, setSelectInProgress] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false); // mise a jour de la liste des odm qd on planifie le rdv pour le scan

  const [missionOrderSelected, setMissionOrderSelected] = useState(null);
  const mediaQueries = useContext(MediaQueryContext);

  const refIsSummary = useRef(isSummary);

  const setIsSummary = (data) => {
    refIsSummary.current = data;
    _setIsSummary(data);
  };

  const handleSelectFinished = (event) => {
    setSelectFinished(event.target.checked);
  };
  const handleSelectInProgress = (event) => {
    setSelectInProgress(event.target.checked);
  };

  useEffect(() => {
    api.missionOrders
      .search()
      .then((response) => {
        const reverseResponse = response.reverse();
        setOdms(reverseResponse);
        if (searchInput.length > 0) {
          doTextSearch();
        } else {
          setFilteredOdm(reverseResponse);
        }
      })
      .catch((error) => {});
  }, [open, isDeleted, isUpdated]);

  useEffect(() => {
    if (searchInput.length > 0) {
      doTextSearch();
    } else {
      setFilteredOdm(odms);
    }
  }, [searchInput]);

  const statusSearch = () => {
    if (selectFinished && selectInProgress) {
      setFilteredOdm(odms);
    } else if (selectFinished) {
      const filteredOdms = odms.filter((odm) => {
        if (odm.kind === 1) {
          return odm.edl?.status === 4;
        } else {
          return odm.scan?.status === 2;
        }
      });
      setFilteredOdm(filteredOdms);
    } else if (selectInProgress) {
      const filteredOdms = odms.filter((odm) => {
        if (odm.kind === 1) {
          return odm.edl?.status !== 4;
        } else {
          return odm.scan?.status !== 2;
        }
      });
      setFilteredOdm(filteredOdms);
    } else {
      setFilteredOdm(odms);
    }
  };

  useEffect(() => {
    statusSearch();
  }, [selectFinished, selectInProgress]);

  const doTextSearch = () => {
    if (searchInput.length > 0) {
      const filteredOdms = filteredOdm.filter((odm) => {
        const normalizedSearchInput = normalizedString(searchInput);

        const matchesSearchInput = (str) => {
          if (str === null || str === undefined) {
            return false;
          } else {
            return normalizedString(str).includes(normalizedSearchInput);
          }
        };

        return (
          matchesSearchInput(odm?.ref_internal) ||
          matchesSearchInput(odm?.data?.address?.street) ||
          matchesSearchInput(odm?.data?.address?.country) ||
          matchesSearchInput(odm?.data.address?.city) ||
          matchesSearchInput(odm?.data.address?.zip_code) ||
          matchesSearchInput(odm?.data.address?.building_infos?.name) ||
          (odm?.data?.property && matchesSearchInput(odm?.data.property?.address_complement)) ||
          (odm?.user_in_charge && matchesSearchInput(odm?.user_in_charge?.first_name)) ||
          (odm?.user_in_charge && matchesSearchInput(odm?.user_in_charge?.last_name))
        );
      });

      setFilteredOdm(filteredOdms);
    } else {
      setFilteredOdm(odms);
    }
  };

  useEffect(() => {
    if (location?.state?.data) {
      setOpen(true);
    }
    setSpinnerOpen(false);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(!open);
    if (location?.state?.data) location.state.data = null;
  };

  const handleIsSummary = () => {
    setIsSummary(!isSummary);
  };

  const [page, setPage] = useState(1);
  const pageSize = mediaQueries.matchesMd ? 5 : 3; // Nombre d'éléments par page

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Pagination des données filtrées
  const paginatedOdm = filteredOdm.slice((page - 1) * pageSize, page * pageSize);

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Typography
          variant={mediaQueries.matchesXl ? 'h1' : mediaQueries.matchesLg ? 'h2' : mediaQueries.matchesMd ? 'h3' : 'h4'}
          sx={{ color: 'primary.main', paddingTop: '15px' }}
        >
          Ordres de services
        </Typography>
        <Breadcrumb
          data={[{ label: 'Accueil', href: '/' }]}
          last={`Ordres de services`}
          setSpinnerOpen={setSpinnerOpen}
        />
      </Grid2>
      <Grid2 size={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          color="secondary"
          variant="outlined"
          sx={{ color: 'secondary', borderRadius: '20px', paddingTop: '4px', borderColor: 'secondary.main' }}
          onClick={handleOpen}
          size="small"
        >
          <AddOutlinedIcon fontSize="small" style={{ paddingBottom: '2px' }} />
          <span style={{ fontSize: '15px' }}>&nbsp; Nouvelle demande</span>
        </Button>
      </Grid2>
      <Grid2 size={12}>
        <Typography variant="h5" sx={{ color: 'primary.main' }}>
          Tous les ordres de services
        </Typography>
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 12, md: 9 }} sx={{ display: 'flex' }}>
        <div
          style={{
            alignItems: 'center',
            backgroundColor: appTheme.palette.primary[50],
            border: `1px solid`,
            borderColor: appTheme.palette.primary[100],
            borderRadius: '20px',
            display: 'flex',
            height: '36px',
            fontSize: '16px',
            width: '85%'
          }}
        >
          <InputBase
            autoComplete="off"
            placeholder="Rechercher"
            inputProps={{
              sx: {
                pl: 1.8
              }
            }}
            sx={{ flex: 1 }}
            onChange={(e) => {
              const input = normalizedString(e.target.value);
              setSearchInput(input);
            }}
          />
          <SearchOutlinedIcon
            sx={{
              color: 'primary.main',
              ml: 2,
              mr: 1
            }}
            fontSize="medium"
          />
        </div>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 2 }} sx={{ display: 'flex', flexDirection: 'row' }}>
        <FormGroup sx={{ display: 'flex', flexDirection: 'row', ml: '0.5vw' }}>
          <FormControlLabel
            control={<Checkbox checked={selectFinished} onChange={handleSelectFinished} />}
            label="Terminés"
          />
          <FormControlLabel
            control={<Checkbox checked={selectInProgress} onChange={handleSelectInProgress} />}
            label="En cours"
          />
        </FormGroup>
      </Grid2>
      <Grid2 size={12}>
        <div style={{ width: '100%' }}>
          {paginatedOdm.length !== 0 &&
            paginatedOdm.map((odm, index) => (
              <MissionOrderItem
                key={odm.uuid}
                missionOrder={odm}
                missionOrderSelected={missionOrderSelected}
                setMissionOrderSelected={setMissionOrderSelected}
                isLast={index === paginatedOdm.length - 1}
                openSummary={handleIsSummary}
              />
            ))}
        </div>
      </Grid2>
      {filteredOdm.length > pageSize && (
        <Grid2
          size={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: mediaQueries.matchesMd ? 1 : 0,
            mb: mediaQueries.matchesMd ? 0 : 2
          }}
        >
          <Pagination
            count={Math.ceil(filteredOdm.length / pageSize)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Grid2>
      )}
      <Modal open={isSummary} onClose={handleIsSummary}>
        <Box
          sx={{
            position: 'absolute',
            top: '7.5%',
            left: !mediaQueries.matchesMd ? '0%' : '25%',
            width: !mediaQueries.matchesMd ? '88%' : '50%',
            height: !mediaQueries.matchesMd ? '80%' : '75%',
            backgroundColor: 'white',
            borderRadius: '20px',
            p: 3
          }}
        >
          <WizzardMissionOrderRecap
            data={missionOrderSelected}
            close={handleIsSummary}
            setIsDeleted={setIsDeleted}
            setIsUpdated={setIsUpdated}
          />
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '98%',
              height: '100%',
              backgroundColor: 'white',
              p: 3
            }}
          >
            <Grid2 size={12} sx={{ display: 'flex', justifyContent: 'end' }}>
              <IconButton onClick={handleClose} sx={{ mr: mediaQueries?.matchesMd ? '2vw' : '5vw' }}>
                <CloseIcon />
              </IconButton>
            </Grid2>
            <WizzardMissionOrderForm
              setSpinnerOpen={setSpinnerOpen}
              spinnerOpen={spinnerOpen}
              handleClose={handleClose}
              productData={null}
              scanOrder={false}
            />
          </Box>
        </Fade>
      </Modal>
    </Grid2>
  );
};
