import { useContext, useEffect, useState } from 'react';
import { useDialog } from '~/utils/useDialog';
import BasicModal from '~/app/components/BasicModal/BasicModal';
import * as React from 'react';
import { HighlightArea } from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { api, updateFile } from '~/api';
import { Button, Grid2 } from '@mui/material';
import { PDFViewer } from '~/app/components/PDFViewer/PDFViewer';
import DocumentUploader from '~/app/components/WizzardBlocks/DocumentUploader';
import { TypeForm } from '~/app/components/WizzardBlocks/TypeForm';
import { ConfirmationDialog } from '~/app/components/ConfirmationDialog/ConfirmationDialog';
import { DiagnosticForm } from '~/app/components/WizzardBlocks/DiagnosticForm';
import { DatesForm } from '~/app/components/WizzardBlocks/DatesForm';
import { LinkEquipmentForm } from '~/app/components/WizzardBlocks/LinkEquipmentForm';
import { ObjectForm } from '~/app/components/WizzardBlocks/ObjectForm';
import { EDLKindForm } from '~/app/components/WizzardBlocks/EDLKindForm';
import { ConditionForm } from '~/app/components/WizzardBlocks/ConditionForm';
import { IndiceForm } from '~/app/components/WizzardBlocks/IndiceForm';
import { CommentForm } from '~/app/components/WizzardBlocks/CommentForm';
import { AppContext } from '~/utils/context/AppContext';
import { LinkContactForm } from '~/app/components/WizzardBlocks/LinkContactForm';
import '~/app/components/WizzardBlocks/Wizzards.scss';
import { ProductTitle } from '../ProductTitle/ProductTitle';
import { EquipmentSubTitle } from '../EquipmentSubTitle/EquipmentSubTitle';
import RemoveIcon from '@mui/icons-material/Remove';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

var fileExtension = require('file-extension');

interface Note {
  id: number;
  content: string;
  highlightAreas: HighlightArea[];
  quote: string;
}

interface WizzardDocumentProps {
  productDatas: any;
  setWizzardDocumentOpen: (open: boolean) => void;
  documentData: any;
  setDocumentData: any;
  updateSelectedDocumentData: (data: any) => void;
  setSelectedUUIDEquipment: (uuid: string) => void;
  setWizzardEquipmentCreationMode: (mode: boolean) => void;
  setWizzardEquipmentOpen: (open: boolean) => void;
  generateSubtitleEquipment?: any;
  equipmentData?: any;
  equipmentReferential?: any;
}

export const WizzardDocument: React.FC<WizzardDocumentProps> = ({
  productDatas,
  setWizzardDocumentOpen,
  documentData,
  setDocumentData,
  updateSelectedDocumentData,
  setSelectedUUIDEquipment,
  setWizzardEquipmentCreationMode,
  setWizzardEquipmentOpen,
  generateSubtitleEquipment = null,
  equipmentData = null,
  equipmentReferential = null
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const [deleteDialogOpen, handleOpenDeleteDialog, handleCloseDeleteDialog] = useDialog();

  const [fileExt, setFileExt] = useState<string>('');
  const [notes, setNotes] = React.useState<Note[]>([]);
  const [documentTypeLayout, setDocumentTypeLayout] = useState<any>({});
  const [prechekedList, setPrechekedList] = useState<any>([]);
  const noteEles: Map<number, HTMLElement> = new Map();
  const { referencial } = React.useContext(AppContext);

  const headerRef = React.useRef<HTMLDivElement>(null);
  const subtitleRef = React.useRef<HTMLDivElement>(null);

  const [isLandscape, setIsLandscape] = useState(false);

  const [mainOffset, setMainOffset] = useState(0);

  const mediaQueries = useContext(MediaQueryContext);

  useEffect(() => {
    if (documentData !== undefined && documentData !== null && referencial && referencial['document.category']) {
      if (documentData.data === null) documentData.data = {};
      setDocumentTypeLayout(
        referencial['document.category'].find((DocumentType) => DocumentType.name === documentData.category)
      );
      setFileExt(fileExtension(documentData.uri));
      if (documentData.annotation !== undefined && documentData.annotation !== null) {
        setNotes(documentData.annotation.notes);
      }
      api.files.equipmentLink.get(documentData.uuid).then((res) => {
        setPrechekedList(res);
      });
    }
  }, [documentData, referencial]);

  const calculateMainContentSize = () => {
    let offset = 0;
    if (headerRef.current) {
      offset = headerRef.current.clientHeight;
    }
    if (subtitleRef.current) {
      offset += subtitleRef.current.clientHeight;
    }
    offset += 16;
    if (window.innerWidth > window.innerHeight) {
      setIsLandscape(true);
    } else {
      setIsLandscape(false);
    }

    setMainOffset(offset);
  };

  useEffect(() => {
    calculateMainContentSize();
  });

  useEffect(() => {
    calculateMainContentSize();

    window.addEventListener('resize', calculateMainContentSize);

    return () => {
      window.removeEventListener('resize', calculateMainContentSize);
    };
  }, []);

  useEffect(() => {
    if (documentTypeLayout && documentTypeLayout.name !== undefined) {
      const data = { ...documentData.data };
      if (!documentTypeLayout.needEDLKind) {
        delete data.EDLKind;
      }
      if (!documentTypeLayout.needDate && !documentTypeLayout.needDateTime) {
        delete data.date;
      }
      if (!documentTypeLayout.needEndDate) {
        delete data.endDate;
      }
      if (!documentTypeLayout.needObject) {
        delete data.object;
      }
      if (!documentTypeLayout.needCondition) {
        delete data.condition;
      }
      if (!documentTypeLayout.needComment) {
        delete data.comment;
      }
      if (!documentTypeLayout.needIndice) {
        delete data.indice;
      }
      if (documentTypeLayout.name !== 'Diagnostics') {
        if (data.diagnostics !== undefined && data.diagnostics !== null && data.diagnostics.length > 0) {
          delete data.diagnostics;
        }
      } else if (documentTypeLayout.name === 'Diagnostics') {
        if (data.diagnostics === undefined || data.diagnostics === null) {
          data.diagnostics = [];
        }
      }

      setDocumentData({ ...documentData, data: data });
    }
  }, [documentTypeLayout]);

  const deleteDraftEquipment = (): void => {
    api.files.delete(documentData.uuid).then((res) => {
      handleCloseDeleteDialog();
      setModalOpen(false);
      updateSelectedDocumentData(res);
    });
  };

  const modalClosed = () => {
    setWizzardDocumentOpen(false);
  };

  const handleConfirm = () => {
    if (documentData) {
      delete documentData.uri;
      documentData.annotation = notes.length > 0 ? { notes } : null;
      updateFile(documentData.uuid, documentData).then((res) => {
        updateSelectedDocumentData(res);
      });
    }
    setWizzardDocumentOpen(false);
  };

  const handleNextStep = (data) => {
    updateSelectedDocumentData(data);
    setWizzardDocumentOpen(false);
  };

  React.useEffect(() => {
    return () => {
      noteEles.clear();
    };
  }, []);

  return (
    <>
      <BasicModal
        modalOpen={modalOpen}
        onModalOpen={(open: boolean) => setModalOpen(open)}
        onModalClose={modalClosed}
        confirmClose={true}
        handleConfirm={handleConfirm}
      >
        <Grid2 container spacing={0} ref={headerRef} sx={{ position: 'sticky', top: '0', zIndex: 2 }}>
          <div style={{ backgroundColor: '#fff7f3', width: '100vw', paddingLeft: '15px' }}>
            <Grid2 size={12}>
              <ProductTitle data={productDatas} />
            </Grid2>
          </div>
          {generateSubtitleEquipment && equipmentData && equipmentReferential && (
            <Grid2 size={12}>
              <EquipmentSubTitle
                generateSubtitleEquipment={generateSubtitleEquipment}
                equipmentData={equipmentData}
                equipmentReferencial={equipmentReferential}
              />
            </Grid2>
          )}
        </Grid2>
        {documentData && (
          <>
            <Grid2 container spacing={0} style={{ height: `calc(100dvh - ${mainOffset}px)` }}>
              <Grid2 size={12}>
                <Grid2>
                  <div ref={subtitleRef} className="Wizzards__contentTitle Wizzards__center">
                    Editez votre document
                  </div>
                </Grid2>
              </Grid2>

              <Grid2 size={{ xs: 12, md: 8 }}>
                {fileExt === 'pdf' && (
                  <div
                    style={{
                      // width: '100%',
                      height: mediaQueries.matchesMd ? `calc(100dvh - ${mainOffset}px)` : '300px',
                      paddingLeft: '15px',
                      paddingRight: '15px'
                    }}
                  >
                    <PDFViewer documentData={documentData} setNotes={setNotes} notes={notes} />
                  </div>
                )}

                {(fileExt === 'jpg' ||
                  fileExt === 'jpeg' ||
                  fileExt === 'png' ||
                  fileExt === 'JPEG' ||
                  fileExt === 'JPG' ||
                  fileExt === 'PNG') && (
                  <img
                    style={{
                      maxWidth: '100%'
                    }}
                    src={
                      documentData?.uri?.includes('http')
                        ? documentData.uri
                        : process.env.REACT_APP_BASEURL_MEDIAS + '/' + documentData.uri
                    }
                    alt={documentData.name}
                  />
                )}
              </Grid2>

              <Grid2
                size={{ xs: 12, md: 4 }}
                className="hide-scrollbar"
                style={{
                  height: `calc(100% - ${0}px)`,
                  paddingLeft: '30px',
                  paddingRight: '15px',
                  paddingTop: mediaQueries.matchesMd ? '0' : '25px',
                  overflowY: 'scroll'
                }}
              >
                <TypeForm documentData={documentData} setDocumentData={setDocumentData}></TypeForm>
                <DiagnosticForm documentData={documentData} setDocumentData={setDocumentData}></DiagnosticForm>

                <div style={{ paddingBottom: '15px' }}>Informations Complémentaires</div>

                <DatesForm
                  documentData={documentData}
                  setDocumentData={setDocumentData}
                  documentTypeLayout={documentTypeLayout}
                ></DatesForm>
                <LinkEquipmentForm
                  prechekedList={prechekedList}
                  documentData={documentData}
                  setDocumentData={setDocumentData}
                  documentTypeLayout={documentTypeLayout}
                  setWizzardEquipmentOpen={setWizzardEquipmentOpen}
                  setSelectedUUIDEquipment={setSelectedUUIDEquipment}
                  setWizzardEquipmentCreationMode={setWizzardEquipmentCreationMode}
                  productDatas={productDatas}
                ></LinkEquipmentForm>
                <LinkContactForm
                  documentData={documentData}
                  setDocumentData={setDocumentData}
                  documentTypeLayout={documentTypeLayout}
                ></LinkContactForm>
                <ObjectForm
                  documentData={documentData}
                  setDocumentData={setDocumentData}
                  documentTypeLayout={documentTypeLayout}
                ></ObjectForm>
                <EDLKindForm
                  documentData={documentData}
                  setDocumentData={setDocumentData}
                  documentTypeLayout={documentTypeLayout}
                ></EDLKindForm>
                <ConditionForm
                  documentData={documentData}
                  setDocumentData={setDocumentData}
                  documentTypeLayout={documentTypeLayout}
                ></ConditionForm>
                <IndiceForm
                  documentData={documentData}
                  setDocumentData={setDocumentData}
                  documentTypeLayout={documentTypeLayout}
                ></IndiceForm>
                <CommentForm
                  documentData={documentData}
                  setDocumentData={setDocumentData}
                  documentTypeLayout={documentTypeLayout}
                ></CommentForm>
                <Grid2 size={12}>
                  <Button
                    color="primary"
                    variant="outlined"
                    sx={{ borderRadius: '20px', paddingTop: '8px', marginBottom: '40px' }}
                    onClick={() => {
                      deleteDialogOpen ? handleCloseDeleteDialog() : handleOpenDeleteDialog();
                    }}
                  >
                    <RemoveIcon fontSize="small" />
                    <span style={{ fontWeight: 'bold', fontSize: '15px' }}>&nbsp; Supprimer le document</span>
                  </Button>
                </Grid2>
              </Grid2>
            </Grid2>
          </>
        )}
        {!documentData && (
          <>
            <DocumentUploader
              nextStepCallBack={handleNextStep}
              currentElementUUID={equipmentData ? equipmentData.uuid : productDatas.uuid}
              elementType="document"
              filesType="document"
            ></DocumentUploader>
          </>
        )}
      </BasicModal>
      <ConfirmationDialog
        message={'Etes vous certain de vouloir supprimer définitivement ce document'}
        onCancel={handleCloseDeleteDialog}
        onConfirm={deleteDraftEquipment}
        open={deleteDialogOpen}
        title={'Suppression'}
        variant={'error'}
        cancelText="Non"
        confirmText="Oui"
      />
    </>
  );
};
