import { Button, Grid, Grid2, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { PhotonClient, waitUntil } from '~/utils/PhotonClient';
import { CameraPose, Event, Frame, IVector3 } from '~/utils/types';
import { LocalTime } from '~/utils/time/LocalTime';

export const RemoteControlContent = ({
  matterport_model_id,
  refreshProductImages,
  productData,
  equipmentData = null,
  generateSubtitleEquipment = null,
  equipmentReferential = null,
  context = null,
  takeScreenShot = null,
  refreshProductData = null,
  mpSdk = null
}) => {
  const [remoteSessionModeEnabled, setRemoteSessionModeEnabled] = useState(false);
  const photonClientRef = useRef(null);
  const intervalRef = useRef(null);
  const timeRef = useRef<LocalTime>(null);

  const [actors, setActors] = useState({});

  const sendEvent = (photonClient: PhotonClient, type: string, step: number, args: IArguments) => {
    const event: Event = {
      type,
      step,
      args: Array.from(args)
    };
    // console.log('event', event);
    photonClient.raiseEvent(0, event);
  };

  const frameFromPose = (step: number, pose: CameraPose): Frame => {
    return {
      step,
      pose: {
        position: {
          x: pose.position.x,
          y: pose.position.y,
          z: pose.position.z
        },
        rotation: {
          x: pose.rotation.x,
          y: pose.rotation.y
        },
        mode: pose.mode,
        sweep: pose.sweep
      }
    };
  };

  useEffect(() => {
    return () => {
      // console.log('unsubscribed');
      if (photonClientRef.current) {
        // photonClientRef.current.myRoom().close();
        photonClientRef.current.disconnect();
        photonClientRef.current = null;
      }
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (mpSdk) {
      mpSdk.Camera.pose.subscribe((pose: CameraPose) => {
        // console.log('pose', pose);

        if (pose && photonClientRef.current !== null && timeRef.current !== null) {
          const currentStep = timeRef.current.currentStep();
          photonClientRef.current.myActor().setCustomProperty('pose', frameFromPose(currentStep, pose));
        }
      });
      mpSdk.on(mpSdk.Sweep.Event.EXIT, (...args: any) =>
        sendEvent(photonClientRef.current, mpSdk.Sweep.Event.EXIT, timeRef.current.currentStep(), args)
      );
      mpSdk.on(mpSdk.Mode.Event.CHANGE_START, (...args: any) =>
        sendEvent(photonClientRef.current, mpSdk.Mode.Event.CHANGE_START, timeRef.current.currentStep(), args)
      );
      mpSdk.on(mpSdk.Mode.Event.CHANGE_END, (...args: any) =>
        sendEvent(photonClientRef.current, mpSdk.Mode.Event.CHANGE_END, timeRef.current.currentStep(), args)
      );
    }
  }, [mpSdk]);

  // useEffect(() => {
  //   console.log(photonClientRef.current);
  //   if(photonClientRef.current) {
  //     photonClientRef.current.events.addListener('onActorJoin', onConnectedCountChanged);
  //     photonClientRef.current.events.addListener('onActorLeave', onConnectedCountChanged);
  //   }
  // }, [photonClientRef.current]);

  const onTick = () => {
    // console.log('tick');
    // console.log(photonClientRef.current);
  };

  const connectPhoton = async () => {
    const photonClient = new PhotonClient();
    await photonClient.start();
    // console.log('photonClient', photonClient);
    photonClientRef.current = photonClient;
    intervalRef.current = setInterval(onTick);
    timeRef.current = new LocalTime(photonClient);
    timeRef.current.start();

    const availableRooms = await photonClientRef.current.availableRooms();
    // console.log('availableRooms', availableRooms);

    const room = await photonClientRef.current.myRoom();
    // console.log(room);

    // await waitUntil(() => photonClientRef.current.isJoinedToRoom());

    const newRoom = await photonClientRef.current.joinRoom(productData.uuid, {
      createIfNotExists: true
    });

    // console.log('newRoom', newRoom);

    photonClientRef.current.events.addListener('onActorJoin', onConnectedCountChanged);
    photonClientRef.current.events.addListener('onActorLeave', onConnectedCountChanged);
  };

  const onConnectedCountChanged = (e) => {
    // console.log('onConnectedCountChanged', e);
    if (photonClientRef.current) {
      const actors = photonClientRef.current.myRoomActors();
      // console.log(actors);

      setActors({ ...actors });
    } else {
      setActors([]);
    }
  };

  useEffect(() => {
    if (remoteSessionModeEnabled === true) {
      connectPhoton();
    } else {
      if (photonClientRef.current) {
        // photonClientRef.current.myRoom().close();
        photonClientRef.current.disconnect();
        photonClientRef.current = null;
      }
      clearInterval(intervalRef.current);
    }
  }, [remoteSessionModeEnabled]);

  return (
    // <div>
    <Grid2 container spacing={2} style={{ padding: '10px' }} justifyContent={'center'} alignItems={'center'}>
      {!photonClientRef.current && (
        <Grid2 size={12}>{`Vous pouvez démarer une session de pilotage visite virtuelle`}</Grid2>
      )}

      {photonClientRef.current && actors && (
        <>
          <Grid2
            size={12}
          >{`Transmettez cette url aux personnes que vous voulez accueillir à cette visité pilotée`}</Grid2>

          <Grid2 size={12}>
            <TextField
              value={`${process.env.REACT_APP_BASEURL_PUBLIC}remoteClient/${productData.uuid}`}
              fullWidth
              size="small"
            ></TextField>
          </Grid2>
          <Grid2 size={12}>{`Vous êtes ${Object.keys(actors).length} personnes connectés`}</Grid2>
        </>
      )}
      <Grid2 size={12}>
        <Button
          onClick={() => {
            setRemoteSessionModeEnabled(!remoteSessionModeEnabled);
          }}
          variant={'contained'}
        >
          {!remoteSessionModeEnabled ? 'Démarer' : 'Terminer'}
        </Button>
      </Grid2>
    </Grid2>
  );
};
