import { Grid2, Rating, Switch } from '@mui/material';
import { getTagForEquipment, getTagPinPoint } from '../View3d/utilsView3D';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

export const EquipmentItem = ({
  equipment,
  index,
  equipmentSelected,
  cameraMoving,
  setUuidTagSelected,
  setEquipmentSelected,
  sortedEquipmentsList,
  getEquipmentName,
  productDatas,
  assignMode = null,
  equipmentsAssigned = null,
  setEquipmentsAssigned = null
}) => {
  const mediaQueries = useContext(MediaQueryContext);
  const [equipmentFullDatas, setEquipmentFullDatas] = useState(null);
  const [isASsigned, setIsAssigned] = useState(null);

  const assignChange = (event) => {
    if (event.target.checked) {
      setEquipmentsAssigned([...equipmentsAssigned, equipmentFullDatas]);
    } else {
      setEquipmentsAssigned(equipmentsAssigned.filter((element) => element.uuid !== equipmentFullDatas.uuid));
    }
  };

  useEffect(() => {
    setEquipmentFullDatas(_.find(productDatas.equipments, { uuid: equipment.uuid }));
  }, []);

  useEffect(() => {
    if (equipmentsAssigned && equipmentFullDatas) {
      if (equipmentsAssigned.find((element) => element.uuid === equipmentFullDatas.uuid)) {
        setIsAssigned(true);
      } else {
        setIsAssigned(false);
      }
    }
  }, [equipmentFullDatas]);
  return (
    <>
      {equipmentFullDatas && (
        <Grid2 size={12} key={index}>
          <Grid2
            id={equipmentFullDatas.uuid}
            container
            spacing={0}
            alignItems={'center'}
            sx={{
              borderBottom: '1px solid #e0e0e0',
              padding: '10px 0',
              cursor: 'pointer',
              pl: '10px',
              pr: '10px',
              backgroundColor: equipmentSelected === equipmentFullDatas?.uuid ? '#EEEEEE' : '#fff',
              fontWeight: equipmentSelected === equipmentFullDatas?.uuid ? 'bold' : 'normal'
            }}
            onClick={() => {
              if (getTagForEquipment(equipmentFullDatas) && setUuidTagSelected)
                setUuidTagSelected(getTagForEquipment(equipmentFullDatas).uuid, true);
              if (setEquipmentSelected) setEquipmentSelected(equipmentFullDatas);
            }}
          >
            <Grid2 size={'grow'}>{getEquipmentName(equipmentFullDatas)}</Grid2>
            <Grid2 size={'auto'}>
              {sortedEquipmentsList &&
                sortedEquipmentsList[index] &&
                equipmentFullDatas?.states &&
                equipmentFullDatas.states?.length - 1 >= 0 &&
                equipmentFullDatas?.states[equipmentFullDatas.states?.length - 1].rating !== null && (
                  <>
                    <Rating
                      size={mediaQueries.matchesSm ? 'medium' : 'small'}
                      sx={{ paddingRight: '15px' }}
                      value={equipmentFullDatas?.states[equipmentFullDatas.states?.length - 1].rating}
                      precision={0.5}
                      readOnly
                    ></Rating>
                  </>
                )}
            </Grid2>
            <Grid2 size={'auto'}>
              {!assignMode && (
                <>
                  {getTagPinPoint(equipmentFullDatas) ? (
                    getTagPinPoint(equipmentFullDatas)
                  ) : (
                    <svg
                      width="15"
                      height="25"
                      viewBox="0 0 20 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    ></svg>
                  )}
                </>
              )}
              {assignMode && isASsigned !== null && (
                <>
                  <Switch defaultChecked={isASsigned} onChange={assignChange}></Switch>
                </>
              )}
            </Grid2>
          </Grid2>
        </Grid2>
      )}
    </>
  );
};
