import { Chip, Grid2 } from '@mui/material';
import dayjs from 'dayjs';
import { referentielEventStatus, toolLogo } from '~/utils/references';
import { useContext, useEffect, useState } from 'react';
import { getIcon, getTagForEvent, getTagPinPoint, getVariant } from '../View3d/utilsView3D';
import _ from 'lodash';
import { AppContext } from '~/utils/context/AppContext';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import { api } from '~/api';

export const EventItem = ({
  event,
  eventSelected = null,
  setEventSelected = null,
  setUuidTagSelected = null,
  productDatas,
  context = null,
  isLast = null
}) => {
  const { referencial } = useContext(AppContext);
  const mediaQueries = useContext(MediaQueryContext);
  const [eventFullDatas, setEventFullDatas] = useState(null);

  useEffect(() => {
    if (context === 'equipment-details') {
      api.events.get(event.uuid).then((res) => {
        setEventFullDatas(res);
      });
    }
  }, []);

  return (
    <>
      <div>
        <Grid2
          size={12}
          id={event.uuid}
          sx={{
            cursor: 'pointer',
            backgroundColor: eventSelected?.uuid === event.uuid ? '#EEEEEE' : '#0000',
            p: '10px'
          }}
          onClick={() => {
            if (context === 'product') {
              setEventSelected(event?.uuid);
            } else {
              setEventSelected(event);
            }

            if (context === 'equipment-details' && getTagForEvent(eventFullDatas) && setUuidTagSelected)
              setUuidTagSelected(getTagForEvent(eventFullDatas).uuid, true);
          }}
        >
          <Grid2 container>
            <Grid2 size={'grow'} sx={{ pb: '10px' }}>
              <Grid2 container>
                <Grid2 size={12} sx={{ pb: context === 'product' && mediaQueries.matchesMd ? '0px' : '10px' }}>
                  <span style={{ color: '#929292' }}>
                    {dayjs(event?.created_at).format('DD/MM/YYYY') + ' - ' + dayjs(event?.created_at).format('HH:mm')}
                  </span>{' '}
                  {<br />}
                  {event?.kind !== 0 && (
                    <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                      <span style={{ color: '#929292' }}>Type : </span>{' '}
                      {context === 'product' && !mediaQueries.matchesMd && <br />}
                      <span style={{ color: event?.urgent === true ? 'rgb(241, 118, 51)' : '' }}>
                        {_.find(referencial['event.kind'], { id: Number(event?.kind) })?.name}
                      </span>
                    </Grid2>
                  )}
                </Grid2>
              </Grid2>
            </Grid2>

            {event?.urgent === true && (
              <Grid2 sx={{ pr: '10px' }} size={'auto'}>
                {toolLogo(2, 25, 'rgb(241, 118, 51)')}
              </Grid2>
            )}

            {context !== 'equipment-details' && event?.tags && event?.tags?.length > 0 && (
              <Grid2 size={'auto'} sx={{ pr: '10px' }}>
                {getTagPinPoint(event)}
              </Grid2>
            )}

            {context === 'equipment-details' && eventFullDatas?.tags && eventFullDatas?.tags?.length > 0 && (
              <Grid2 size={'auto'} sx={{ pr: '10px' }}>
                {getTagPinPoint(eventFullDatas)}
              </Grid2>
            )}

            {event?.status !== 0 && (
              <Grid2 size={'auto'}>
                <Chip
                  size="small"
                  icon={getIcon(event?.status)}
                  label={referentielEventStatus[event?.status]}
                  color={getVariant(event?.status)}
                  sx={{
                    color: getVariant(event?.status) + '.main',
                    backgroundColor: getVariant(event?.status) + '.light'
                  }}
                />
              </Grid2>
            )}

            <Grid2 size={12}>
              <Grid2 container></Grid2>
            </Grid2>

            {event?.desc && event?.desc !== '' && (
              <Grid2 size={12} sx={{ pb: '10px' }}>
                <span style={{ color: '#929292' }}>Description : </span>{' '}
                {context === 'product' && !mediaQueries.matchesMd && <br />}
                <span>{event?.desc}</span>{' '}
              </Grid2>
            )}
          </Grid2>
        </Grid2>
        {!isLast && <div style={{ borderTop: '1px solid #EEEEEE', paddingBottom: '10px' }} />}
      </div>
    </>
  );
};
