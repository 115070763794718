import { Box, Button, Chip, Grid2, Link, ListItem, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { referentielEventStatus, toolLogo } from '~/utils/references';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { api } from '~/api';
import Gallery from '../Gallery/Gallery';
import { ModalContentType } from '~/interfaces';
import BasicModal from '../BasicModal/BasicModal';
import { EquipmentItem } from '../EquipmentsList/EquipmentItem';
import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';
import { getIcon, getVariant } from '../View3d/utilsView3D';
import auth from '~/app/components/Auth/AuthApi';
import { EditOutlined, IosShareOutlined } from '@mui/icons-material';
import { EditEventInfos } from '../EditEventInfos/EditEventInfos';
import { EventShareDialog } from '../EventShareDialog/EventShareDialog';
import { WizzardDocument } from '../WizzardDocument/WizzardDocument';
import DocumentItem from '../EquipmentsList/DocumentItem';
import MessageIcon from '@mui/icons-material/Message';
import { isAdmin } from '../Auth/AuthApi';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import { MessageItem } from './MessageItem';

export const EventDetails = ({
  setEquipmentSelected,
  event,
  productDatas,
  setUuidTagSelected,
  refreshData,
  setCreateEntityMode = null,
  setActiveMainToolId = null,
  setLinkedEntityUuid = null,
  isLandscape
}) => {
  const mediaQueries = useContext(MediaQueryContext);

  const [parsedJwt, setParsedJwt] = useState(null);
  const [eventFullData, setEventFullData] = useState(null);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [images, setImagesList] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalContentType>();
  const [userName, setUserName] = useState<any>('');
  const [equipments, setEquipments] = useState<any>([]);
  const { referencial, equipmentReferencial } = useContext(AppContext);
  const [openShareEventDialog, setOpenShareEventDialog] = useState(false);
  const [wizzardDocumentOpen, setWizzardDocumentOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showAllDocuments, setShowAllDocuments] = useState(false);
  const [wizzardEquipmentOpen, setWizzardEquipmentOpen] = useState<boolean>(false);
  const [messagesList, setMessagesList] = useState(null);
  const [observation, setObservation] = useState('');
  const [firstLaunch, setFirstLaunch] = useState(true);
  const [wizzardEquipmentCreationMode, setWizzardEquipmentCreationMode] = useState<boolean>(false);

  const handleContentModal = (type: ModalContentType): ReactNode => {
    switch (type) {
      case 'Gallery':
        return <Gallery images={images} startIndex={galleryIndex} />;
      case 'EditEventInfos':
        return (
          <EditEventInfos
            setModalOpen={setModalOpen}
            refreshData={refreshData}
            eventData={eventFullData}
            productDatas={productDatas}
            equipments={equipments}
            refreshEvent={getEventFullData}
          />
        );
    }
  };

  const getEquipmentName = (equipment) => {
    const title = equipment.title && equipment.title !== '' ? equipment.title : '';
    const categoryName = equipment.kind
      ? equipmentReferencial.equipments.find((category) => category.id === equipment.kind)?.name
      : '';
    const categoryKindName = equipment.category_kind
      ? 'Catégorie : ' +
        equipmentReferencial.equipmentCategoryKinds.find((category) => category.id === equipment.category_kind)?.name
      : equipment.tab !== ''
        ? 'Onglet : ' + equipment.tab
        : '';

    // Retourner le titre de l'équipement ou la catégorie
    return `${title !== '' ? title : categoryName}\n${categoryKindName}`;
  };

  const updateSelectedDocumentData = (documentData) => {
    if (documentData)
      api.files.update(documentData.uuid, documentData).then((res) => {
        setSelectedDocument(res);
        getEventFullData();
      });
  };

  const modalClosed = () => {
    // console.log('modalClosed');
  };

  const handleModal = (type: ModalContentType) => {
    setModalOpen(!modalOpen);
    setModalType(type);
  };

  const getEventFullData = async () => {
    const promises = [];
    promises.push(
      await api.events.get(event.uuid).then((res) => {
        setEventFullData(res);
        if (res.files && res.files.length > 0) {
          setImagesList(res.files.filter((file) => file.kind === 'image'));
          setDocuments(res.files.filter((file) => file.kind === 'document'));
        } else {
          setImagesList([]);
        }

        if (res.messages && res.messages.length > 0) {
          const messagesList = [];

          res.messages.forEach(async (message, index) => {
            const isCurrentUser = message && message?.uuid_user === parsedJwt?.uuid_user ? true : false;

            messagesList.push(
              <MessageItem
                key={message.uuid}
                isCurrentUser={isCurrentUser}
                index={index}
                message={message}
                eventUuid={event.uuid}
                getEventFullData={getEventFullData}
                isAdmin={isAdmin()}
              ></MessageItem>
            );
          });

          setMessagesList(
            <Grid2 container>
              <Grid2 size={12}>{messagesList}</Grid2>
            </Grid2>
          );
        } else {
          setMessagesList(null);
        }
        if (res.user_creator) {
          setUserName(res.user_creator?.first_name + ' ' + res.user_creator?.last_name);
        } else {
          setUserName(null);
        }
      }),
      api.events.equipment.get(event.uuid).then((res) => {
        if (setFirstLaunch) {
          setFirstLaunch(false);

          setEquipments(res);
        }
      })
    );

    return Promise.all(promises);
  };

  const handleSubmitObservation = (observation) => {
    api.events.message.create(event.uuid, { content: observation }).then((res) => {
      getEventFullData();
    });
  };

  useEffect(() => {
    setParsedJwt(auth.getJWT());
  }, []);

  useEffect(() => {
    if (parsedJwt && event) {
      getEventFullData();
    }
  }, [parsedJwt, event]);

  if (eventFullData) {
    return (
      <Grid2 container>
        <Grid2 size={12}>
          <Grid2 container>
            <Grid2 size={'grow'}>
              <Box></Box>
            </Grid2>

            <Grid2 size={'auto'} onClick={() => setOpenShareEventDialog(true)}>
              <IosShareOutlined sx={{ fontSize: '18px', cursor: 'pointer', pr: '5px' }} />
            </Grid2>

            <Grid2
              size={'auto'}
              onClick={() => {
                handleModal('EditEventInfos');
              }}
            >
              <EditOutlined sx={{ fontSize: '18px', cursor: 'pointer' }} />
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 size={12} sx={{ pb: '10px', borderBottom: '1px solid #e0e0e0' }}>
          <Grid2 sx={{ mb: '20px' }} container alignItems={'baseline'} justifyContent={'center'}>
            <Grid2 size={'auto'} sx={{ pr: '10px' }}>
              <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.5 0.0849609C3.36 0.0849609 0 3.61899 0 7.97342C0 12.3279 3.36 15.8619 7.5 15.8619C11.64 15.8619 15 12.3279 15 7.97342C15 3.61899 11.64 0.0849609 7.5 0.0849609ZM8.25 11.9177H6.75V7.18458H8.25V11.9177ZM8.25 5.60688H6.75V4.02919H8.25V5.60688Z"
                  fill="black"
                />
              </svg>
            </Grid2>
            <Grid2 size={'grow'}>
              <Typography variant="h4">Caractéristiques</Typography>
            </Grid2>
          </Grid2>
          <Grid2 container sx={{ pb: '10px', width: '100%' }}>
            <Grid2 size={12}>
              <Grid2 container sx={{ pb: '10px' }}>
                <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                  <span style={{ color: '#929292' }}>Date : </span> <br />
                  <span>
                    {dayjs(eventFullData.created_at).format('DD/MM/YYYY') +
                      ' - ' +
                      dayjs(eventFullData.created_at).format('HH:mm')}{' '}
                  </span>{' '}
                </Grid2>

                {eventFullData.status !== 0 && (
                  <Grid2 size={'auto'}>
                    <Chip
                      size="small"
                      icon={getIcon(eventFullData.status)}
                      label={referentielEventStatus[eventFullData.status]}
                      color={getVariant(eventFullData.status)}
                      sx={{
                        color: getVariant(eventFullData.status) + '.main',
                        backgroundColor: getVariant(eventFullData.status) + '.light'
                      }}
                    />
                  </Grid2>
                )}
              </Grid2>
            </Grid2>

            {images && (
              <Grid2 size={12}>
                <div className="hide_scrollbar" style={{ width: '100%', overflowX: 'scroll' }}>
                  <div style={{ position: 'relative', display: 'inline-flex' }}>
                    {images
                      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                      .map((image, index) => (
                        <div
                          className="product_sheet_image"
                          key={image.uri}
                          onClick={() => {
                            setGalleryIndex(index);
                            handleModal('Gallery');
                          }}
                          style={{
                            backgroundImage: `url(${process.env.REACT_APP_BASEURL_MEDIAS}/${image.uri})`
                          }}
                        ></div>
                      ))}
                  </div>
                </div>
              </Grid2>
            )}

            <Grid2 size={12}>
              <Grid2 container>
                {eventFullData.kind !== 0 && (
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Type : </span> <br />
                    <span style={{ color: eventFullData.urgent ? 'rgb(241, 118, 51)' : '#929292' }}>
                      {_.find(referencial['event.kind'], { id: Number(eventFullData.kind) }).name}
                    </span>{' '}
                  </Grid2>
                )}
                {eventFullData.urgent === true && <Grid2 size={'auto'}>{toolLogo(2, 25, 'rgb(241, 118, 51)')}</Grid2>}
              </Grid2>
            </Grid2>

            <Grid2 size={12}>
              <Grid2 container sx={{ pb: '10px' }}>
                <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                  <span style={{ color: '#929292' }}>Description : </span> <br />
                  <span>{eventFullData.desc}</span>
                </Grid2>
              </Grid2>
            </Grid2>

            {eventFullData.data && eventFullData.data.contact_requestor && (
              <Grid2 size={12}>
                <Grid2 container sx={{ pb: '10px' }}>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Déclarant public : </span> <br />
                    <span>{eventFullData.data.contact_requestor.name}</span>
                    <br />
                    <span>{eventFullData.data.contact_requestor.phone}</span>
                    <br />
                    <span>
                      <ListItem
                        key="Email"
                        component="a"
                        href={'mailto:' + eventFullData.data.contact_requestor.email}
                      ></ListItem>
                    </span>
                  </Grid2>
                </Grid2>
              </Grid2>
            )}

            {userName && (
              <Grid2 size={12}>
                <Grid2 container sx={{ pb: '10px' }}>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Déclarant : </span> <br />
                    <span>{userName}</span>
                  </Grid2>
                </Grid2>
              </Grid2>
            )}
          </Grid2>
        </Grid2>

        {documents && documents.length > 0 && (
          <Grid2 size={12}>
            <Grid2 container alignItems={'baseline'} justifyContent={'center'} sx={{ pt: '20px', pb: '20px' }}>
              <Grid2 size={'auto'} sx={{ pr: '10px' }}>
                <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.125 0.485596H1.625C0.73125 0.485596 0.00812519 1.24289 0.00812519 2.16847L0 15.6314C0 16.557 0.723125 17.3143 1.61687 17.3143H11.375C12.2688 17.3143 13 16.557 13 15.6314V5.53421L8.125 0.485596ZM9.75 13.9486H3.25V12.2657H9.75V13.9486ZM9.75 10.5828H3.25V8.89996H9.75V10.5828ZM7.3125 6.37565V1.74775L11.7812 6.37565H7.3125Z"
                    fill="black"
                  />
                </svg>
              </Grid2>
              <Grid2 size={'grow'}>
                <Typography variant="h4">Documents</Typography>
              </Grid2>
              {documents.length > 3 && (
                <Grid2
                  size={'auto'}
                  onClick={() => {
                    // setShowAllStates(!showAllStates);
                    // setShowAllEvents(!showAllEvents);
                    setShowAllDocuments(!showAllDocuments);
                  }}
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  {!showAllDocuments ? 'Voir tous les documents' : 'Masquer tous les documents'}
                </Grid2>
              )}
            </Grid2>

            {documents.length > 0 && (
              <Grid2 size={12}>
                <Grid2 container>
                  {documents.map((document, index) => (
                    <Grid2 size={12} key={index}>
                      {!showAllDocuments && index < 3 && (
                        <Grid2 size={12} key={index}>
                          <DocumentItem
                            document={document}
                            setWizzardDocumentOpen={setWizzardDocumentOpen}
                            setSelectedDocument={setSelectedDocument}
                          ></DocumentItem>
                        </Grid2>
                      )}

                      {showAllDocuments && (
                        <Grid2 size={12} key={index}>
                          <DocumentItem
                            document={document}
                            setWizzardDocumentOpen={setWizzardDocumentOpen}
                            setSelectedDocument={setSelectedDocument}
                          ></DocumentItem>
                        </Grid2>
                      )}
                    </Grid2>
                  ))}
                </Grid2>
              </Grid2>
            )}
          </Grid2>
        )}

        {/* {equipments?.length > 0 && ( */}
        <>
          <Grid2 size={12} sx={{ pb: '10px', pt: '20px', borderBottom: '1px solid #e0e0e0' }}>
            <Grid2 sx={{ mb: '20px' }} container alignItems={'baseline'} justifyContent={'center'}>
              <Grid2 size={'auto'} sx={{ pr: '10px' }}>
                <MessageIcon sx={{ fontSize: 18 }} />
              </Grid2>
              <Grid2 size={'grow'}>
                <Typography variant="h4">Observations</Typography>
              </Grid2>
            </Grid2>
            <Grid2 container spacing={2} sx={{ pb: '10px' }}>
              <Grid2 size={12}>{messagesList}</Grid2>
              <Grid2 size={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  label="Décriver votre observation"
                  value={observation}
                  onChange={(e) => setObservation(e.target.value)}
                />
              </Grid2>
              <Grid2 size={12} sx={{ textAlign: 'center' }}>
                <Button
                  disabled={!observation || observation === ''}
                  fullWidth={isLandscape || !mediaQueries.matchesSm ? true : false}
                  type="submit"
                  color={'primary'}
                  variant="contained"
                  onClick={() => {
                    handleSubmitObservation(observation);
                    setObservation('');
                  }}
                >
                  Créer observation
                </Button>
              </Grid2>
            </Grid2>
          </Grid2>
        </>
        <>
          <Grid2 size={12} sx={{ pb: '10px', pt: '20px' }}>
            <Grid2 sx={{ mb: '20px' }} container alignItems={'baseline'} justifyContent={'center'}>
              <Grid2 size={'auto'} sx={{ pr: '10px' }}>
                {toolLogo(3, 20, 'black')}
              </Grid2>
              <Grid2 size={'grow'}>
                <Typography variant="h4">Équipements liés</Typography>
              </Grid2>
            </Grid2>
            <Grid2 container sx={{ pb: '10px' }}>
              {equipments && equipments.length > 0 && (
                <div style={{ width: '100%', paddingBottom: '10px' }}>
                  {equipments?.map(
                    (equipment, index) =>
                      equipment && (
                        <EquipmentItem
                          key={`${equipment.uuid}-${index}`}
                          equipment={equipment}
                          index={index}
                          equipmentSelected={null}
                          cameraMoving={false}
                          setUuidTagSelected={setUuidTagSelected}
                          setEquipmentSelected={setEquipmentSelected}
                          sortedEquipmentsList={equipments}
                          getEquipmentName={getEquipmentName}
                          productDatas={productDatas}
                        />
                      )
                  )}
                </div>
              )}
              {
                <Grid2 size={12} sx={{ pb: '10px', pl: '10px' }}>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={(e) => {
                      setLinkedEntityUuid(event.uuid);
                      setActiveMainToolId(3);
                      setTimeout(() => {
                        setCreateEntityMode(true);
                      }, 100);
                    }}
                  >
                    Ajouter un équipement lié au ticket
                  </Link>
                </Grid2>
              }
            </Grid2>
          </Grid2>
        </>
        {/* )} */}

        <BasicModal
          modalOpen={modalOpen}
          onModalOpen={(open: boolean) => setModalOpen(open)}
          onModalClose={modalClosed}
        >
          {handleContentModal(modalType)}
        </BasicModal>

        {openShareEventDialog && (
          <EventShareDialog
            onClose={() => setOpenShareEventDialog(false)}
            open={openShareEventDialog}
            eventData={eventFullData}
            address={productDatas.address}
          />
        )}

        {wizzardDocumentOpen && (
          <WizzardDocument
            productDatas={productDatas}
            setWizzardDocumentOpen={setWizzardDocumentOpen}
            documentData={selectedDocument}
            setDocumentData={updateSelectedDocumentData}
            updateSelectedDocumentData={updateSelectedDocumentData}
            setSelectedUUIDEquipment={setEquipmentSelected}
            setWizzardEquipmentCreationMode={setWizzardEquipmentCreationMode}
            setWizzardEquipmentOpen={setWizzardEquipmentOpen}
          ></WizzardDocument>
        )}
      </Grid2>
    );
  } else {
    return <></>;
  }
};
