import { EquipmentsListView3d } from '../EquipmentsList/EquipmentsListView3d';
import { ImagePicker } from '../ImagePicker/ImagePicker';
import EventsListView3d from '../EventsList/EventsListView3d';
import { useEffect, useState } from 'react';
import { RemoteControlContent } from './RemoteControl';

export const ViewerContentContainer = ({
  productDatas,
  context,
  takeScreenShot,
  refreshProductData = null,
  contentId = null,
  equipmentSelected = null,
  setEquipmentSelected = null,
  uuidTagSelected = null,
  setUuidTagSelected = null,
  navigateToTag = null,
  hideTags = null,
  showTags = null,
  cameraMoving = null,
  events = null,
  eventSelected = null,
  setEventSelected,
  createEntityMode,
  setCreateEntityMode,
  hasNoMatterportScan,
  setModalOpen,
  setActiveMainToolId,
  setLinkedEntityUuid,
  linkedEntityUuid,
  waitingForApiResponse,
  setWaitingForApiResponse,
  setNewEntityCreated,
  isLandscape,
  mpSdk
}) => {
  const refreshProductImages = () => {
    refreshProductData();
  };

  return (
    <div>
      {contentId === 2 && (
        <div style={{ padding: '10px' }}>
          <EventsListView3d
            events={events}
            eventSelected={eventSelected}
            setEventSelected={setEventSelected}
            setUuidTagSelected={setUuidTagSelected}
            uuidTagSelected={uuidTagSelected}
            navigateToTag={navigateToTag}
            setEquipmentSelected={setEquipmentSelected}
            productDatas={productDatas}
            showTags={showTags}
            refreshData={refreshProductData}
            createEntityMode={createEntityMode}
            setCreateEntityMode={setCreateEntityMode}
            hasNoMatterportScan={hasNoMatterportScan}
            setModalOpen={setModalOpen}
            setActiveMainToolId={setActiveMainToolId}
            setLinkedEntityUuid={setLinkedEntityUuid}
            linkedEntityUuid={linkedEntityUuid}
            setWaitingForApiResponse={setWaitingForApiResponse}
            setNewEntityCreated={setNewEntityCreated}
            isLandscape={isLandscape}
          ></EventsListView3d>
        </div>
      )}
      {contentId === 3 && (
        <div style={{ padding: '10px' }}>
          <EquipmentsListView3d
            productDatas={productDatas}
            equipmentSelected={equipmentSelected}
            setEquipmentSelected={setEquipmentSelected}
            setUuidTagSelected={setUuidTagSelected}
            showTags={showTags}
            cameraMoving={cameraMoving}
            setEventSelected={setEventSelected}
            refreshData={refreshProductData}
            createEntityMode={createEntityMode}
            setCreateEntityMode={setCreateEntityMode}
            hasNoMatterportScan={hasNoMatterportScan}
            setModalOpen={setModalOpen}
            setActiveMainToolId={setActiveMainToolId}
            setLinkedEntityUuid={setLinkedEntityUuid}
            linkedEntityUuid={linkedEntityUuid}
            setWaitingForApiResponse={setWaitingForApiResponse}
            setNewEntityCreated={setNewEntityCreated}
            isLandscape={isLandscape}
          ></EquipmentsListView3d>
        </div>
      )}
      {contentId === 5 && (
        <ImagePicker
          matterport_model_id={productDatas?.matterport_scan?.model_id}
          refreshProductImages={refreshProductImages}
          productData={productDatas}
          context={context}
          takeScreenShot={takeScreenShot}
          refreshProductData={refreshProductData}
        />
      )}
      {contentId === 7 && (
        <RemoteControlContent
          matterport_model_id={productDatas?.matterport_scan?.model_id}
          refreshProductImages={refreshProductImages}
          productData={productDatas}
          context={context}
          takeScreenShot={takeScreenShot}
          refreshProductData={refreshProductData}
          mpSdk={mpSdk}
        />
      )}
    </div>
  );
};

export default ViewerContentContainer;
