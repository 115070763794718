import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import 'reflect-metadata';
import App from './app/App';
import ReactGA from 'react-ga4';
import { PostHogProvider } from 'posthog-js/react';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
};

const container = document.getElementById('root');
const root = createRoot(container!);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', process.env.REACT_APP_API_URL],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
root.render(
  <>
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
      <App ReactGA={ReactGA} />
    </PostHogProvider>
  </>
);
