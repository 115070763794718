import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { appTheme } from '~/utils/theme';
import {
  Box,
  Grid2,
  Typography,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  TextField,
  MenuItem,
  ListItemIcon,
  ListItemText,
  InputBase
} from '@mui/material';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import { useContext, useEffect, useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { iconFilters } from '../View3d/utilsView3D';
import _ from 'lodash';
import { AppContext } from '~/utils/context/AppContext';

const ListFilter = ({
  handleChangeTypeFilter = null,
  buildRefEventTypesValues = null,
  handleChangeSearchFilter,
  search,
  walletChecked = null,
  handleChangeWalletChecked = null,
  closedChecked = null,
  handleChangeClosedChecked = null,
  archivedChecked = null,
  handleChangeArchivedChecked = null,
  productEventsLayout = false,
  events = null,
  handleSearchTicketsType = null,
  isEvent = null,
  context = null
}) => {
  const mediaQueries = useContext(MediaQueryContext);
  const [showFilters, setShowFilters] = useState(context === 'product' ? true : false);
  const [eventTypes, setEventTypes] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const { referencial } = useContext(AppContext);
  const [eventsBackup, setEventsBackup] = useState([]);

  useEffect(() => {
    if (events) {
      setEventsBackup([...events]);
      const eventTypesTmp = _.uniq(events.map((event) => event.kind !== 0 && event.kind)).filter((type) => type);
      setEventTypes(eventTypesTmp);
      setFilteredEvents([...events]);
    }
  }, [events]);

  const handleSelectType = (value) => {
    setSelectedType(value);
  };

  useEffect(() => {
    if (selectedType) {
      const filteredEquipmentsTmp = events.filter((event) => event.kind === selectedType);
      setFilteredEvents(filteredEquipmentsTmp);
    } else {
      setFilteredEvents(eventsBackup);
    }
  }, [selectedType, events]);

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <Box sx={context === 'product' ? { position: 'relative', top: '-10px' } : { pt: 2, pb: 1, width: '100%' }}>
      <Grid2 container alignItems="flex-end">
        <Grid2 size={12}>
          <Box sx={{ flex: 1, mb: context === 'product' ? 0 : 2, width: 1 }}>
            <Grid2 container>
              <Grid2
                size={12}
                sx={{ display: 'flex', alignItems: 'center', mb: context === 'product' ? 'Opx' : '1vh' }}
              ></Grid2>
              <Grid2
                container
                spacing={0}
                alignItems={'flex-start'}
                sx={{
                  borderBottom: '0px solid #e0e0e0',
                  padding: context === 'product' ? '0px' : '10px 0',
                  width: '100%'
                }}
              >
                {isEvent ? (
                  <>
                    <Grid2 size={'grow'}>
                      {showFilters && (
                        <Grid2 container spacing={1}>
                          <Grid2 size={{ xs: 6 }}>
                            <TextField
                              size="small"
                              sx={{ textTransform: 'none' }}
                              fullWidth
                              autoComplete="off"
                              label="Recherche"
                              onChange={handleChangeSearchFilter}
                              value={search}
                              InputProps={
                                search !== ''
                                  ? {
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <HighlightOffIcon
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                              handleChangeSearchFilter({ target: { value: '' } });
                                            }}
                                          />
                                        </InputAdornment>
                                      ),
                                      style: {
                                        paddingRight: 0
                                      }
                                    }
                                  : {}
                              }
                            />
                          </Grid2>
                          <Grid2 size={{ xs: 6 }}>
                            <FormControl fullWidth>
                              <InputLabel size="small" id="demo-simple-select-label" sx={{ textTransform: 'none' }}>
                                Par type
                              </InputLabel>
                              <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedType}
                                label="Par type"
                                onChange={(e) => {
                                  handleSelectType(e.target.value);
                                  handleChangeSearchFilter({ target: { value: '' } });
                                  handleSearchTicketsType(e.target.value);
                                }}
                                size="small"
                              >
                                {eventTypes.map((type, index) => (
                                  <MenuItem key={index} value={type}>
                                    {_.find(referencial['event.kind'], { id: Number(type) })?.name || `Type ${type}`}
                                  </MenuItem>
                                ))}
                                <MenuItem value="" onClick={() => handleSelectType(null)}>
                                  <ListItemIcon>
                                    <HighlightOffIcon />
                                  </ListItemIcon>
                                  <ListItemText primary="Réinitialiser" />
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid2>
                        </Grid2>
                      )}
                    </Grid2>
                    <Grid2
                      size={1}
                      sx={{
                        display: context === 'product' ? 'none' : 'auto',
                        width: '32px',
                        cursor: 'pointer',
                        pl: '10px',
                        pt: '10px'
                      }}
                      onClick={handleShowFilters}
                    >
                      {iconFilters(showFilters ? 'rgb(241, 118, 51)' : '#000')}
                    </Grid2>
                  </>
                ) : (
                  <Box sx={{ flexGrow: 3, mr: 2 }}>
                    <div
                      style={{
                        alignItems: 'center',
                        backgroundColor: appTheme.palette.primary[50],
                        border: `1px solid`,
                        borderColor: appTheme.palette.primary[100],
                        borderRadius: '20px',
                        display: 'flex',
                        height: '36px',
                        fontSize: '16px',
                        width: '100%'
                      }}
                    >
                      <InputBase
                        autoComplete="off"
                        placeholder="Rechercher"
                        onChange={handleChangeSearchFilter}
                        value={search}
                        inputProps={{
                          sx: {
                            pl: 1.8,
                            '&::placeholder': {}
                          }
                        }}
                        sx={{ flex: 1 }}
                      />
                      <SearchOutlinedIcon
                        sx={{
                          color: 'primary.main',
                          ml: 2,
                          mr: 1
                        }}
                        fontSize="medium"
                      />
                    </div>
                  </Box>
                )}
              </Grid2>

              <Grid2 size={12}>
                <Grid2 container sx={{ mt: '1vh' }}>
                  {handleChangeTypeFilter && buildRefEventTypesValues && (
                    <Grid2 size={{ md: 3, xs: 12 }} sx={{ display: 'flex', alignItems: 'center', mb: '1vh' }}>
                      <>
                        <Box sx={{ mr: 2 }}>
                          <Typography variant="body1" sx={{ color: 'primary.main' }}>
                            Filtrer:
                          </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 1, mr: 2 }}>
                          <FormControl fullWidth size="small">
                            <InputLabel id="userType">Type de ticket</InputLabel>
                            <Select
                              labelId="userType"
                              label="Type de ticket"
                              defaultValue=""
                              onChange={handleChangeTypeFilter}
                            >
                              {buildRefEventTypesValues()}
                            </Select>
                          </FormControl>
                        </Box>
                      </>
                    </Grid2>
                  )}
                  <Box>
                    <Typography variant="body1" sx={{ color: 'primary.main' }}>
                      {!productEventsLayout && handleChangeWalletChecked && (
                        <>
                          <Checkbox checked={walletChecked} onChange={handleChangeWalletChecked} id="myEvents" />
                          <label htmlFor="myEvents">Mes tickets</label>
                        </>
                      )}
                      {handleChangeClosedChecked && (
                        <>
                          <Checkbox checked={closedChecked} onChange={handleChangeClosedChecked} id="closedEvents" />
                          <label htmlFor="closedEvents">
                            {mediaQueries.matchesMd ? 'Tickets terminés' : 'Terminés'}
                          </label>
                        </>
                      )}
                      {handleChangeArchivedChecked && (
                        <>
                          <Checkbox
                            checked={archivedChecked}
                            onChange={handleChangeArchivedChecked}
                            id="archivedEvents"
                          />
                          <label htmlFor="archivedEvents">
                            {mediaQueries.matchesMd ? 'Tickets archivés' : 'Archivés'}
                          </label>
                        </>
                      )}
                    </Typography>
                  </Box>
                </Grid2>
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default ListFilter;
