import {
  Button,
  FormControl,
  Grid2,
  InputLabel,
  Paper,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { ProductTitle } from '../ProductTitle/ProductTitle';
import { api } from '~/api';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '~/utils/context/AppContext';
import EquipmentsListView3d from '../EquipmentsList/EquipmentsListView3d';
import { UploadFile } from '~/app/components/CustomDropZoneArea/CustomDropZoneArea';

export const EditEventInfos = ({
  setModalOpen,
  refreshData,
  eventData,
  productDatas,
  equipments,
  hasNoMatterportScan = null,
  refreshEvent = null
}) => {
  const [contentHeight, setContentHeight] = useState(0);
  const [tmpEventData, setTmpEventData] = useState(null);
  const [userName, setUserName] = useState<any>('');
  const [tmpEquipmentAssigned, setTmpEquipmentAssigned] = useState<any>(null);
  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);

  const { referencial } = useContext(AppContext);

  useEffect(() => {
    if (eventData) {
      setTmpEventData(eventData);

      if (eventData.user_creator)
        setUserName(eventData.user_creator?.first_name + ' ' + eventData.user_creator?.last_name);
    }
  }, [eventData]);

  useEffect(() => {
    if (headerRef.current && footerRef.current) {
      setContentHeight(window.innerHeight - headerRef.current.offsetHeight - footerRef.current.offsetHeight - 60);
    }
  });

  useEffect(() => {
    if (equipments) {
      setTmpEquipmentAssigned(equipments);
    } else {
      setTmpEquipmentAssigned([]);
    }
  }, [equipments]);

  const buildRefEventTypesValues = () => {
    const arrType = [];
    referencial['event.kind'].forEach((item, index) => {
      arrType.push(
        <MenuItem key={index} value={Number(item.id)}>
          {item.name}
        </MenuItem>
      );
    });
    return arrType;
  };

  return (
    <>
      {tmpEventData && (
        <>
          <Grid2 container ref={headerRef} spacing={0}>
            <div style={{ backgroundColor: '#fff7f3', width: '100vw', paddingLeft: '15px' }}>
              <Grid2 size={12}>
                <ProductTitle data={productDatas} />
              </Grid2>
            </div>
          </Grid2>

          <Grid2
            sx={{ p: '25px' }}
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="stretch"
            wrap="wrap"
          >
            <Grid2
              size={12}
              style={{
                maxHeight: contentHeight + 'px',
                overflow: 'auto',
                paddingBottom: '10px',
                paddingRight: '10px'
              }}
            >
              <Grid2
                container
                spacing={5}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="wrap"
              >
                {/* Detail du ticket */}

                <Grid2 size={{ xs: 12, md: 4 }}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Grid2
                      container
                      spacing={3}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      alignContent="stretch"
                      wrap="wrap"
                    >
                      <Grid2 size={12}>
                        <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Gestion du ticket</h3>
                      </Grid2>
                      <Grid2 size={12}>
                        {tmpEventData?.status && (
                          <Grid2 size={{ xs: 12 }}>
                            <FormControl sx={{ marginTop: '20px' }} fullWidth>
                              <InputLabel id="eventType">{'Statut'}</InputLabel>
                              <Select
                                labelId="eventType"
                                label="Statut"
                                value={Number(tmpEventData?.status)}
                                fullWidth
                                onChange={(e) => {
                                  setTmpEventData({ ...tmpEventData, status: Number(e.target.value) });
                                }}
                              >
                                <MenuItem value={2}>A qualifier</MenuItem>
                                <MenuItem value={3}>En cours</MenuItem>
                                <MenuItem value={4}>Terminé</MenuItem>
                                <MenuItem value={5}>Archivé</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid2>
                        )}
                      </Grid2>
                      {
                        <Grid2 size={{ xs: 12 }}>
                          <FormControl sx={{ marginTop: '20px' }} fullWidth>
                            <InputLabel id="kind">{'Type de ticket'}</InputLabel>
                            <Select
                              labelId="kind"
                              label="Type de ticket"
                              fullWidth
                              value={tmpEventData.kind !== 0 ? tmpEventData.kind : null}
                              onChange={(e) => {
                                setTmpEventData({ ...tmpEventData, kind: Number(e.target.value) });
                              }}
                            >
                              {buildRefEventTypesValues()}
                            </Select>
                          </FormControl>
                        </Grid2>
                      }

                      <Grid2 size={{ xs: 2 }}>
                        <FormControlLabel
                          label="Urgent"
                          control={
                            <Checkbox
                              checked={tmpEventData.urgent}
                              color={'primary'}
                              onChange={(e) => {
                                setTmpEventData({ ...tmpEventData, urgent: e.target.checked });
                              }}
                            />
                          }
                          sx={{ marginTop: '20px' }}
                        />
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>

                {/* Assignation Equipment */}

                <Grid2 size={{ xs: 12, md: 4 }}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Grid2
                      container
                      spacing={3}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      alignContent="stretch"
                      wrap="wrap"
                    >
                      <Grid2 size={12}>
                        <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Ajouter des Images</h3>
                      </Grid2>

                      <Grid2 size={12}>
                        <UploadFile
                          filesType="images"
                          setFile={setImages}
                          text={
                            <pre>
                              Faites glisser vos images ici <br />
                              (10 megas maximum)
                            </pre>
                          }
                        ></UploadFile>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>

                <Grid2 size={{ xs: 12, md: 4 }}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Grid2
                      container
                      spacing={3}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      alignContent="stretch"
                      wrap="wrap"
                    >
                      <Grid2 size={12}>
                        <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Ajouter des documents</h3>
                      </Grid2>

                      <Grid2 size={12}>
                        <UploadFile
                          filesType="documents"
                          setFile={setFiles}
                          text={
                            <pre>
                              Faites glisser vos documents ici <br />
                              (10 megas maximum)
                            </pre>
                          }
                        ></UploadFile>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Grid2
                      container
                      spacing={3}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      alignContent="stretch"
                      wrap="wrap"
                    >
                      <Grid2 size={12}>
                        <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Equipements liés</h3>
                      </Grid2>
                      <Grid2 size={12}>
                        <EquipmentsListView3d
                          productDatas={productDatas}
                          equipmentSelected={null}
                          setEquipmentSelected={null}
                          setUuidTagSelected={null}
                          showTags={null}
                          cameraMoving={null}
                          setEventSelected={eventData}
                          assignMode={true}
                          equipmentsAssigned={tmpEquipmentAssigned}
                          setEquipmentsAssigned={setTmpEquipmentAssigned}
                          refreshData={refreshData}
                          createEntityMode={null}
                          setCreateEntityMode={null}
                          hasNoMatterportScan={hasNoMatterportScan}
                          setNewEntityCreated={null}
                          context={'edit-event-infos'}
                        ></EquipmentsListView3d>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
              </Grid2>
            </Grid2>

            {/* FOOTER */}

            <Grid2 ref={footerRef} size={12} sx={{ mt: '15px' }}>
              <Grid2 container spacing={2} justifyContent={'center'} alignItems={'center'}>
                <Grid2>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    Annuler
                  </Button>
                </Grid2>
                <Grid2>
                  <Button
                    variant="contained"
                    onClick={() => {
                      const promises = [];

                      equipments?.forEach((equipment) => {
                        if (!tmpEquipmentAssigned.find((element) => element.uuid === equipment.uuid)) {
                          promises.push(api.events.equipment.unassign(eventData.uuid, equipment.uuid));
                        }
                      });

                      tmpEquipmentAssigned.forEach((equipment) => {
                        promises.push(api.events.equipment.assign(eventData.uuid, equipment.uuid));
                      });

                      if (images.length > 0) {
                        images.forEach((image) => {
                          const imgdata = new FormData();
                          imgdata.append('uuid_owner', eventData.uuid);
                          imgdata.append('kind', 'image');
                          imgdata.append('file', image);
                          promises.push(api.files.create(imgdata));
                        });
                      }

                      if (files.length > 0) {
                        files.forEach((file) => {
                          const imgdata = new FormData();
                          imgdata.append('uuid_owner', eventData.uuid);
                          imgdata.append('kind', 'document');
                          imgdata.append('file', file);
                          promises.push(api.files.create(imgdata));
                        });
                      }

                      Promise.all(promises).then((res) => {
                        api.events.update(eventData.uuid, tmpEventData).then((resp) => {
                          if (refreshEvent) {
                            refreshEvent().then((res) => {
                              setModalOpen(false);
                              refreshData();
                            });
                          } else {
                            setModalOpen(false);
                            refreshData();
                          }
                        });
                      });
                    }}
                  >
                    Valider
                  </Button>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </>
      )}
    </>
  );
};
